import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2FillColorsType } from '__pc__/constant/v2Design/Colors/V2FillColors';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import { SpaceType, SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2ButtonIconPositionType, V2ButtonSizeType, V2ButtonType } from '__pc__/constant/v2Design/V2ButtonType';
import { V2IconSizeType } from '__pc__/constant/v2Design/V2IconType';

export const getButtonSpaceStyle = (buttonType?: V2ButtonSizeType, iconPosition?: V2ButtonIconPositionType) => {
  switch (buttonType) {
    case V2ButtonSizeType.L:
      if (iconPosition === V2ButtonIconPositionType.left) {
        return {
          padding: `${SpaceValue[SpaceType.M]}px ${SpaceValue[SpaceType.L]}px ${SpaceValue[SpaceType.M]}px ${
            SpaceValue[SpaceType.S]
          }px`,
        };
      } if (iconPosition === V2ButtonIconPositionType.right) {
        return {
          padding: `${SpaceValue[SpaceType.M]}px ${SpaceValue[SpaceType.S]}px ${SpaceValue[SpaceType.M]}px ${
            SpaceValue[SpaceType.L]
          }px`,
        };
      }
      return { padding: `${SpaceValue[SpaceType.M]}px ${SpaceValue[SpaceType.L]}px` };

    case V2ButtonSizeType.M:
      if (iconPosition === V2ButtonIconPositionType.left) {
        return {
          padding: `${SpaceValue[SpaceType.XS]}px ${SpaceValue[SpaceType.M]}px ${SpaceValue[SpaceType.XS]}px ${
            SpaceValue[SpaceType.XXS]
          }px`,
        };
      } if (iconPosition === V2ButtonIconPositionType.right) {
        return {
          padding: `${SpaceValue[SpaceType.XS]}px ${SpaceValue[SpaceType.XXS]}px ${SpaceValue[SpaceType.XS]}px ${
            SpaceValue[SpaceType.M]
          }px`,
        };
      }
      return { padding: `${SpaceValue[SpaceType.XS]}px ${SpaceValue[SpaceType.M]}px` };

    case V2ButtonSizeType.S:
      if (iconPosition === V2ButtonIconPositionType.left) {
        return {
          padding: `${SpaceValue[SpaceType.XXS]}px ${SpaceValue[SpaceType.XS]}px ${SpaceValue[SpaceType.XXS]}px ${
            SpaceValue[SpaceType.XXXS]
          }px`,
        };
      } if (iconPosition === V2ButtonIconPositionType.right) {
        return {
          padding: `${SpaceValue[SpaceType.XXS]}px ${SpaceValue[SpaceType.XXXS]}px ${SpaceValue[SpaceType.XXS]}px ${
            SpaceValue[SpaceType.XS]
          }px`,
        };
      }
      return { padding: `${SpaceValue[SpaceType.XXS]}px ${SpaceValue[SpaceType.XS]}px` };

    default:
      if (iconPosition === V2ButtonIconPositionType.left) {
        return {
          padding: `${SpaceValue[SpaceType.XXXS]}px ${SpaceValue[SpaceType.XXS]}px ${SpaceValue[SpaceType.XXXS]}px ${
            SpaceValue[SpaceType.XXXXS]
          }px`,
        };
      } if (iconPosition === V2ButtonIconPositionType.right) {
        return {
          padding: `${SpaceValue[SpaceType.XXXS]}px ${SpaceValue[SpaceType.XXXXS]}px ${SpaceValue[SpaceType.XXXS]}px ${
            SpaceValue[SpaceType.XXS]
          }px`,
        };
      }
      return { padding: `${SpaceValue[SpaceType.XXXS]}px ${SpaceValue[SpaceType.XXS]}px` };
  }
};
// *develope* fill에 따른 borderColor가 맵핑되어야함.
export const getButtonBorderStyle = (buttonType: V2ButtonType, fillType?: V2FillColorsType) => {
  if (buttonType === V2ButtonType.OutLine) {
    return { border: `1px solid ${V2BorderColors.default}` };
  }
  return {};
};

export const getButtonIconSize = (buttonSize?: V2ButtonSizeType): V2IconSizeType => {
  switch (buttonSize) {
    case V2ButtonSizeType.L:
      return V2IconSizeType.S;
    case V2ButtonSizeType.M:
      return V2IconSizeType.S;
    case V2ButtonSizeType.S:
      return V2IconSizeType.XS;
    case V2ButtonSizeType.XS:
      return V2IconSizeType.XS;
    default:
      return V2IconSizeType.M;
  }
};

export const getButtonRadiusStyle = (size?: V2ButtonSizeType) => {
  switch (size) {
    case V2ButtonSizeType.L:
    case V2ButtonSizeType.M:
      return { borderRadius: `${RadiusValue.M}px` };
    case V2ButtonSizeType.S:
    case V2ButtonSizeType.XS:
      return { borderRadius: `${RadiusValue.S}px` };

    default:
      return { borderRadius: `${RadiusValue.NONE}px` };
  }
};
