import { MatchDashboardInfoType } from 'components/_v2/matchHome/dashboard/MatchHomeDashboardInfoModal';
import URIs from 'consts/URIs';
import { ICertificationRs, IMatchApplicationHistories, IMatchDashboardPosition, IMatchHomeDashboardRs, IMatchHomeDashboardStatus, IMatchWritingResumePositions, IMatchingHomeApplicationStatusRs, JF3PageType } from 'interfaces/_v2/matchHome/dashboard/IMatchHomeDashboard';
import { IPositionStatusRs } from 'interfaces/_v2/_common/IPositionStatusRs';
import { action, observable } from 'mobx';
import { request } from 'utils/request';
import { MatchTabType } from 'consts/_v2/match/MatchType';

export default class MatchHomeDashboardModel {
  @observable application: IMatchHomeDashboardRs | null = null;
  @observable applicationCount:IMatchingHomeApplicationStatusRs | null = null;
  @observable writingResumes:IMatchWritingResumePositions | null = null;
  @observable resumes:IMatchApplicationHistories | null = null;
  @observable status: IMatchHomeDashboardStatus | null = null;
  @observable positionStatus: IPositionStatusRs | null = null;
  @observable jp3URL:string= '';
  @observable hiddenPositions: IMatchDashboardPosition[] = [];
  @observable infoModal: { isOpen: boolean, type: MatchDashboardInfoType|null } = { isOpen: false, type: null };
  @observable applicationCertification:ICertificationRs | null = null;
  @action
  loadApplicationCertification = async (positionSns:number[]) => {
    try {
      this.applicationCertification = (await request<ICertificationRs>({
        method: 'post',
        url: URIs.post_match_home_application_certification,
        data: { positionSns },
      }));
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadApplicationPositions = async () => {
    try {
      this.application = (await request<{ applicationStatus: IMatchHomeDashboardRs }>({
        method: 'get',
        url: URIs.get_match_home_application_positions,
      })).applicationStatus;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadApplicationCount = async () => {
    try {
      this.applicationCount = (await request<IMatchingHomeApplicationStatusRs>({
        method: 'get',
        url: URIs.get_match_home_application_status,
      }));
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadApplicationHistories = async (tabType:MatchTabType) => {
    try {
      this.resumes = (await request<IMatchApplicationHistories>({
        method: 'get',
        url: URIs.get_match_home_application_positions,
        params: { tabType },
      }));
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadWritingResumePositions = async () => {
    try {
      this.writingResumes = (await request<IMatchWritingResumePositions>({
        method: 'get',
        url: URIs.get_match_home_writing_resume_positions,
      }));
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadHiddenPositions = async () => {
    try {
      this.hiddenPositions = (await request<{ hiddenPositions: IMatchDashboardPosition[] }>({
        method: 'get',
        url: URIs.get_match_home_application_hidden_positions,
      })).hiddenPositions;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  getJF3Domain = async (positionSn: number, pageType: JF3PageType) => {
    try {
      this.jp3URL = (await request<{url:string}>({
        method: 'get',
        url: URIs.get_recruitment_url,
        params: { positionSn, pageType },
      })).url;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadApplicationStatus = async () => {
    try {
      this.status = (await request<{ status: IMatchHomeDashboardStatus }>({
        method: 'get',
        url: URIs.get_match_home_application_response_status,
      })).status;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  deleteApply = async (positionSn: number) => {
    try {
      const res = await request({
        method: 'delete',
        url: URIs.delete_match_application_position_apply(positionSn),
      });
      return res;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  deleteWritingResume = async (positionSn: number) => {
    try {
      const res = await request({
        method: 'delete',
        url: URIs.delete_match_application_resume(positionSn),
      });
      return res;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  getPositionStatus = async (positionSn: number) => {
    try {
      this.positionStatus = await request({
        method: 'get',
        url: URIs.get_position_status(positionSn),
      });
    } catch (error) {
      console.error(error);
    }
  }

  @action
  hidePosition = async (positionSn: number) => {
    try {
      await request({
        method: 'put',
        url: URIs.put_match_home_application_positions_hide,
        data: { positionSn },
      });
    } catch (error) {
      console.error(error);
    }
  }

  @action
  showPosition = async (positionSns: number[]) => {
    try {
      await request({
        method: 'put',
        url: URIs.put_match_home_application_positions_show,
        data: { positionSns },
      });
    } catch (error) {
      console.error(error);
    }
  }
}
