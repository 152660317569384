import { UserAuthorityType } from 'consts/_v2/UserAuthorityType';
import ILoginUserInfoRs from 'interfaces/rqrs/ILoginUserInfoRs';
import { useUserInfo } from 'query/user/useUserInfoQuery';
import { useEffect } from 'react';

const useGa4 = () => {
  const { data: userInfo } = useUserInfo();// <= api : get .('/login/user/info')
  const { isMatchingUser, isAuthorityUser, isReceivingMatchingUser } = useCheckAuthority(userInfo);

  const getUserType = () => {
    if (isMatchingUser) {
      return LoggingUserTypeEnum.MATCHING_ON;
    }
    if (userInfo?.id) return LoggingUserTypeEnum.USER;
    return LoggingUserTypeEnum.NOT_USER;
  };

  useEffect(() => {
    if (!window.gtag) return;

    window.gtag('set', 'user_properties', {
      userType: getUserType(),
      cc: isAuthorityUser,
      receivedMatch: isReceivingMatchingUser,
    });
  }, [userInfo]);
};

export default useGa4;

export enum LoggingUserTypeEnum {
    CC = 'CC',
    MATCHING_ON = 'MATCHING_ON',
    RECEIVING_MATCHING = 'RECEIVING_MATCHING', // 매칭제안 받은 유저
    USER = 'USER', // 매칭이 아닌 유저
    NOT_USER = 'NOT_USER', // 로그인하지 않은 유저
  }

const isAuthorityUser = (userData?: ILoginUserInfoRs) => {
  return userData?.authorities?.includes(UserAuthorityType.PARTNERSHIP) ?? false;
};

const isMatchingUser = (userData?: ILoginUserInfoRs) => {
  return !!userData?.isMatchingUser;
};

const isReceivingMatchingUser = (userData?: ILoginUserInfoRs) => {
  return !!userData?.matchingProposalCount;
};

const useCheckAuthority = (userData?: ILoginUserInfoRs) => {
  return {
    isAuthorityUser: isAuthorityUser(userData),
    isMatchingUser: isMatchingUser(userData),
    isReceivingMatchingUser: isReceivingMatchingUser(userData),
  };
};
