import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Badge from '__pc__/components/common/v2Design/badge';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2BadgeOption, V2BadgeType } from '__pc__/constant/v2Design/V2BadgeType';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import ImgDummy from 'assets/_v2/positionList/img_dumy.png';
import JDALink from 'components/JDALink';
import { BusinessSizeText } from 'consts/BusinessSize';
import { LocationCode } from 'consts/CompanyLocationType';
import RoutePaths from 'consts/RoutePaths';
import { lineClampBox } from 'consts/style/mixins';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import { useMatchHomeMatchingCompany } from 'query/match/useMatchQuery';
import React, { FC } from 'react';
import styled from 'styled-components';
import { formatNumberWithCommas } from 'utils/NumberFormatUtil';

const Frame = styled.div`
`;

const TitleFrame = styled.div`
    margin : 32px 16px 0 16px;
    display: flex;
    justify-content: space-between;
`;

const CardFrame = styled.div`
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;

    ::-webkit-scrollbar {
          display: none;
      }
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }
  
    .scroll-area {
      display: grid;
      grid-auto-flow: column;
      grid-template-rows: repeat(3, auto);
      grid-template-columns: max-content;
      gap: ${SpaceValue.L}px;
      margin-left: ${SpaceValue.L}px;

      .companyListWrap {
        width: 240px;
      }
    }
    .companyCardArea {
      display: flex;
      align-items: center;
      gap: ${SpaceValue.L}px;

      .logo {
        flex: none;
        object-fit: cover;
        width: 64px;
        height: 64px;
        border: 1px solid ${V2BorderColors.default};
        overflow: hidden;
      }

      .companyDesc {
        display: flex;
        flex-direction: column;
        gap: ${SpaceValue.XXS}px;

        .companyName {
          ${lineClampBox(1)};
          word-break: break-all;
        }

        .companyInfo {
          display: flex;
          align-items: center;

          .divider {
            margin: 0 8px;
            width: 1px;
            height: 8px;
            background-color: ${V2BorderColors.default};
          }

          .hidden {
            display: none;
          }
        }
      }
    }
  `;

interface IMainMatchingCompany{
    login?:Login
}

interface CompanyCardProps {
  logoUrl: string | null;
  companyName: string;
  location: string;
  companySn: number;
  enterprise: string;
}

const CompanyCard = ({ logoUrl, companyName, location, enterprise, companySn }: CompanyCardProps) => {
  return (
    <JDALink className='companyCardWrap' to={RoutePaths.company_details(companySn)}>
      <div className='companyCardArea'>
        <img src={logoUrl ?? ImgDummy} alt='logo' className='logo' />
        <div className='companyDesc'>
          <V2Text
            className='companyName'
            element={V2TextOption.element.span}
            fontStyle={V2TextOption.fontStyle.body_1_b}
            color={V2TextOption.color.default}
          >
            {companyName}
          </V2Text>
          {
            enterprise && location !== '국내'
            && (
            <div className='companyInfo'>
              <V2Text
                element={V2TextOption.element.span}
                fontStyle={V2TextOption.fontStyle.body_2_m}
                color={V2TextOption.color.subtlest}
              >
                {enterprise}
              </V2Text>
              <div className={`divider ${!enterprise || !location ? 'hidden' : ''}`} />
              <V2Text
                element={V2TextOption.element.span}
                fontStyle={V2TextOption.fontStyle.body_2_m}
                color={V2TextOption.color.subtlest}
              >
                {location}
              </V2Text>
            </div>
            )
          }

        </div>
      </div>
    </JDALink>
  );
};

const MainMatchingCompany:FC<IMainMatchingCompany> = () => {
  const { data: matchingCompanyData } = useMatchHomeMatchingCompany();

  return (
    <Frame>
      <TitleFrame>
        <V2Text
          fontStyle={V2FontStyleType.title_3_b}
          color={V2TextColorsType.strong}
        >매칭 참여 기업
        </V2Text>
        <V2Badge
          type={V2BadgeType.OutLine}
          size={V2BadgeOption.size.L}
          color={V2BadgeOption.color.subtlest}
          fontStyle={V2BadgeOption.fontStyle.caption_1_sb}
          borderColor={V2BadgeOption.borderColor.default}
        >
          참여 기업: {formatNumberWithCommas(matchingCompanyData?.matchingCompanyCount || 0)}사
        </V2Badge>
      </TitleFrame>
      <SpacingBlock vertical size={SpaceValue.L} />
      {/* 매칭 참여 기업 */}
      <CardFrame>
        <div className='scroll-area'>
          {matchingCompanyData && matchingCompanyData.matchingCompanyList.map((card, index) => (
            <div key={`${card.companySn} - ${index}`} className='companyListWrap'>
              <CompanyCard
                companySn={card.companySn}
                logoUrl={card.logoImageUrl}
                companyName={card.companyName}
                location={LocationCode(card.companyLocationCode)}
                enterprise={BusinessSizeText[card.companyBusinessSize]}
              />
            </div>
          ))}
        </div>
      </CardFrame>
      <SpacingBlock vertical size={SpaceValue.XXL} />
    </Frame>
  );
};
export default inject(injectStore.login)(observer(MainMatchingCompany));
