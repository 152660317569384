import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Loading from 'components/Loading';
import { CompanyMetaTag } from 'components/_v2/_common/meta/MetaTag';
import CompanyDetailErrorPage from 'components/_v2/company/CompanyDetailErrorPage';
import CompanyDetailHeaderCard from 'components/_v2/company/CompanyDetailHeaderCard';
import CompanyDetailInformation from 'components/_v2/company/CompanyDetailInformation';
import CompanyDetailMatchingReport from 'components/_v2/company/CompanyDetailMatchingReport';
import CompanyDetailPosition from 'components/_v2/company/CompanyDetailPosition';
import ErrorCode from 'consts/ErrorCodes';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import CompanyModel from 'models/_v2/CompanyModel';
import MatchReportModel from 'models/_v2/MatchReportModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import CommonUtils from 'utils/CommonUtils';
import ScrollUtil from 'utils/ScrollUtil';
import KakaoMapScript from 'utils/_v2/kakaoMap/KakaoMapScript';

const Frame = styled.div`
  .divider {
    margin: 32px 0;
  }
`;

const CompanyTab = styled.div<{tabColor?:boolean}>`
    position:sticky;
    padding: 20px 16px 16px 16px ;
    z-index: 100;
    display: flex;
    align-items: center;
    width: 100%;
    top: ${(props) => (!(props as any)['data-hide'] ? `calc(env(safe-area-inset-top) + 50px)` : `calc(env(safe-area-inset-top) + 0px)`)};
    transition: top .25s, background .25s;
    background: ${(props) => (props.tabColor ? colors.WHITE_100 : colors.JOBDA_BLACK)};

    .a-link {
        position:relative;
        width:fit-content;
        font: ${Fonts.B2_Medium};
        color: ${colors.CG_60};
        margin-right: 24px;
        padding-bottom: 4px;
        &[aria-current=true] {
            font: ${Fonts.B2_Bold};
            color:${(props) => (props.tabColor ? colors.JOBDA_BLACK : colors.WHITE_100)};
        }

        &[aria-current=true]:before {
          display:block;
          position:absolute;
          content:'';
          bottom: 0;
          left:0;
          width:100%;
          height:2px;
          background:${(props) => (props.tabColor ? colors.JOBDA_BLACK : colors.WHITE_100)};
        }
    }


`;
export interface ICompanyDetails {
    context?: Context;
    companyModel?: CompanyModel;
    matchReportModel?:MatchReportModel;
  }

  interface ICompanyDetailTab {
    tabId: number;
    tabName: string;
    tab: string;
    visible: boolean;
  }
export const CompanyTabRoute = {
  report: 'report',
  info: 'info',
  recruit: 'recruit',
};
const CompanyDetailTab : FC<ICompanyDetails> = ({ context = new Context(), companyModel = new CompanyModel(), matchReportModel = new MatchReportModel() }) => {
  const { search } = useLocation();
  const { positionSn, tab } = CommonUtils.parseQuery(search);
  const [reportVisible, setReportVisible] = useState(false);
  useEffect(() => {
    if (tab === CompanyTabRoute.recruit) {
      setCurrentTab(1);
    } else if (tab === CompanyTabRoute.report) {
      setCurrentTab(2);
    } else {
      setCurrentTab(0);
    }
  }, [tab]);
  const [isLoading, setIsLoading] = useState(false);
  const { companySn } = useParams<{ companySn: string}>();
  const [errorCode, setErrorCode] = useState<ErrorCode | null>(null);
  const companyDetailTab:ICompanyDetailTab[] = [{ tabId: 0, tabName: '기업 정보', tab: 'info', visible: true }, { tabId: 1, tabName: '공고', tab: 'recruit', visible: true }, { tabId: 2, tabName: '매칭 리포트', tab: 'report', visible: reportVisible }];
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [headerColor, setHeaderColor] = useState(false);
  const [headerIconColor, setHeaderIconColor] = useState(false);
  const blendHandler = async () => {
    const scrollTop = await ScrollUtil.getScrollTop();
    setScrollPosition(scrollTop);
  };
  useEffect(() => {
    ScrollUtil.addEventListener(blendHandler);
    return () => {
      ScrollUtil.removeEventListener(blendHandler);
    };
  }, []);
  useEffect(() => {
    if (scrollPosition > 440) {
      setHeaderColor(true);
    } else {
      setHeaderColor(false);
      context.hideHeader = false;
    }
    if (scrollPosition > 440) {
      setHeaderIconColor(true);
    } else {
      setHeaderIconColor(false);
    }
  }, [scrollPosition, currentTab, companyModel.companyTheme, context]);
  useEffect(() => {
    const init = async () => {
      context.iPhoneNotch = true;
      if (Number.isNaN(Number(companySn))) {
        setErrorCode(ErrorCode.B002);
      } else {
        setIsLoading(true);
        await companyModel.init(Number(companySn));
        try {
          await companyModel.loadCompanyInformation();
        } catch (e: any) {
          setIsLoading(false);
          switch (e.response?.data?.errorCode) {
            case ErrorCode.B001:
              setErrorCode(ErrorCode.B001);
              break;
            case ErrorCode.B002:
              setErrorCode(ErrorCode.B002);
              break;
            case ErrorCode.B901:
              setErrorCode(ErrorCode.B901);
              break;
            default:
              break;
          }
        }
        setIsLoading(false);
        if (companyModel.companyInformation) {
          const queryParams = new URLSearchParams(window.location.search);
          await companyModel.loadCompanyHeader();
          if (companyModel.companyHeader && companyModel.companyHeader.companyHeaderDto.matchingReportOpenYn) {
            await matchReportModel.loadMatchReportCompanies(companyModel.companySn);
            await matchReportModel.loadMatchReportCompaniesPositions(companyModel.companySn);
            if (matchReportModel.matchReportCompaniesPositions && matchReportModel.matchReportCompaniesPositions.positions.length > 0) {
              if (positionSn) {
                const foundPosition = matchReportModel.matchReportCompaniesPositions.positions.find((item) => item.sn === Number(positionSn));
                if (foundPosition) {
                  matchReportModel.currentReportPosition = foundPosition;
                  await matchReportModel.loadMatchReportPositionsDetail(matchReportModel.currentReportPosition.sn);
                  // if (!queryParams.get('tab')) {
                  //   queryParams.append('tab', 'report');
                  //   const updatedURL = `${window.location.pathname}?${queryParams.toString()}`;
                  //   window.history.replaceState({}, document.title, updatedURL);
                  // }
                  setReportVisible(true);
                } else {
                  // if (!queryParams.get('tab')) {
                  //   queryParams.append('tab', 'info');
                  //   const updatedURL = `${window.location.pathname}?${queryParams.toString()}`;
                  //   window.history.replaceState({}, document.title, updatedURL);
                  // }
                  setReportVisible(false);
                  setCurrentTab(0);
                }
              } else {
                // eslint-disable-next-line prefer-destructuring
                matchReportModel.currentReportPosition = matchReportModel.matchReportCompaniesPositions.positions[0];
                // 현재 URL의 쿼리 파라미터를 가져옵니다.
                // if (!queryParams.get('tab')) {
                //   queryParams.append('tab', 'report');
                //   const updatedURL = `${window.location.pathname}?${queryParams.toString()}&?positionSn=${matchReportModel.currentReportPosition.sn}`;
                //   window.history.replaceState({}, document.title, updatedURL);
                // } else {
                //   const updatedURL = `${window.location.pathname}?positionSn=${matchReportModel.currentReportPosition.sn}`;
                //   window.history.replaceState({}, document.title, updatedURL);
                // }
                // 쿼리 파라미터에 tab이 없으면 추가합니다.

                await matchReportModel.loadMatchReportPositionsDetail(matchReportModel.currentReportPosition.sn);
                setReportVisible(true);
              }
              if (matchReportModel.matchReportPositionsDetail?.addressInfo) {
                await matchReportModel.getLocation();
                KakaoMapScript(matchReportModel.locationX, matchReportModel.locationY, matchReportModel.matchReportPositionsDetail.addressInfo.address);
              }
            } else {
              setReportVisible(false);
              setCurrentTab(0);
            }
            // setCurrentTab(0);
          } else {
            setReportVisible(false);
            setCurrentTab(0);
          }
          await companyModel.loadCompanyFinance();
          await companyModel.loadCompanyEmployment();
          await companyModel.loadCompanySpecialty();
          await companyModel.loadCompanyFinance();
          await companyModel.loadCompanyWelfare();
          await companyModel.loadCompanyAdvantage();
          await companyModel.loadCompanyTheme();
          await companyModel.loadCompanyEmployeeStories();
          if (companyModel.companyInformation?.companyDetailDto.location) {
            await companyModel.getLocation();
            KakaoMapScript(companyModel.locationX, companyModel.locationY, companyModel.companyInformation.companyDetailDto.location);
          }
        }
      }
    };
    init();
    return () => {
      context.iPhoneNotch = false;
      companyModel.companyAdvantage = null;
    };
  }, [matchReportModel.changedPosition]);

  const handleTabClick = (tabId:number) => {
    let newTab = '';
    if (tabId === 2) {
      newTab = CompanyTabRoute.report;
    } else if (tabId === 0) {
      newTab = CompanyTabRoute.info;
    } else if (tabId === 1) {
      newTab = CompanyTabRoute.recruit;
    }
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('tab')) {
      queryParams.set('tab', newTab);
    } else {
      queryParams.append('tab', newTab);
    }

    const updatedURL = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.replaceState({}, document.title, updatedURL);
    setCurrentTab(tabId);
  };
  if (isLoading) return <Loading />;

  return (
    <Frame>
      {
        errorCode === null
          ? (
            <>
              {
              companyModel.companyInformation
              && (
              <>
                {CompanyMetaTag(companyModel.companyInformation.companyName)}
                {currentTab !== 1 && <CompanyDetailHeaderCard headerIconColor={headerIconColor} scrollPosition={scrollPosition} />}

                <CompanyTab data-hide={context.hideHeader} tabColor={headerColor}>
                  {companyDetailTab.map((tabItem:ICompanyDetailTab, idx) => {
                    if (tabItem.visible) {
                      return (
                        <div className='a-link' role='button' onClick={() => handleTabClick(idx)} aria-current={currentTab === idx}>{tabItem.tabName}</div>
                      );
                    }
                  })}
                </CompanyTab>
                {currentTab === 0 && <CompanyDetailInformation />}
                {currentTab === 1 && <CompanyDetailPosition />}
                {currentTab === 2 && reportVisible && <CompanyDetailMatchingReport setCurrentTab={setCurrentTab} />}
              </>
              )
            }

            </>
          )
          : <CompanyDetailErrorPage type={errorCode!} />
      }

    </Frame>
  );
};

export default inject(injectStore.companyModel, injectStore.matchReportModel, injectStore.context)(observer(CompanyDetailTab));
