import URIs from 'consts/URIs';
import { action, autorun, observable } from 'mobx';
import { request } from 'utils/request';

import { ICategoryData, IInfoCardData } from 'interfaces/_v2/contents/IContents';
import { ContentsSpace, IContentsParams, Space } from 'interfaces/_v2/contents/IContentsRq';
import { ICategoryRs, IContentRs } from 'interfaces/_v2/contents/IContentsRs';

const initialCategory: ICategoryData = {
  id: -1,
  name: '전체',
};

const size = 6;

export default class Contents {
  @observable categories: ICategoryData[] = [];
  @observable dChampsCategories: ICategoryData[] = [];
  @observable passReviewContents: IInfoCardData[] = [];
  @observable contents: IInfoCardData[] = [];
  @observable dChampsContents: IInfoCardData[] = [];
  @observable selectedCategory: number = -1;
  @observable page: number = 0;
  @observable totalPages: number = 0;
  @observable eventCategory: number | null = null;

  @action
  getCategoryList = async (type: ContentsSpace, categorySn?:number) => {
    const params = type !== 'ALL' ? { type } : undefined;
    try {
      const res = await request<ICategoryRs>({
        method: 'get',
        url: URIs.get_post_category_space,
        params,
      });
      if (type === Space.MAIN) {
        const randomIndex = Math.floor(Math.random() * res.categories.length);
        this.selectedCategory = res.categories[randomIndex].sn;
        this.categories = [...res.categories.map((ele) => ({
          id: ele.sn,
          name: ele.name,
        }))];
      } else {
        this.categories = [initialCategory, ...res.categories.map((ele) => ({
          id: ele.sn,
          name: ele.name,
        }))];
      }
      if (categorySn) this.getContentList(categorySn);
      else this.getContentList(this.selectedCategory);
    } catch (e) {
      console.error(e);
    }
  }

  @action
  getEventCategory = async () => {
    try {
      const res = await request<ICategoryRs>({
        method: 'get',
        url: URIs.get_post_category_space,
      });
      this.eventCategory = res.categories.find((ele) => ele.name === '이벤트')?.sn || null;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  getContentList = async (sn: number = -1) => {
    const categoriesSn: number[] = [];
    if (sn === -1) {
      this.categories.forEach((ele) => {
        if (ele.id !== -1) categoriesSn.push(ele.id);
      });
    }

    const params: IContentsParams = {
      categorySns: sn === -1 ? categoriesSn : [sn],
      page: this.page,
      size,
    };

    try {
      const res = (await request<IContentRs>({
        method: 'get',
        url: URIs.get_post_list,
        params,
      }));
      this.contents = res.posts.map((ele) => ({
        id: ele.sn,
        title: ele.title,
        subTitle: ele.label,
        category: ele.categoryName,
        imageSrc: ele.thumbnailUrl,
        categoryType: ele.categoryIconType,
        type: ele.type,
        link: ele.link,
        privacy: ele.privacy,
      }));
      this.page = res.pages.page;
      this.totalPages = res.pages.totalPages;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  getCategoryListDChamps = async (type: ContentsSpace) => {
    const params = type !== 'ALL' ? { type } : undefined;
    try {
      const res = await request<ICategoryRs>({
        method: 'get',
        url: URIs.get_post_category_space,
        params,
      });
      this.dChampsCategories = [initialCategory, ...res.categories.map((ele) => ({
        id: ele.sn,
        name: ele.name,
      }))];
      this.getContentListDChamps(-1);
    } catch (e) {
      console.error(e);
    }
  }

  @action
  getContentListDChamps = async (sn: number = -1) => {
    const categoriesSn: number[] = [];
    if (sn === -1) {
      this.dChampsCategories.forEach((ele) => {
        if (ele.id !== -1) categoriesSn.push(ele.id);
      });
    }
    const params: IContentsParams = {
      categorySns: sn === -1 ? categoriesSn : [sn],
      page: this.page,
      size,
    };

    try {
      const res = (await request<IContentRs>({
        method: 'get',
        url: URIs.get_post_list,
        params,
      }));
      this.dChampsContents = res.posts.map((ele) => ({
        id: ele.sn,
        title: ele.title,
        subTitle: ele.label,
        category: ele.categoryName,
        imageSrc: ele.thumbnailUrl,
        categoryType: ele.categoryIconType,
        type: ele.type,
        link: ele.link,
        privacy: ele.privacy,
      }));
      this.page = res.pages.page;
      this.totalPages = res.pages.totalPages;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  getPassReviewContents = async () => {
    try {
      const res = (await request<IContentRs>({
        method: 'get',
        url: URIs.get_post_pass_review,
        params: {
          page: 0,
          size: 8,
        },
      }));
      this.passReviewContents = res.posts.map((ele) => ({
        id: ele.sn,
        title: ele.title,
        subTitle: ele.label,
        category: ele.categoryName,
        imageSrc: ele.thumbnailUrl,
        categoryType: ele.categoryIconType,
        type: ele.type,
        link: ele.link,
        privacy: ele.privacy,
      }));
    } catch (e) {
      console.error(e);
    }
  }

  @action
  setSelectedCategory = (sn: number) => {
    this.selectedCategory = sn;
    this.page = 0;
    this.getContentList(this.selectedCategory);
  }

  @action
  setPage = (page: number) => {
    this.page = page;
    autorun(() => {
      this.getContentList(this.selectedCategory);
    });
  }
}
