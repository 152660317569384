import RoutePaths from 'consts/RoutePaths';
import { lineClampBox } from 'consts/style/mixins';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import Colors from '__designkit__/common/colors';
import { useHistory } from 'react-router';
import Contents from 'models/_v2/Contents';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import Login from 'models/Login';
import Context from 'models/Context';
import { PassReviewMetaTag } from 'components/_v2/_common/meta/MetaTag';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';

interface IMatchingStroy {
  contents?:Contents
  login?:Login
  context?:Context
}

const Frame = styled.div`
  margin: ${SpaceValue.XL}px 0;
`;

const TitleFrame = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px 16px;
`;

const BestReview = styled.div`
  padding: 0 16px;
  width: 100%;
  overflow-x: scroll;

  ::-webkit-scrollbar {
        display: none;
    }
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  .scroll-area {
    display: flex;
    gap: 16px;
    width: fit-content;
  }

  .best-item {
    display: flex;
    flex-direction: column;
    width: 160px;
    
    .item-thumbnail {
      width: 160px;
      height: 106px;
      border-radius: 8px;
      background-color: ${Colors.CG_40};
      object-fit: cover;
    }

    .item-text {
      margin-top: 8px;

      .title {
        margin-top: 4px;
        ${lineClampBox(2)};
      }
    }
  }
`;

const MatchingStroy:FC<IMatchingStroy> = ({ contents = new Contents() }) => {
  const history = useHistory();
  useEffect(() => {
    const init = async () => {
      await contents.getPassReviewContents();
    };
    init();
  }, []);

  return (
    <Frame>
      <PassReviewMetaTag />
      {/* 선배들의 합격 꿀팁 대공개 */}
      <div className='section'>
        <TitleFrame>
          <V2Text
            fontStyle={V2FontStyleType.title_3_b}
            color={V2TextColorsType.strong}
          >
            매칭 합격 스토리
          </V2Text>
          <V2Button
            className='btnViewAll'
            size={V2ButtonOption.size.S}
            fontStyle={V2ButtonOption.fontStyle.body_2_sb}
            color={V2ButtonOption.color.subtle}
            aProps={{ href: RoutePaths.info_category(8) }}
          >
            전체 보기
          </V2Button>
        </TitleFrame>
        <BestReview>
          <div className='scroll-area'>
            {
              contents.passReviewContents.map((item, index) => (
                <div role='button' className='best-item' onClick={() => history.push(RoutePaths.info_detail(item.id))}>
                  <img src={item.imageSrc} alt='' className='item-thumbnail' />
                  <div className='item-text'>
                    <V2Text
                      fontStyle={V2FontStyleType.body_3_m}
                      color={V2TextColorsType.subtle}
                    >
                      합격 스토리
                    </V2Text>
                    <V2Text
                      className='title'
                      fontStyle={V2FontStyleType.body_1_b}
                      color={V2TextColorsType.strong}
                    >
                      {item.title}
                    </V2Text>
                  </div>
                </div>
              ))
            }
          </div>
        </BestReview>
      </div>
    </Frame>
  );
};
export default inject(injectStore.contents, injectStore.login, injectStore.context)(observer(MatchingStroy));
