/* eslint-disable no-return-assign */
/* eslint-disable array-callback-return */
import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Button from '__pc__/components/common/v2Design/button';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import RoutePaths from 'consts/RoutePaths';
import { useMatchHomeJobPostingsAcc } from 'query/match/useMatchQuery';
import React from 'react';
import styled from 'styled-components';
import MainAccaJobsCard from './MainAccaJobsCard';
import MainNoPosition from './MainNoPosition';

const RootFrame = styled.div`
  width:100%;
  margin-bottom: 64px;
`;

const Frame = styled.div`
`;

const TitleFrame = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 32px 16px 16px;
`;

const CardFrame = styled.div`
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0 ${SpaceValue.XS}px;

  ::-webkit-scrollbar {
        display: none;
    }
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  .scroll-area {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: max-content;
    gap: ${SpaceValue.XXL}px;
    padding: 0 ${SpaceValue.XS}px;
  }
`;

const MainAccaRecruitFrame = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const MainAccaRecruit = () => {
  const { data: accPositionData, isLoading } = useMatchHomeJobPostingsAcc(27);

  return (
    <RootFrame>
      <Frame>
        <TitleFrame>
          <V2Text
            fontStyle={V2FontStyleType.title_3_b}
            color={V2TextColorsType.strong}
          >
            역량검사 활용 공고
          </V2Text>
          <V2Button
            type={V2ButtonOption.type.Text}
            size={V2ButtonOption.size.S}
            fontStyle={V2ButtonOption.fontStyle.body_2_sb}
            color={V2ButtonOption.color.subtle}
            aProps={{ href: RoutePaths.acca_test }}
          >
            전체 보기
          </V2Button>
        </TitleFrame>
      </Frame>

      <CardFrame>
        {accPositionData && accPositionData?.accJobPostings.length > 0
          ? (
            <MainAccaRecruitFrame>
              <div className='scroll-area'>
                {accPositionData?.accJobPostings && accPositionData?.accJobPostings.length > 0
              && accPositionData?.accJobPostings.map((item, idx) => (

                <React.Fragment key={item.sn}>
                  <MainAccaJobsCard item={item} positionList={accPositionData.accJobPostings} />

                </React.Fragment>

              ))}
              </div>
            </MainAccaRecruitFrame>
          )
          : isLoading ? <MainNoPosition /> : <></>}
      </CardFrame>
      <SpacingBlock vertical size={SpaceValue.XXL} />
    </RootFrame>
  );
};

export default MainAccaRecruit;
