import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import imgArrow from 'assets/_v2/_common/icon_arrow_right.svg';
import React, { FC } from 'react';
import styled from 'styled-components';
import IndicatorLottie from '__designkit__/assets/lottie/tutorial_indicate_lottie.json';
import Lottie from 'lottie-react';

const StyledNotificationCard = styled.div`
  display:flex;
  padding:20px;
  justify-content: space-between;
  z-index:10006;
  position:relative;
  background-color: ${colors.WHITE_100};
  .icon-frame{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
  .content-frame{
    flex:2;
    display: flex;
    flex-direction: column;
    >div{
      display: flex;
      margin-bottom:4px;
    }
    h1{
      font: ${Fonts.B2_Medium};
      white-space: pre-line;
      color:${colors.CG_80};
      margin-bottom:16px;
      strong{
        font: ${Fonts.B2_Bold};
      }
    }
    h3{
      height:20px;
      display:flex;
      align-items:flex-end;
      font: ${Fonts.B3_Medium};
      line-height: 14px;
      color:${colors.CG_60};

    }
    .move-button{
      font: ${Fonts.B3_Medium};
      line-height: 14px;
      color:${colors.CG_70};
      flex-direction: row;
      position: relative; // Lottie의 위치 조정을 위해 position 추가

      .lottie-indicator {
        position: absolute;
        top:-17px;
        left: 10px;
        width: 50px;
        height: 50px;
        z-index: 1;
      }
      .icon-arrow{
        margin-left: 5px;
      }
    }
    .new{
      margin-left:4px;
      margin-top:5px;
      width:4px;
      height:4px;
      border-radius: 50%;
      background: ${colors.ERROR};
    }
  }

  .close-frame{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    button{
      background: transparent;
    }
  }
`;

const MainNotificationCard: FC = () => {
  return (
    <StyledNotificationCard className={`notification-card`}>
      <div className='content-frame'>
        <div>
          <h3>
            방금
          </h3>
          <div className='new' />
        </div>
        <h1>{`잡다매칭의 매칭 튜토리얼 직무 포지션`}에서 <strong>입사지원 제안</strong>이 도착했어요. 어떤 제안이 왔는지 확인해보세요.</h1>
        <div
          className='move-button'
          role='button'

        >
          <Lottie animationData={IndicatorLottie} className='lottie-indicator' />

          메세지 확인하기 <img alt='arrow' src={imgArrow} className='icon-arrow' />
        </div>
      </div>

    </StyledNotificationCard>
  );
};

export default MainNotificationCard;
