import URIs from 'consts/URIs';
import { PositionOrderType } from 'consts/_v2/position/PositionType';
import { IWelfareCategoryWithWelfaresDto } from 'interfaces/rqrs/IWelfareCategoriesWithWelfaresRs';
import { IBookmarkListRs, IPositionAd, IPositionListRq, IPositionListRs } from 'interfaces/_v2/positionList/IPositionList';
import { action, observable } from 'mobx';
import { request } from 'utils/request';

const SEARCH_INIT_PAGE = 0;
const SEARCH_INIT_SIZE = 24;
export default class PositionListModel {
  @observable searchPositions: IPositionListRs | null = null;
  @observable searchBookmarks: IBookmarkListRs | null = null;
  @observable searchFilters: Partial<IPositionListRq> = {
    jobTitles: '', // 직무 코드
    recruitments: '', // 채용 형태
    minSalary: undefined, // 연봉
    maxSalary: undefined, // 연봉
    locations: '', // 지역
    matchingYn: false, // 매칭 여부
    orderType: PositionOrderType.POPULAR, // 정렬 형태
    keyword: '',
    bookmarkYn: false,
  }

  @observable positionAds: IPositionAd[] = [];
  @observable isOpenFilterModal: boolean = false;
  @observable isFirstBookmark: boolean=false;
  @observable welfareCategoryWithWelfares: IWelfareCategoryWithWelfaresDto[] = [];
  @observable positionJobSelectedList: string[] = [];
  @observable positionRecruitSelectedList: string[] = [];
  @observable positionLocationSelectedList: string[] = [];
  @observable isGlobalSearchedJob: boolean = false;
  @observable isGlobalSearchedLocation: boolean = false;
  @observable isGlobalSearchedSalary: boolean = false;

  @action
  init = async () => {

  }

  @action
  loadMatchPositions = async () => {
    try {
      this.searchPositions = await request<IPositionListRs>({
        method: 'get',
        url: URIs.get_positions,
        params: {
          page: SEARCH_INIT_PAGE,
          size: SEARCH_INIT_SIZE,
          ...this.searchFilters,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action
  lazeLoadMatchPositions = async () => {
    if (this.searchPositions === null) return;
    if (this.searchPositions.pages.totalPages <= this.searchPositions.pages.page + 1) return;
    const nextSearchPositions = await request<IPositionListRs>({
      method: 'get',
      url: URIs.get_positions,
      params: {
        page: (this.searchPositions.pages.page + 1),
        size: this.searchPositions.pages.size,
        ...this.searchFilters,
      },
    });
    this.searchPositions = {
      positions: [...this.searchPositions.positions, ...nextSearchPositions.positions],
      pages: nextSearchPositions.pages,
    };
  }

  @action
  loadMatchBookmarks = async () => {
    try {
      this.searchBookmarks = await request<IBookmarkListRs>({
        method: 'get',
        url: URIs.get_position_bookmarks,
        params: {
          page: SEARCH_INIT_PAGE,
          size: SEARCH_INIT_SIZE,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action
  lazeLoadMatchBookmarks = async () => {
    if (this.searchBookmarks === null) return;
    if (this.searchBookmarks.pages.totalPages <= this.searchBookmarks.pages.page + 1) return;
    const nextSearchPositions = await request<IBookmarkListRs>({
      method: 'get',
      url: URIs.get_position_bookmarks,
      params: {
        page: (this.searchBookmarks.pages.page + 1),
        size: this.searchBookmarks.pages.size,
      },
    });
    this.searchBookmarks = {
      bookmarks: [...this.searchBookmarks.bookmarks, ...nextSearchPositions.bookmarks],
      pages: nextSearchPositions.pages,
    };
  }

  @action
  setMatchPositionBookMark = async (bookmarkYn: boolean, positionSn: number) => {
    try {
      if (!bookmarkYn) {
        const res = await request<{firstBookmarkYn:boolean}>({
          method: 'put',
          url: URIs.put_match_positions_bookmark(positionSn),
        });
        if (res.firstBookmarkYn) this.isFirstBookmark = true;
      } else {
        await request({
          method: 'delete',
          url: URIs.delete_match_positions_bookmark(positionSn),
        });
      }
    } catch (e) {
      console.error(e);
      throw (e);
    }
  }

  @action
  loadPositionAd = async () => {
    try {
      this.positionAds = (await request<{positionAds:IPositionAd[]}>({
        method: 'get',
        url: URIs.get_position_ad,
        params: { pageSize: 6 },
      })).positionAds;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  addPositionAdClick = async (positionAdSn: number) => {
    try {
      await request({
        method: 'post',
        url: URIs.post_position_ad_clicks(positionAdSn),
        ignoreLoading: true,
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action
  addPositionAdShows = async (positionAdSn: number) => {
    try {
      await request({
        method: 'post',
        url: URIs.post_position_ad_shows(positionAdSn),
        ignoreLoading: true,
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadTotalWelfare = async () => {
    try {
      this.welfareCategoryWithWelfares = (await request<{ welfareCategoryWithWelfares: IWelfareCategoryWithWelfaresDto[] }>({
        method: 'get',
        url: URIs.get_welfare_categories_welfares,
      })).welfareCategoryWithWelfares;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  resetAll = async () => {
    this.searchPositions = null;
    this.searchFilters = {
      jobTitles: '', // 직무 코드
      recruitments: '', // 채용 형태
      minSalary: undefined, // 연봉
      maxSalary: undefined, // 연봉
      locations: '', // 지역
      matchingYn: false, // 매칭 여부
      orderType: PositionOrderType.LATEST, // 정렬 형태
      keyword: '',
    };
  }

  @action
  resetFilter = async () => {
    this.searchPositions = null;
    this.searchFilters = {
      jobTitles: '', // 직무 코드
      recruitments: '', // 채용 형태
      minSalary: undefined, // 연봉
      maxSalary: undefined, // 연봉
      locations: '', // 지역
      matchingYn: false, // 매칭 여부
      orderType: PositionOrderType.POPULAR, // 정렬 형태
      keyword: this.searchFilters.keyword,
    };
    this.positionJobSelectedList = [];
    this.positionRecruitSelectedList = [];
    this.positionLocationSelectedList = [];
  }
}
