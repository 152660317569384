import URIs from 'consts/URIs';
import { StatusType } from 'consts/_v2/calendar/CalendarFilterType';
import { ICalendarFilterRq, ICalendarFilterRs, ICalendarList, ICalendarMoListDto } from 'interfaces/_v2/calendar/ICalendarList';
import { action, observable } from 'mobx';
import { DateDataFormatYYYYMM } from 'utils/DateUtils';
import { request } from 'utils/request';

export default class CalendarModel {
  @observable calendarList: ICalendarMoListDto[] | null = null;
  @observable calendarMonthCountList: ICalendarMoListDto[] | null = null;
  @observable currentDate: Date = new Date();
  @observable selectedDate: Date = new Date();
  @observable isCalendarFolded: boolean = true;
  @observable bookmarkFilter: boolean = false;
  @observable accFilter: boolean = false;
  @observable centerDate: Date = new Date();
  @observable statusFilter: StatusType = StatusType.TOTAL;
  @observable recentlyFilter:ICalendarFilterRs | null = null;
  @observable calendarLoadFilter: Partial<ICalendarList> = {
    jobTitles: '', // 직무 코드
    recruitmentTypes: '', // 채용 형태
    locations: '', // 지역
    businessSizeTypes: '', // 기업 구분
    keyword: '',
  };

  @observable calendarFilter: Partial<ICalendarList> = {
    jobTitles: '', // 직무 코드
    recruitmentTypes: '', // 채용 형태
    locations: '', // 지역
    businessSizeTypes: '', // 기업 구분
    keyword: '',
  };

  @observable lastLoadDate: string = ''; // yyyy-mm
  @observable selectedLoadDate: Date = this.selectedDate; // 로드한 날과 클릭한 날의 month를 비교하기 위해 사용.
  @observable showDate: Date = this.selectedDate; // 실제 selected값이 아니라 보여지는 값.(앵커링 중복 방지..)
  @observable nextLoadCount: number = 0;

  @action
  init = () => {
    // this.bookmarkFilter = false;
    // this.statusFilter = StatusType.TOTAL;
    // this.currentDate = new Date();

    // this.isCalendarFolded = true;

    // this.calendarLoadFilter = {
    //   jobTitles: '', // 직무 코드
    //   recruitmentTypes: '', // 채용 형태
    //   locations: '', // 지역
    //   businessSizeTypes: '', // 기업 구분
    // };
    // this.calendarFilter = {
    //   jobTitles: '', // 직무 코드
    //   recruitmentTypes: '', // 채용 형태
    //   locations: '', // 지역
    //   businessSizeTypes: '', // 기업 구분
    // };

    // this.lastLoadDate = '';
    // this.selectedLoadDate = new Date();
    // this.selectedDate = new Date();  // 공고 들어갔다가 초기화 문제..
    // this.showDate = new Date(); // 공고 들어갔다가 초기화 문제..
    // this.nextLoadCount = 0;
  }

  @action
  resetFilter = () => {
    this.calendarFilter = {
      jobTitles: '', // 직무 코드
      recruitmentTypes: '', // 채용 형태
      locations: '', // 지역
      businessSizeTypes: '', // 기업 구분
      keyword: this.calendarFilter.keyword,
    };
  }

  @action
  loadCalendarList = async () => {
    try {
      this.calendarList = (await request<{ calendarItems: ICalendarMoListDto[] }>({
        method: 'get',
        url: URIs.get_calendar_list,
        params: { ...this.calendarLoadFilter, targetMonth: DateDataFormatYYYYMM(this.selectedDate) },
      })).calendarItems;
      this.selectedLoadDate = this.selectedDate;
      this.nextLoadCount = 0;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadCalendarMonthCountList = async (targetMonth?: string) => {
    try {
      this.calendarMonthCountList = (await request<{ calendarItems: ICalendarMoListDto[] }>({
        method: 'get',
        url: URIs.get_calendar_list,
        params: { ...this.calendarLoadFilter, targetMonth: targetMonth || DateDataFormatYYYYMM(this.selectedDate) },
      })).calendarItems;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  lazyCalendarList = async () => {
    if (this.calendarList === null) return;
    let nextYear = 0;
    let nextMonth = 0;
    if (!this.lastLoadDate) {
      nextYear = this.selectedDate.getFullYear();
      nextMonth = this.selectedDate.getMonth() + 2;
    } else {
      const lastDate = new Date(this.lastLoadDate);
      nextYear = lastDate.getFullYear();
      nextMonth = lastDate.getMonth() + 2;
    }

    if (nextMonth > 12) {
      nextMonth -= 12;
      nextYear += 1;
    }
    const nextDateText = new Date(nextYear, nextMonth, 1);
    const nextDate = nextDateText.toISOString().slice(0, 7);

    const nextCalendarList = (await request<{ calendarItems: ICalendarMoListDto[] }>({
      method: 'get',
      url: URIs.get_calendar_list,
      params: { ...this.calendarLoadFilter, targetMonth: nextDate },
    })).calendarItems;
    // list가 덮여씌워지기 전에 변경되어야함. (앵커링 영향)
    this.lastLoadDate = nextDate;

    if (nextCalendarList.length > 0) {
      this.calendarList = [...this.calendarList, ...nextCalendarList].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
      this.nextLoadCount = 0;
    } else {
      // 공고가 없음! 없으면 최대 3연속 load
      this.nextLoadCount += 1;
      if (this.nextLoadCount < 3) {
        await this.lazyCalendarList();
      }
    }
  }

  @action
  saveJobPostingsBookmark = async (jobPostingSn: number) => {
    const res = (await request({
      method: 'put',
      url: URIs.put_job_postings_bookmark(jobPostingSn),
    }));
    return res;
  }

  @action
  deleteJobPostingsBookmark = async (jobPostingSn: number) => {
    const res = (await request({
      method: 'delete',
      url: URIs.delete_job_postings_bookmark(jobPostingSn),
    }));
    return res;
  }

  @action
  hasFilter = () => {
    const filter = this.calendarLoadFilter;
    return !(!filter.jobTitles && !filter.businessSizeTypes && !filter.locations && !filter.recruitmentTypes);
  }

  @action
  saveFilter = async (filterRq: ICalendarFilterRq) => {
    try {
      await request({
        method: 'post',
        url: URIs.post_calendar_filter,
        data: filterRq,
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadFilter = async () => {
    try {
      this.recentlyFilter = (await request({
        method: 'get',
        url: URIs.get_calendar_filter,
      }));
    } catch (e) {
      console.error(e);
    }
  }

  @action
  updateBookmarkYn = (sn: number) => {
    if (this.calendarList === null) return;
    this.calendarList = this.calendarList.map((item) => {
      const newPostrings = item.jobPostings.map((jobPosting) => {
        const tempPosting = jobPosting;
        if (tempPosting.jobPostingSn === sn) tempPosting.bookmarkYn = !tempPosting.bookmarkYn;
        return tempPosting;
      });
      return {
        ...item,
        jobPostings: newPostrings,
      };
    });
  }
}
