/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import V2ColorPalette from './V2ColorPalette';

export enum V2BorderColorsType {
  strong = 'strong',
  default = 'default',
  subtle = 'subtle',
  inverse = 'inverse',
  disabled = 'disabled',
  brand = 'brand',
  success = 'success',
  danger = 'danger',
  warning = 'warning',
  information = 'information',
  accent_green = 'accent_green',
  accent_springgreen = 'accent_springgreen',
  accent_olivegreen = 'accent_olivegreen',
  accent_yellow = 'accent_yellow',
  accent_gold = 'accent_gold',
  accent_orange = 'accent_orange',
  accent_red = 'accent_red',
  accent_pink = 'accent_pink',
  accent_purple = 'accent_purple',
  accent_violet = 'accent_violet',
  accent_cobaltblue = 'accent_cobaltblue',
  accent_skyblue = 'accent_skyblue',
  accent_cyan = 'accent_cyan',
  accent_teal = 'accent_teal',
}

export const V2BorderColors: Record<V2BorderColorsType, V2ColorPalette> = {
  strong: V2ColorPalette.C_GRAY_300,
  default: V2ColorPalette.C_GRAY_200,
  subtle: V2ColorPalette.C_GRAY_100,
  inverse: V2ColorPalette.C_GRAY_WHITE,
  disabled: V2ColorPalette.C_GRAY_100,
  brand: V2ColorPalette.C_GREEN_500,
  success: V2ColorPalette.C_GREEN_500,
  danger: V2ColorPalette.C_RED_500,
  warning: V2ColorPalette.C_YELLOW_500,
  information: V2ColorPalette.C_SKYBLUE_500,
  accent_green: V2ColorPalette.C_GREEN_500,
  accent_springgreen: V2ColorPalette.C_SPRINGGREEN_500,
  accent_olivegreen: V2ColorPalette.C_OLIVEGREEN_500,
  accent_yellow: V2ColorPalette.C_YELLOW_500,
  accent_gold: V2ColorPalette.C_GOLD_500,
  accent_orange: V2ColorPalette.C_ORANGE_500,
  accent_red: V2ColorPalette.C_RED_500,
  accent_pink: V2ColorPalette.C_PINK_500,
  accent_purple: V2ColorPalette.C_PURPLE_500,
  accent_violet: V2ColorPalette.C_VIOLET_500,
  accent_cobaltblue: V2ColorPalette.C_COBALTBLUE_500,
  accent_skyblue: V2ColorPalette.C_SKYBLUE_500,
  accent_cyan: V2ColorPalette.C_CYAN_500,
  accent_teal: V2ColorPalette.C_TEAL_500,
};
