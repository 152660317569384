import { V2BorderColors, V2BorderColorsType } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import { SpaceType, SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2BadgeSizeType, V2BadgeType } from '__pc__/constant/v2Design/V2BadgeType';

export const getBadgeRadiusStyle = (size?: V2BadgeSizeType) => {
  switch (size) {
    default:
      return { borderRadius: `${RadiusValue.S}px` };
  }
};

export const getBadgeSpaceStyle = (size?: V2BadgeSizeType) => {
  switch (size) {
    case V2BadgeSizeType.L:
      return { padding: `${SpaceValue[SpaceType.XXXS]}px ${SpaceValue[SpaceType.XS]}px` };

    case V2BadgeSizeType.M:
      return { padding: `${SpaceValue[SpaceType.NONE]}px ${SpaceValue[SpaceType.XXS]}px` };
    default:
      return {};
  }
};

// *develope* fill에 따른 borderColor가 맵핑되어야함.
export const getBadgeBorderStyle = (type: V2BadgeType, borderColorType?: V2BorderColorsType) => {
  if (!borderColorType) return;

  if (type === V2BadgeType.OutLine) {
    return {
      border: `1px solid ${V2BorderColors[borderColorType]}`,
    };
  }
  return {};
};
