import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Icon from '__pc__/components/common/v2Design/icon';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import V2Badge from '__pc__/components/common/v2Design/badge';
import { V2BadgeOption } from '__pc__/constant/v2Design/V2BadgeType';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import Icon from '__designkit__/icon/Icon';
import { useMatchHomeMatchingQuestStatus } from 'query/match/useMatchQuery';
import ImgBanner from 'assets/_v2/_common/matching_banner.svg';
import RoutePaths from 'consts/RoutePaths';
import { useHistory } from 'react-router';
import Login from 'models/Login';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import Loading from 'components/Loading';
import useToast from 'hooks/useToast';
import FileUtil from 'utils/FileUtil';
import EventMatchModel from 'models/_v2/event/EventMatchModel';
import { TOAST_TYPE } from '../_common/toast/JDToast';
import JDFullModal from '../_common/modals/JDFullModal';
import JDSimpleInfoModal from '../_common/modals/JDSimpleInfoModal';

const Frame = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;
const ContentsFrame = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y:scroll;
    .padding-contents{
        padding: ${SpaceValue.XXL}px ${SpaceValue.L}px;
        display: flex;
        flex-direction: column;
        .banner-img{
        width: 320px;
        height: 320px;
        margin-top: -51px;
        margin-bottom: -69px;
        align-self: center;
    }
    }
    .center-text{
        text-align: center;
    }
    .check-list{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: ${SpaceValue.XS}px ${SpaceValue.XXS}px;
        .left-field{
            display: flex;
            align-items: center;
            gap:4px;

        }
        :last-child{
          margin-bottom: 128px;
        }
    }
   
`;
const BtnFrame = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  position: fixed;
  bottom: 0;
  z-index: 100;
  .inner-frame{
    padding: 0 16px calc(env(safe-area-inset-bottom) + 24px) 16px;
    width: 100%;
    background-color: #FFF;
  }
  :before{
    content: "";
    display: block;
    height: 24px;
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
  }
`;

interface IMainMatchingQuestModal{
    isOpen?:boolean;
    onClose : ()=>void;
    afterLogic?:()=>void;
    login?:Login
    eventMatchModel?:EventMatchModel;

}
const MainMatchingQuestModal:FC<IMainMatchingQuestModal> = ({ isOpen, onClose, afterLogic, login = new Login(), eventMatchModel = new EventMatchModel() }) => {
  const { data: matchingQuestData, refetch } = useMatchHomeMatchingQuestStatus(!!login.userInfo);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openEventSuccessModal, setOpenEventSuccessModal] = useState(false);
  const { setToastObject } = useToast();

  const history = useHistory();
  useEffect(() => {
    if (matchingQuestData) {
      if (matchingQuestData.profilePercent >= 50 && matchingQuestData.tutorialExperienced && matchingQuestData.applied) {
        setBtnDisabled(false);
      }
    }
  }, [matchingQuestData]);

  const handleDownload = async () => {
    try {
      setIsLoading(true);

      const res = await eventMatchModel.loadEventsProfileCompleteProfileDownload();
      if (res) {
        FileUtil.fileDownload('직무별 합격자 이력서 모음', res, true);
        setIsLoading(false);
        setOpenEventSuccessModal(true);
      }
      setIsLoading(false);
    } catch (e) {
      setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '다시 시도해 주세요.', subMessage: undefined });
      console.error(e);
    }
  };
  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen]);
  if (!isOpen) return null;
  return (
    <JDFullModal handleClose={onClose}>
      <Frame>
        <ContentsFrame>
          <div className='padding-contents'>

            <V2Text
              fontStyle={V2TextOption.fontStyle.body_2_b}
              color={V2TextOption.color.brand}
              className='center-text'
            >
              잡다매칭 퀘스트
            </V2Text>
            <SpacingBlock size={SpaceValue.XS} vertical />
            <V2Text
              element={V2TextOption.element.div}
              fontStyle={V2TextOption.fontStyle.title_1_b}
              color={V2TextOption.color.default}
              className='center-text'
            >
              매칭 퀘스트 완료하고
              <br />
              <V2Text
                element={V2TextOption.element.span}
                fontStyle={V2TextOption.fontStyle.title_1_b}
                color={V2TextOption.color.brand}
                className='center-text'
              >
                합격자 이력서{`\n`}
              </V2Text>
              받아가세요!
            </V2Text>
            <SpacingBlock size={SpaceValue.XL} vertical />

            <img src={ImgBanner} alt='banner' className='banner-img' />
            <SpacingBlock size={SpaceValue.XXXL} vertical />
            <div
              className='check-list'
              role='button'
              id='matching-quest-profile'
              onClick={() => {
                history.push(RoutePaths.profile);
                onClose();
              }}
            >
              <div className='left-field'>
                <V2Icon name='check' size={V2IconOption.size.S} fill={V2IconOption.fill.subtle} />
                <V2Text
                  fontStyle={V2TextOption.fontStyle.body_1_m}
                  color={V2TextOption.color.default}
                >프로필 입력 50% 이상 작성하기
                </V2Text>
              </div>
              {
                matchingQuestData && matchingQuestData?.profilePercent >= 50
                  ? (
                    <V2Badge
                      fontStyle={V2BadgeOption.fontStyle.caption_1_sb}
                      color={V2BadgeOption.color.accent_green_subtle}
                      backgroundColor={V2BadgeOption.backgroundColor.accent_green_subtle}
                    >{matchingQuestData?.profilePercent}%
                    </V2Badge>
                  )
                  : (
                    <V2Badge
                      fontStyle={V2BadgeOption.fontStyle.caption_1_sb}
                      color={V2BadgeOption.color.subtlest}
                      backgroundColor={V2BadgeOption.backgroundColor.accent_gray_subtle}
                    >{matchingQuestData?.profilePercent}%
                    </V2Badge>
                  )
              }

            </div>
            <SpacingBlock size={SpaceValue.M} vertical />
            <div
              className='check-list'
              role='button'
              id='matching-quest-apply'
              onClick={() => {
                history.push(RoutePaths.acca_test);
                onClose();
              }}
            >
              <div className='left-field'>
                <V2Icon name='check' size={V2IconOption.size.S} fill={V2IconOption.fill.subtle} />
                <V2Text
                  fontStyle={V2TextOption.fontStyle.body_1_m}
                  color={V2TextOption.color.default}
                >역량검사 응시하기
                </V2Text>
              </div>
              {
                matchingQuestData && matchingQuestData?.applied
                  ? (
                    <V2Badge
                      fontStyle={V2BadgeOption.fontStyle.caption_1_sb}
                      color={V2BadgeOption.color.accent_green_subtle}
                      backgroundColor={V2BadgeOption.backgroundColor.accent_green_subtle}
                    >완료
                    </V2Badge>
                  )
                  : (
                    <V2Badge
                      fontStyle={V2BadgeOption.fontStyle.caption_1_sb}
                      color={V2BadgeOption.color.subtlest}
                      backgroundColor={V2BadgeOption.backgroundColor.accent_gray_subtle}
                    >미완료
                    </V2Badge>
                  )
              }
            </div>
            <SpacingBlock size={SpaceValue.M} vertical />
            <div
              className='check-list'
              role='button'
              id='matching-quest-tutorial'
              onClick={() => {
                onClose();
                if (afterLogic) afterLogic();
              }}
            >
              <div className='left-field'>
                <V2Icon name='check' size={V2IconOption.size.S} fill={V2IconOption.fill.subtle} />
                <V2Text
                  fontStyle={V2TextOption.fontStyle.body_1_m}
                  color={V2TextOption.color.default}
                >매칭 튜토리얼 체험하기
                </V2Text>
              </div>
              {
                matchingQuestData && matchingQuestData?.tutorialExperienced
                  ? (
                    <V2Badge
                      fontStyle={V2BadgeOption.fontStyle.caption_1_sb}
                      color={V2BadgeOption.color.accent_green_subtle}
                      backgroundColor={V2BadgeOption.backgroundColor.accent_green_subtle}
                    >완료
                    </V2Badge>
                  )
                  : (
                    <V2Badge
                      fontStyle={V2BadgeOption.fontStyle.caption_1_sb}
                      color={V2BadgeOption.color.subtlest}
                      backgroundColor={V2BadgeOption.backgroundColor.accent_gray_subtle}
                    >미완료
                    </V2Badge>
                  )
              }
            </div>
          </div>
        </ContentsFrame>
        <BtnFrame>
          <div className='inner-frame'>

            <V2Button
              type={V2ButtonOption.type.Fill}
              size={V2ButtonOption.size.L}
              fill={V2ButtonOption.fillType.brand_strong_default}
              fontStyle={V2ButtonOption.fontStyle.body_1_sb}
              color={V2ButtonOption.color.inverse}
              styles={{ width: '100%' }}
              buttonProps={{ disabled: btnDisabled, onClick: handleDownload }}
            >
              합격자 이력서 다운로드
            </V2Button>
            <SpacingBlock vertical size={SpaceValue.XS} />
            <V2Button
              type={V2ButtonOption.type.OutLine}
              size={V2ButtonOption.size.L}
              buttonProps={{ onClick: () => history.push(RoutePaths.info_detail(474)) }}
              fill={V2ButtonOption.fillType.neutral_subtle_default}
              fontStyle={V2ButtonOption.fontStyle.body_1_sb}
              color={V2ButtonOption.color.subtle}
              styles={{ width: '100%' }}
            >
              이벤트 자세히 보기
            </V2Button>
          </div>

        </BtnFrame>
      </Frame>
      <JDSimpleInfoModal
        title='혜택 다운로드 완료!'
        descriptions={`다운로드 받은 [합격자 프로필 이력서 모음.ZIP]을\n토대로 내 프로필을 보완해보세요. `}
        dimmed
        isOpen={openEventSuccessModal}
        onClickClose={() => {
          setOpenEventSuccessModal(false);
        }}
      />
    </JDFullModal>
  );
};
export default inject(injectStore.login, injectStore.eventMatchModel)(observer(MainMatchingQuestModal));
