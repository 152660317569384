import { Divider8Grey } from 'components/divider/Divider';
import Loading from 'components/Loading';
import JobsJd from 'components/_v2/jobs/JobsJd';
import JobsPostingInfo from 'components/_v2/jobs/JobsPostingInfo';
import JobsPostingPositions from 'components/_v2/jobs/JobsPostingPositions';
import PositionJdEditRequest, { PositionTypeAll } from 'components/_v2/positionJd/PositionJdEditRequest';
import MainHeader from 'components/_v2/_common/mainHeader/MainHeader';
import RoutePaths from 'consts/RoutePaths';
import IJobsProps from 'interfaces/_v2/jobs/IJobsProps';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import JobsModel from 'models/_v2/JobsModel';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';

const Frame = styled.div`

`;

const JobsDivider = styled(Divider8Grey)`
    margin: 32px 0;
`;

const Jobs: FC<IJobsProps> = ({ jobsModel = new JobsModel() }) => {
  const { jobPostingSn } = useParams<{ jobPostingSn: string }>();
  const [loading, setLoading] = useState<boolean | null>(null);
  const [diffX, setDiffX] = useState(0);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const history = useHistory();

  useEffect(() => {
    const init = async () => {
      if (jobPostingSn)
        setLoading(true);
      try {
        jobsModel.init();
        await jobsModel.loadJobPostingsDetail(Number(jobPostingSn));
        setLoading(false);
        setDiffX(0);
      } catch (e) {
        console.error(e);
      }
    };

    init();
  }, [jobPostingSn]);

  const handleTouchStart = (event: any) => {
    setStartX(event.touches[0].clientX);
    setStartY(event.touches[0].clientY);
  };

  const handleTouchMove = (event: any) => {
    const currentX = event.touches[0].clientX;
    const currentY = event.touches[0].clientY;

    setDiffX(startX - currentX);
  };

  const handleTouchEnd = () => {
    if (diffX === 0 || jobsModel.postingScale !== 1) return;

    if (diffX > 150) {
      const nextIdx = jobsModel.nextJobPosting();

      if (nextIdx !== -1) {
        history.push(RoutePaths.jobs_posting(nextIdx));
      }
    } else if (diffX < -150) {
      const beforeIdx = jobsModel.beforeJobPosting();

      if (beforeIdx !== -1) {
        // IOS Web에서 뒤로가기 스와이핑 모션과 겹치는 현상으로 인해 뒤로가기로 통일.
        history.goBack();
      }
    }
    setDiffX(0); // reset
  };

  return (
    <>
      {!loading
        ? (
          <>
            <div
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              <MainHeader
                title={jobsModel.jobPosting?.companyName || 'JOBDA'}
                backable
                alarm={false}
                gnb={false}
                search={false}
                share
                shareDescription={jobsModel.jobPosting?.name}
                handleTitleClick={() => {
                  history.push(RoutePaths.company_details(jobsModel.jobPosting?.companySn));
                }}
              />
              <Frame>
                <JobsPostingInfo jobPostingSn={Number(jobPostingSn)} />
                <JobsDivider />
                <JobsPostingPositions />
                {jobsModel.jobPosting?.content
                && (
                <>
                  <JobsDivider />
                  <JobsJd />
                </>
                )}
                <PositionJdEditRequest type={PositionTypeAll.JOBS} />
              </Frame>
            </div>
          </>
        )
        : <Loading />}

    </>
  );
};

export default inject(injectStore.jobsModel)(observer(Jobs));
