import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { ButtonHTMLAttributes, ReactElement } from 'react';
import styled, { css } from 'styled-components';

const StyledButtonFrame = styled.button < { width?: string, height?: string, labelYn: boolean, textColor?:string, backgroundColor?:string }>`

  &.jd-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: 4px;
    width: ${(props) => (props.width ? props.width : '100%')};
    height: ${(props) => (props.width ? props.height : '100%')};
    
    span {
      width: max-content;
    }

    color: ${colors.JOBDA_BLACK};
    background-color: ${colors.WHITE_100};

    :disabled {
      opacity: 0.64;
      color: ${colors.CG_60};
      background-color: ${colors.CG_40};
    }
  }
  &.primary {
    color: ${colors.WHITE_100};
    background-color: ${colors.JOBDA_BLACK};
    :active:not(:disabled){
      background-color: ${colors.CG_80};
    }
  }
  &.custom {
    color: ${(props) => props.textColor};
    background-color: ${(props) => props.backgroundColor};
    /* :active:not(:disabled){
      background-color: ${colors.CG_80};
    } */
  }
  &.secondary {
    color: ${colors.CG_90};
    background-color: ${colors.CG_40};
    :active:not(:disabled){
      background-color: ${colors.CG_60};
    }
  }

  &.outLined {
    border: 1px solid ${colors.JOBDA_BLACK};
    ${(props) => !props.labelYn && `
      border-color: ${colors.CG_50};
    `}
    :active:not(:disabled){
      background-color: ${colors.CG_40};
    }
    :disabled {
      color: ${colors.CG_50};
      background-color: ${colors.WHITE_100};
      border-color: ${colors.CG_50};
    }
  }

  &.small {
    font: ${Fonts.B3_Bold};
    padding: 8px 16px;
    height: 32px;

    span {
      font: ${Fonts.B3_Bold};
    }
  }
  &.medium {
    font: ${Fonts.B2_Bold};
    padding: 10px 20px;
    height: 38px;

    span {
      font: ${Fonts.B2_Bold};
    }
  }
  
  &.large {
    font: ${Fonts.B2_Bold};
    padding: 14px 22px;
    height: 46px;

    span {
      font: ${Fonts.B2_Bold};
    }
  }

  &.round {
    border-radius: 17px;
  }

 

  // Type Text, Type Link
  &.type-text, &.type-link {
    background-color: inherit;
    padding: 0;

    :active:not(:disabled){
      color: ${colors.CG_70};
    }
    :disabled {
      color: ${colors.CG_50};
      background-color: inherit;
    }
  }

  &.large.type-text, &.large.type-link {
    font: ${Fonts.B1_Medium};

    span {
      font: ${Fonts.B1_Medium};

    }
  }

  &.medium.type-text, &.medium.type-link {
    font: ${Fonts.B2_Medium};

    span {
      font: ${Fonts.B2_Medium};
    }
  }

  &.small.type-text, &.small.type-link {
    font: ${Fonts.B3_Medium};

    span {
      font: ${Fonts.B3_Medium};
    }
  }
  
  &.type-link {
    text-decoration: underline;
    text-underline-position: under; // IOS 지원 X
    text-underline-offset : 1px; // Opera Android 
  }

`;

export const IconFrame = styled.div<{ mode: string, type: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${(props) => (props.mode === '' || props.mode === 'secondary') && css`
    &[aria-disabled=true] {
      >div {
        opacity: 0.5;
      }
    }
  `};

  ${(props) => props.mode === 'primary' && css`
    >div {
      path{
        fill: ${colors.WHITE_100};
      }
    }

    &[aria-disabled=true] {
      >div {
        opacity: 0.5;
        path{
          fill: ${colors.JOBDA_BLACK};
        }
      }
    }
  `};

  ${(props) => (props.type === 'type-icon' ? css`
    margin:0;
  ` : css`
    &.icon-left{
      margin-right:6px;
      margin-left:-6px;
    }
    &.icon-right{
      margin-left:6px;
      margin-right:-6px;
    }
  `)};

  ${(props) => (props.type === 'type-text' || props.type === 'type-link') && css`
    &[aria-disabled=true] {
      >div {
        path{
          fill: ${colors.CG_50};
        }
      }
    }
    &.icon-left{
      margin-right:2px;
      margin-left:-2px;
    }
    &.icon-right{
      margin-left:2px;
      margin-right:-2px;
    }

  `};

`;

export enum ButtonMode {
  DEFAULT = 'DEFAULT',
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  CUSTOM = 'CUSTOM',
}

export enum ButtonType {
  BUTTON = 'BUTTON',
  TEXT = 'TEXT',
  ICON = 'ICON', // Label X
  LINK = 'LINK',
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  width?: string;
  height?: string;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  round?: boolean;
  label?: string;
  onClick?: () => void;
  icon?: ReactElement;
  iconRight?: boolean;
  outLined?: boolean;
  buttonMode?: ButtonMode;
  buttonType?: ButtonType;
  className?: string;
  form?: string;
  textColor?:string;
  backgroundColor?:string
}
export const Button: React.FC<ButtonProps> = ({
  width = '100%',
  height = '100%',
  buttonMode = ButtonMode.DEFAULT,
  size = 'medium',
  label,
  disabled = false,
  round = false,
  icon,
  outLined = false,
  buttonType = ButtonType.BUTTON,
  iconRight = buttonType === ButtonType.LINK, // Link일 경우 default는 IconRight
  className = '',
  onClick,
  textColor,
  backgroundColor,
  form,
  ...props
}) => {
  const rounded = round ? 'round' : '';
  const outLine = outLined ? 'outLined' : '';

  const classMode = (mode: ButtonMode) => {
    switch (mode) {
      case ButtonMode.DEFAULT: {
        return '';
      }
      case ButtonMode.PRIMARY: {
        return 'primary';
      }
      case ButtonMode.SECONDARY: {
        return 'secondary';
      }
      case ButtonMode.CUSTOM: {
        return 'custom';
      }
      default: return '';
    }
  };

  const classType = (type: ButtonType) => {
    switch (type) {
      case ButtonType.BUTTON: {
        return 'type-button';
      }
      case ButtonType.TEXT: {
        return 'type-text';
      }
      case ButtonType.ICON: {
        return 'type-icon';
      }
      case ButtonType.LINK: {
        return 'type-link';
      }
      default: return '';
    }
  };

  const renderIcon = () => (
    <IconFrame mode={classMode(buttonMode)} type={classType(buttonType)} aria-disabled={disabled} className={buttonType !== ButtonType.ICON ? `icon${iconRight ? '-right' : '-left'}` : ''}>
      {icon}
    </IconFrame>
  );

  return (
    <StyledButtonFrame
      width={width}
      height={height}
      labelYn={!!label}
      textColor={textColor}
      backgroundColor={backgroundColor}
      type='button'
      className={['jd-button', `${size}`, rounded, outLine, classMode(buttonMode), classType(buttonType), className].join(' ')}
      disabled={disabled}
      onClick={onClick}
      form={form || undefined}
      {...props}
    >
      {
        icon && !iconRight && renderIcon()
      }
      <span>{label}</span>
      {
        icon && iconRight && renderIcon()
      }
    </StyledButtonFrame>
  );
};
