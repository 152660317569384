import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { lineClampBox } from 'consts/_v2/_common/style/mixins';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Login from 'models/Login';
import JDALink from 'components/JDALink';
import RoutePaths from 'consts/RoutePaths';
import Icon from '__designkit__/icon/Icon';
import PositionListModel from 'models/_v2/PositionListModel';
import { PositionOrderType } from 'consts/_v2/position/PositionType';
import { IPositionListItem } from 'interfaces/_v2/positionList/IPositionList';
import { useHistory, useLocation } from 'react-router';
import ImgDumy from 'assets/_v2/positionList/img_dumy.png';
import Context from 'models/Context';
import { IAccCenterPosition } from 'interfaces/_v2/acc/IAccGate';
import MainPersonalPositionCard from 'components/_v2/main/MainPersonalPositionCard';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { usePositionProfileMatching } from 'query/position/usePositionQuery';
import { userInfo } from 'os';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import useProfileConditions from 'query/profiles/useProfileConditionsQuery';
import Main from 'models/_v2/Main';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption, V2ButtonType } from '__pc__/constant/v2Design/V2ButtonType';
import { V2BackgroundColors, V2BackgroundColorsType } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { V2BorderColors, V2BorderColorsType } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2FillColorsType } from '__pc__/constant/v2Design/Colors/V2FillColors';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import MainTutorialModal from './tutorial/MainTutorialModal';

const Frame = styled.div`
  margin-top: 32px;
`;

const TitleFrame = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 ${SpaceValue.L}px ${SpaceValue.L}px;

    .ad-frame{
      display: flex;
      background-color:${colors.JOBDA_WHITE};
      border:1px solid rgb(0, 0,0,0.05);
      border-radius: 4px;
      justify-content: center;
      align-items: center;
      padding:4px 6px;
      height: 24px;

      .ad-frame-text{
        font: ${Fonts.B4_Medium};
        color:${colors.CG_50}
      }
    }
`;

const MainRecruitFrame = styled.div`
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    ::-webkit-scrollbar {
          display: none;
      }
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    .scroll-area {
      display: flex;
      gap: 16px;
      width: fit-content;

      .personal-state {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 24px 16px 16px;
        width: 160px;
        height: 214px;
        border-radius: 8px;
        border: 1px solid ${V2BorderColors.brand};
        background: #E5FFF0;
        margin-left: 16px;
        
        .main-title {
          margin-top: 8px;
        }

        .state-info {
          position: absolute;
          bottom: 16px;

          &.percentage {
            right:16px;
            font-size: 32px;
            font-weight: 700;
            color: ${colors.G_200};
          
            .unit {
              font: ${Fonts.H4_Bold};
              color: ${colors.G_200};
            }
          }
        }

        /* 로그인 x, 프로필 x */
        &.no-state {
          border: 1px solid ${V2BorderColors.default};
          background: ${V2BackgroundColors.surface_default};

          .sub-title {
            color: ${colors.CG_60};
          }

          .percentage {
            color: ${colors.CG_60};

            .unit {
              color: ${colors.CG_60};
            }
          }
        }
      }
    }
`;

const MorePosition = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 214px;
  width: 160px;
  gap:12px;
  .icon-circle{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid ${colors.CG_40};
  }
  .fit-text{
    font:${Fonts.B3_Bold};
    color:${colors.CG_60};
  }
`;

interface IPersonalState{
  login?:Login;
  positionListModel?:PositionListModel;
  matchApplyModel?:MatchApplyModel;
  main?:Main

}
const SkeletonFrame = styled.div`
  width: 160px;
  border-radius: 8px;
  overflow: hidden;
`;

const SkeletonImage = styled.div`
  width: 160px;
  height: 112px;
  background-color: ${colors.CG_40};
`;

const SkeletonText = styled.div`
  height: 20px;
  margin: 8px 0;
  background-color: ${colors.CG_40};
  border-radius: 4px;
`;
const SkeletonMain = styled.div`
       position: relative;
        display: flex;
        flex-direction: column;
        padding: 24px 16px 16px;
        width: 160px;
        height: 214px;
        border-radius: 8px;
        background-color: ${colors.CG_40};
        margin-left: 16px;
`;
const SkeletonCard = () => (
  <SkeletonFrame>
    <SkeletonImage />
    <SkeletonText style={{ width: '80%' }} />
    <SkeletonText style={{ width: '60%' }} />
  </SkeletonFrame>
);
const MainPersonalPosition:FC<IPersonalState> = ({ login = new Login(), main = new Main(), matchApplyModel = new MatchApplyModel() }) => {
  const [profileCompletePercent, setProfileCompletePercent] = useState<number>(0);
  const { data: positionProfileMatching } = usePositionProfileMatching(11, true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [positions, setPositions] = useState<IPositionListItem[]>([]);

  const history = useHistory();
  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      if (positionProfileMatching) {
        if (login.userInfo) {
          await matchApplyModel.loadMatchingProfile();
          setProfileCompletePercent(matchApplyModel.profile?.completionRate || 0);
        }
        const filteredPositions = positionProfileMatching.filter((position) => position.companySn !== 361);
        setPositions(filteredPositions);
        setIsLoading(false);
      }
    };
    init();
  }, [positionProfileMatching, login]);

  return (
    <Frame>
      <TitleFrame>
        {!login.userInfo ? (
          <V2Text
            element={V2TextOption.element.span}
            fontStyle={V2TextOption.fontStyle.title_3_b}
            color={V2TextOption.color.strong}
            ellipsis
          >
            로그인하고 포지션 추천받기
          </V2Text>
        ) : (
          <V2Text
            element={V2TextOption.element.span}
            fontStyle={V2TextOption.fontStyle.title_3_b}
            color={V2TextOption.color.strong}
            ellipsis
          >
            내 조건에 맞는 추천 포지션
          </V2Text>
        )}
      </TitleFrame>
      <MainRecruitFrame>
        {/* [D] 로그인 전 후로 카드 내용이 바뀝니다.
        로그인 전 - JDALink에 no-state class 추가
        프로필 0%일 때 - JDALink에 no-state class 추가
        프로필 0%가 아닐때 - JDALink에 class 추가 없음
        */}
        <div className='scroll-area'>
          {
            isLoading ? (
              <SkeletonMain />
            ) : (
              !login.userInfo ? (
                <JDALink to={RoutePaths.login} className='personal-state no-state'>
                  <V2Text
                    element={V2TextOption.element.span}
                    fontStyle={V2TextOption.fontStyle.body_3_m}
                    color={V2TextOption.color.subtlest}
                    ellipsis
                  >
                    프로필 채우고
                  </V2Text>
                  <V2Text
                    className='main-title'
                    element={V2TextOption.element.div}
                    fontStyle={V2TextOption.fontStyle.body_1_b}
                    color={V2TextOption.color.default}
                    ellipsis
                  >
                    기업 매칭의<br />기회를 잡아보세요!
                  </V2Text>
                  <V2Button
                    className='state-info'
                    type={V2ButtonType.Text}
                    size={V2ButtonOption.size.XS}
                    fontStyle={V2ButtonOption.fontStyle.body_3_sb}
                    iconName='arrow-right'
                    iconPosition={V2ButtonOption.iconPosition.right}
                  >
                    로그인하기
                  </V2Button>
                </JDALink>
              ) : (
                positions.length === 0
                  ? (
                    <JDALink to={RoutePaths.profile} className='personal-state no-state'>
                      <V2Text
                        element={V2TextOption.element.span}
                        fontStyle={V2TextOption.fontStyle.body_3_m}
                        color={V2TextOption.color.subtlest}
                        ellipsis
                      >
                        {`현재 ${login.userInfo.name}님의`}
                      </V2Text>
                      <V2Text
                        className='main-title'
                        element={V2TextOption.element.div}
                        fontStyle={V2TextOption.fontStyle.body_1_b}
                        color={V2TextOption.color.default}
                        ellipsis
                      >
                        맞춤 포지션이<br />없습니다.
                      </V2Text>
                      <V2Button
                        className='state-info'
                        type={V2ButtonType.Text}
                        size={V2ButtonOption.size.XS}
                        fontStyle={V2ButtonOption.fontStyle.body_3_sb}
                        iconName='arrow-right'
                        iconPosition={V2ButtonOption.iconPosition.right}
                      >
                        선호정보 입력하기
                      </V2Button>
                    </JDALink>
                  )
                  : (
                    <JDALink to={RoutePaths.profile} className={`personal-state ${profileCompletePercent === 0 ? 'no-state' : ''}`}>
                      <V2Text
                        element={V2TextOption.element.span}
                        fontStyle={V2TextOption.fontStyle.body_3_m}
                        color={V2TextOption.color.brand}
                        ellipsis
                      >
                        맞춤 제안 받기
                      </V2Text>
                      <V2Text
                        className='main-title'
                        element={V2TextOption.element.div}
                        fontStyle={V2TextOption.fontStyle.body_1_b}
                        color={V2TextOption.color.default}
                        ellipsis
                      >
                        {login.userInfo?.name}님의<br />프로필 완성도
                      </V2Text>
                      <div className='state-info percentage'>
                        { profileCompletePercent }<span className='unit'>%</span>
                      </div>
                    </JDALink>
                  )
              )
            )
          }

          {(isLoading) ? (
            <>
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
            </>
          ) : (
            positions && positions.map((item) => (
              <MainPersonalPositionCard key={item.positionSn} item={item} />
            ))
          )}
          {
            positions.length <= 3 && (
              <MorePosition onClick={() => history.push(RoutePaths.position)}>
                <div className='icon-circle'>
                  <Icon name='add' size={24} color={colors.CG_60} />
                </div>
                <div className='fit-text'>포지션 더보기</div>
              </MorePosition>
            )
          }
        </div>
      </MainRecruitFrame>
      <SpacingBlock vertical size={SpaceValue.XXL} />
    </Frame>
  );
};

export default inject(injectStore.main, injectStore.jobsModel, injectStore.login, injectStore.main, injectStore.matchApplyModel)(observer(MainPersonalPosition));
