import URIs from 'consts/URIs';
import { INotification, INotificationRs } from 'interfaces/_v2/notification/INotificationRqRs';
import { action, observable } from 'mobx';
import { request } from 'utils/request';

export default class NotificationModel {
  @observable notifications: INotification[] = [];
  @observable unreadCount: number = 0;
  @observable unReadYn: boolean = false;

  @action
  loadNotifications = async () => {
    try {
      const res = await request<INotificationRs>({
        method: 'get',
        url: URIs.get_notifications,
      });
      this.notifications = res.notifications;
      this.unreadCount = res.unreadCount;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  deleteNotifications = async (notificationSn: number[]) => {
    try {
      await request({
        method: 'delete',
        url: URIs.delete_notifications,
        data: { notificationSnList: notificationSn },
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action
  readNotifications = async (notificationSn: number[]) => {
    try {
      await request({
        method: 'put',
        url: URIs.put_notifications,
        data: { notificationSnList: notificationSn },
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action
  setUnRead = (readYn: boolean) => {
    this.unReadYn = readYn;
  }

  @action
  setUnReadCount = (count: number) => {
    this.unreadCount = count;
  }
}
export const notificationModel = new NotificationModel();
