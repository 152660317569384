/* eslint-disable consistent-return */
import URIs from 'consts/URIs';
import { PARTNERSHIP_AUTH_TYPE, partnershipAuthType } from 'consts/_v2/partnership/partnership';
import { IMyPagePartnership, IRegisteredCouponRs, IUniversityInfoRq, PartnershipType } from 'interfaces/myPage/partership/IMyPagePartnership';
import { INameCodeRs } from 'interfaces/rqrs/ICommonRqRs';
import IMajorAliasListGetRs, { IMajorAliasListGetDto } from 'interfaces/rqrs/IMajorAliasListGetRs';
import { action, observable } from 'mobx';
import { request } from 'utils/request';

export default class MyPagePartnershipModel {
  @observable verified: boolean = false;
  @observable userInfo: IMyPagePartnership | null = null;
  @observable eventVerified:boolean = false;
  @observable eventCodeChecked:boolean = false;
  @observable hasValidCoupons:boolean = false;
  @observable eventCodeList: IRegisteredCouponRs | null = null;
  @observable partnership: string[] = [];
  @observable universities: INameCodeRs[] = [];
  @observable isAuthCall: number = 0;
  @observable isUniversityAuthSuccess: boolean = false;
  @observable isCodeAuthSuccess: boolean = false;
  @observable majorAliases: IMajorAliasListGetDto[] = [];

  @action
  init = () => {
    this.verified = false;
    this.userInfo = null;
    this.eventVerified = false;
    this.eventCodeChecked = false;
    this.hasValidCoupons = false;
    this.eventCodeList = null;
    this.partnership = [];
    this.universities = [];
    this.isAuthCall = 0;
    this.isUniversityAuthSuccess = false;
    this.isCodeAuthSuccess = false;
    this.majorAliases = [];
  }

  @action
  loadCouponsCheck = async (couponCode:string) => {
    try {
      const res = await request({
        method: 'get',
        url: URIs.get_coupons_check,
        params: { couponCode },
      });
      if (res) {
        this.eventCodeChecked = true;
      }
      return res;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  registerCoupons = async (couponCode:string) => {
    try {
      const res = await request({
        method: 'post',
        url: URIs.post_coupons_register,
        params: { couponCode },
      });
      return res;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadCouponsRegistered = async () => {
    try {
      const res = await request<IRegisteredCouponRs>({
        method: 'get',
        url: URIs.get_coupons_registered,
      });
      this.eventCodeList = res;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadPartnershipUserInfo=async () => {
    this.userInfo = await request<IMyPagePartnership>({
      method: 'get',
      url: URIs.get_partnership_user_info,
    });
    if (this.userInfo) this.verified = this.userInfo.organizationVerified || this.userInfo.universityVerified;
  }

  @action
  registerUniversities = async (data: IUniversityInfoRq) => {
    const res = await request({
      method: 'post',
      url: URIs.post_partnership_user_register_universities,
      data: { universityInfo: data },
    });
    return res;
  }

  @action
  updateUniversities = async (majorName: string, majorCode: number, studentId: string) => {
    const res = await request({
      method: 'put',
      url: URIs.put_partnership_user_universities,
      data: { majorName, majorCode, studentId },
    });
    return res;
  }

  @action
  registerOrganization = async (code: string) => {
    const res = await request({
      method: 'post',
      url: URIs.post_partnership_user_register_organization,
      data: { code },
    });
    return res;
  }

  @action
  checkSchools = async (schoolCode: number) => {
    try {
      const res = (await request<{ isPartnership: boolean }>({
        method: 'get',
        url: URIs.get_partnership_universities_school_code(schoolCode),
      })).isPartnership;
      return res;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  @action
  searchPartnerShip = async (partnershipType: PartnershipType) => {
    try {
      this.partnership = (await request<{ partnerships: string[] }>({
        method: 'get',
        url: URIs.get_partnership,
        params: { partnershipType },
      })).partnerships;
    } catch (e) {
      console.error(e);
      this.partnership = [];
    }
  }

  @action
  searchSchools = async (name?: string) => {
    try {
      this.universities = (await request<{ universities: INameCodeRs[] }>({
        method: 'get',
        url: URIs.get_partnership_universities,
        params: { name },
      })).universities;
    } catch (e) {
      console.error(e);
      this.universities = [];
    }
  }

  @action
  searchMajorAliases = async (name: string) => {
    try {
      this.majorAliases = (await request<IMajorAliasListGetRs>({
        method: 'get',
        url: URIs.get_register_major_aliases,
        params: { name },
      })).majorAliases;
    } catch (e) {
      console.error(e);
      this.majorAliases = [];
    }
  }

  @action
  sendEmail = async (email: string, schoolCode: string) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const res = await request({
        method: 'post',
        url: URIs.post_partnership_verification_university_send,
        data: { email, schoolCode },
      });
      if (res) this.isAuthCall++;
    } catch (e) {
      throw e;
    }
  }

  @action
  checkEmail = async (email: string, token: string) => {
    const res = await request({
      method: 'post',
      url: URIs.post_partnership_verification_university_verify,
      data: { email, token },
    });
    if (res) this.isUniversityAuthSuccess = true;
    else {
      this.isUniversityAuthSuccess = false;
    }
  }

  @action
  checkCode = async (code: string) => {
    try {
      const res = await request({
        method: 'post',
        url: URIs.post_partnership_verification_organization_send,
        data: { code },
      });
      if (res) this.isCodeAuthSuccess = true;
    } catch (e) {
      this.isCodeAuthSuccess = false;
      throw e;
    }
  }

  @action
  deleteVerification = async (type: partnershipAuthType) => {
    try {
      await request({
        method: 'delete',
        url: URIs.delete_partnership_verification,
        params: { partnershipType: type === PARTNERSHIP_AUTH_TYPE.UNIVERSITY ? 'UNIVERSITY' : 'ORGANIZATION' },
      });
    } catch (e) {
      console.error(e);
    }
  }
}
