import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import ImgBackground from 'assets/_v2/company/img_background_gradation.png';
import JDAPopover from 'components/_v2/_common/popover/JDPopover';
import CompanyGraphInteraction from 'components/_v2/company/CompanyGraphInteraction';
import CompanyReportFourQuadrants from 'components/_v2/company/CompanyReportFourQuadrants';
import RoutePaths from 'consts/RoutePaths';
import { JobTitleFits, MatchingReportPositionDto } from 'interfaces/_v2/matchingReport/IMatchingReportRs';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import CompanyModel from 'models/_v2/CompanyModel';
import MatchReportModel from 'models/_v2/MatchReportModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { getDDayString, str2DateTime } from 'utils/DateUtils';
import { formatNumber } from 'utils/NumberFormatUtil';

const Frame = styled.div`
  /* padding: 0 24px 40px 24px; */
  background: linear-gradient(180deg, #f4f4f5 93.18%, #fff 100%);
  aspect-ratio: 360/778;
  width: 100%; ;
`;
const BackgroundGradation = styled.div`
  background-image: url(${ImgBackground});
  background-size: cover;
  width: 100%;
  aspect-ratio: 360/502;
  flex-shrink: 0;
  z-index: 1;
`;

const TitleMessage = styled.div`
  padding-top: 40px;
  padding-left: 24px;
  font: ${Fonts.B1_Medium_P};
  color: ${Colors.JOBDA_BLACK};
  .bold-text {
    font: ${Fonts.B1_Bold};
  }
  span {
    display: inline-block;
  }
`;
const MessageFrame = styled.div`
  padding: 0 24px;
`;
const MatchingMessage = styled.div`
  background-color: ${Colors.WHITE_100};
  border-radius: 16px;
  padding: 32px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  .title {
    display: flex;
    align-items: center;
    .icon {
    }
    .text {
      font: ${Fonts.B3_Medium};
      color: ${Colors.JOBDA_BLACK};
      margin-left: 4px;
    }
  }
  .message-frame {
    overflow: hidden;
    overflow-y: scroll;
    max-height: 286px;
    padding-right: 12px;
    width: 100%;
    .message-text {
      font: ${Fonts.B2_Medium_P};
      color: ${Colors.JOBDA_BLACK};
      white-space: pre-line;
      .strong {
        font: ${Fonts.B2_Bold_P};
      }
    }
    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 0px;
      background-color: ${Colors.CG_50};
    }
    ::-webkit-scrollbar-track {
      background-color: ${Colors.WHITE_100};
    }
  }
`;

const PercentFrame = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${Colors.WHITE_100};
    border-radius: 16px;
    aspect-ratio: 360/320;
    white-space: pre-line;
    position: relative;
    .sub-text{
      padding:32px 0 0 24px;
        font:${Fonts.B2_Medium_P};
        color:${Colors.CG_70};
    }
    .title-text{
      padding:16px 0 0 24px;
        font:${Fonts.H5_Bold};
        color:${Colors.JOBDA_BLACK};
    }
    .percentage {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 282px;
  aspect-ratio:360/320;
  padding: 32px 0 0 32px;
  border-radius: 16px;

}

.percentageNumber {
    position: absolute;
    right: 24px;
    bottom: 20px;
    font:${Fonts.H1_Bold};
    color:${Colors.S_300};
    .percentageUnit {
      font:${Fonts.H3_Bold};
      color:${Colors.S_300};
    }
  }
  
`;

const CompareFrame = styled.div`
  padding: 64px 24px 0 24px;
  .compare-title {
    display: flex;
    align-items: baseline;
    font: ${Fonts.H4_Bold};
    color: ${Colors.JOBDA_BLACK};
    white-space: pre;
    .color-text{
      font:${Fonts.B2_Bold};
      color:${Colors.G_200};
    }
  }
  .move-frame {
    display: flex;
    align-items: center;
    .move-text {
      font: ${Fonts.B3_Medium};
      color: ${Colors.B_100};
    }
  }
`;

const MatchFrame = styled.div`
  display: flex;
  align-items: center;
  min-height: 28px;
  .match-title {
    font: ${Fonts.B4_Medium};
    color: ${Colors.CG_70};
    margin-right: 24px;
  }
  .match-detail-frame {
    display: flex;
    align-items: center;
    .match-detail {
      font: ${Fonts.B3_Bold};
      color: ${Colors.JOBDA_BLACK};
    }
    .match-plus {
      white-space: pre;
      font: ${Fonts.B3_Bold};
      color: ${Colors.G_200};
    }
    .match-badge {
      display: flex;
      border-radius: 4px;
      background: linear-gradient(90deg, #4bd667 0%, #40ccc6 100%);
      padding: 6px 8px;
      margin-left: 12px;
      .match-badge-text {
        font: ${Fonts.B3_Medium};
        color: ${Colors.WHITE_100};
      }
    }
  }
`;

const PositionFrame = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 16px 24px 24px;
  border-bottom: 1px solid ${Colors.CG_40};
  .left-frame {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .position-title {
      font: ${Fonts.B2_Bold_P};
      color: ${Colors.JOBDA_BLACK};
    }
    .sub-frame {
      display: flex;
      gap: 8px;
      align-items: center;
      .sub-title {
        font: ${Fonts.B3_Medium};
        color: ${Colors.G_200};
      }
      .d-day-frame {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 6px;
        border-radius: 4px;
        border: 1px solid ${Colors.CG_50};
        background-color: ${Colors.WHITE_100};
        color: ${Colors.CG_90};
      }
    }
  }
  .right-frame {
    display: flex;
    align-items: center;
  }
`;
const PopOverFrame = styled.div`
  color: ${Colors.WHITE_100};
  > h1 {
    font: ${Fonts.B3_Medium};
  }
`;
const TotalFrame = styled.div``;
interface ICompanyDetailMatchingReport {
  companyModel?: CompanyModel;
  matchReportModel?: MatchReportModel;
  login?: Login;
}
const CompanyReportSuggestion: FC<ICompanyDetailMatchingReport> = ({
  login = new Login(),
  companyModel = new CompanyModel(),
  matchReportModel = new MatchReportModel(),
}) => {
  const history = useHistory();
  const [otherPositionList, setOtherPositionList] = useState<
    MatchingReportPositionDto[]
  >([]);
  const [fitPositionList, setFitPositionList] = useState<JobTitleFits[]>([{ name: '', fitYn: false, code: '0' }]);
  const MatchingPositionList = () => {
    const [openYn, setOpenYn] = useState(false);
    const handleClick = () => {
      setOpenYn(!openYn);
    };
    const handleFrameClick = async (item: MatchingReportPositionDto) => {
      try {
        matchReportModel.currentReportPosition = item;
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.get('positionSn')) {
          queryParams.set('positionSn', String(item.sn));
          const updatedURL = `${window.location.pathname}?${queryParams.toString()}`;
          window.history.replaceState({}, document.title, updatedURL);
        }
        await matchReportModel.loadMatchReportPositionsDetail(item.sn);
        if (matchReportModel.matchReportCompaniesPositions) {
          const filteredPositions = matchReportModel.matchReportCompaniesPositions.positions.filter(
            (position) => position !== item,
          );
          setOtherPositionList(filteredPositions);
        }
        if (matchReportModel.matchReportPositionsDetail) {
          if (matchReportModel.matchReportPositionsDetail.profileFit.jobTitleFit.jobTitleFits.length >= 1) {
            const matchList = matchReportModel.matchReportPositionsDetail.profileFit.jobTitleFit.jobTitleFits.filter(
              (position) => position.fitYn === true,
            );
            if (matchList.length >= 1) {
              setFitPositionList(matchList);
            } else {
              setFitPositionList(matchReportModel.matchReportPositionsDetail.profileFit.jobTitleFit.jobTitleFits);
            }
          }
        }
        setOpenYn(!openYn);
      } catch (e) {
        console.error(e);
      }
    };
    useEffect(() => {
      const init = async () => {
        if (matchReportModel.matchReportCompaniesPositions) {
          const filteredPositions = matchReportModel.matchReportCompaniesPositions.positions.filter(
            (position) => position !== matchReportModel.currentReportPosition,
          );
          setOtherPositionList(filteredPositions);
        }
        if (matchReportModel.matchReportPositionsDetail) {
          if (matchReportModel.matchReportPositionsDetail.profileFit.jobTitleFit.jobTitleFits.length >= 1) {
            const matchList = matchReportModel.matchReportPositionsDetail.profileFit.jobTitleFit.jobTitleFits.filter(
              (position) => position.fitYn === true,
            );
            if (matchList.length >= 1) {
              setFitPositionList(matchList);
            } else {
              setFitPositionList(matchReportModel.matchReportPositionsDetail.profileFit.jobTitleFit.jobTitleFits);
            }
          }
        }
      };
      init();
    }, [matchReportModel.matchReportCompaniesPositions, matchReportModel.matchReportPositionsDetail]);
    return (
      <TotalFrame>
        <PositionFrame role='button' onClick={handleClick}>
          <div className='left-frame'>
            <div className='position-title'>
              {matchReportModel.currentReportPosition?.name}
            </div>
            <div className='sub-frame'>
              <div className='sub-title'>{matchReportModel.currentReportPosition?.responseType === 'ACCEPTED' ? '수락 완료' : '답변 대기 중'}</div>
              <div className='d-day-frame'>
                <div className='d-day-text'>
                  {getDDayString(
                    str2DateTime(
                      matchReportModel.currentReportPosition
                        ?.decisionDueDateTime || '',
                    ),
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='right-frame'>
            {matchReportModel.matchReportCompaniesPositions
              && matchReportModel.matchReportCompaniesPositions.positions.length
                > 1
              && (openYn ? (
                <Icon name='arrow-top' size={40} />
              ) : (
                <Icon name='arrow-bottom' size={40} />
              ))}
          </div>
        </PositionFrame>

        {openYn
          && otherPositionList.map((item, index) => (
            <PositionFrame
              key={`position-list-${item.sn}`}
              onClick={() => handleFrameClick(item)}
            >
              <div className='left-frame'>
                <div className='position-title'>{item.name}</div>
                <div className='sub-frame'>
                  <div className='sub-title'>{item.responseType === 'ACCEPTED' ? '수락 완료' : '답변 대기 중'}</div>
                  <div className='d-day-frame'>
                    <div className='d-day-text'>
                      {getDDayString(
                        str2DateTime(item.decisionDueDateTime || ''),
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </PositionFrame>
          ))}
      </TotalFrame>
    );
  };

  return (
    <>
      {MatchingPositionList()}
      <Frame>
        <BackgroundGradation>
          <TitleMessage>
            <span className='bold-text'>
              {companyModel.companyHeader?.companyHeaderDto.name}
            </span>
            <span>은</span>
            <br />
            <span className='bold-text'>{login.userInfo?.name}</span>님의 역량을 빛낼 좋은 기회입니다!
            <br /> 매칭리포트에서 자세히 확인해 보세요.
          </TitleMessage>
          <SpacingBlock size={24} vertical />
          <MessageFrame>
            <MatchingMessage>
              <div className='title'>
                <Icon
                  name='verified-filled'
                  color={Colors.BLACK_100}
                  size={24}
                />
                <div className='text'>매칭 제안</div>
              </div>
              <div className='message-frame'>
                <div className='message-text'>
                  {matchReportModel.matchReportPositionsDetail?.offerMessage}
                </div>
              </div>
            </MatchingMessage>
          </MessageFrame>
          <MessageFrame>
            <PercentFrame>
              <div className='sub-text'>{`내 프로필과 \n 역량검사 결과 기반으로 분석한`}</div>
              <div className='title-text'>{`${login.userInfo?.name}님과 \n 우리가 잘 맞을 확률`}</div>
              <CompanyGraphInteraction />
              <span className='percentageNumber'>
                {matchReportModel.matchReportPositionsDetail?.score.matchingTotalScore.toFixed(
                  1,
                )}
                <span className='percentageUnit'>%</span>
              </span>
            </PercentFrame>
          </MessageFrame>
        </BackgroundGradation>
      </Frame>
      <CompanyReportFourQuadrants
        profileScore={
          matchReportModel.matchReportPositionsDetail?.score.profileScore
        }
        competencyScore={
          matchReportModel.matchReportPositionsDetail?.score.competencyScore
        }
      />
      <SpacingBlock size={64} vertical />
      <CompareFrame>
        <div className='compare-title'>
          내 프로필 <div className='color-text'>{` vs `}</div> 공고
        </div>
        <div className='compare-title'>
          주요 항목 비교
        </div>
        <SpacingBlock vertical size={8} />
        <div className='move-frame'>
          <div
            className='move-text'
            role='button'
            onClick={() => history.push(
              RoutePaths.position_jd(
                matchReportModel.currentReportPosition?.sn,
              ),
            )}
          >
            공고 자세히보기
          </div>
          <Icon name='arrow-right' size={24} color={Colors.B_100} />
        </div>
        <SpacingBlock vertical size={24} />
        <MatchFrame>
          <div className='match-title'>직무</div>
          {fitPositionList.length > 1 ? (
            <JDAPopover
              useOutsideClick
              position='under'
              popoverWidth={150}
              anchorIcon={(
                <div className='match-detail-frame'>
                  <div className='match-detail'>
                    {
                        fitPositionList[0].name
                      }
                  </div>
                  <div className='match-plus'>
                    {` +`}
                    {fitPositionList.length - 1}
                  </div>
                  {fitPositionList[0].fitYn && (
                    <div className='match-badge'>
                      <div className='match-badge-text'>적합</div>
                    </div>
                  )}
                </div>
                )}
            >
              <PopOverFrame>
                {fitPositionList.map(
                  (item, index) => index >= 1 && <h1>{item.name}</h1>,
                )}
              </PopOverFrame>
            </JDAPopover>
          )
            : (
              <div className='match-detail-frame'>
                <div className='match-detail'>
                  {
            fitPositionList[0].name ? fitPositionList[0].name : ''
          }
                </div>
                {fitPositionList[0].fitYn && (
                  <div className='match-badge'>
                    <div className='match-badge-text'>적합</div>
                  </div>
                )}
              </div>
            )}
        </MatchFrame>
        <SpacingBlock vertical size={8} />
        <MatchFrame>
          <div className='match-title'>연봉</div>
          <div className='match-detail-frame'>
            {matchReportModel.matchReportPositionsDetail && (
              matchReportModel.matchReportPositionsDetail.profileFit.salaryFit === null
                ? <div className='match-detail'>추후 협의</div>
                : (
                  <div className='match-detail'>
                    {formatNumber(Math.floor(matchReportModel.matchReportPositionsDetail.profileFit.salaryFit.minSalary * 10000))} ~ {formatNumber(Math.floor(matchReportModel.matchReportPositionsDetail.profileFit.salaryFit.maxSalary * 10000))}원
                  </div>

                )
            )}
            {matchReportModel.matchReportPositionsDetail?.profileFit.salaryFit && (
              matchReportModel.matchReportPositionsDetail?.profileFit.salaryFit
                .fitYn && (
                <div className='match-badge'>
                  <div className='match-badge-text'>적합</div>
                </div>
              )
            )}
          </div>
        </MatchFrame>
        <SpacingBlock vertical size={8} />
        <MatchFrame>
          <div className='match-title'>경력</div>
          <div className='match-detail-frame'>

            <div className='match-detail'>
              {matchReportModel.matchReportPositionsDetail?.profileFit.careerFit
                .minYear === null
            && matchReportModel.matchReportPositionsDetail?.profileFit.careerFit
              .maxYear === null
                ? '경력무관'
                : (
                  matchReportModel.matchReportPositionsDetail?.profileFit.careerFit.minYear && matchReportModel.matchReportPositionsDetail?.profileFit.careerFit.maxYear
                    ? `${matchReportModel.matchReportPositionsDetail?.profileFit.careerFit.minYear} ~ ${matchReportModel.matchReportPositionsDetail?.profileFit.careerFit.maxYear}년`
                    : (
                      matchReportModel.matchReportPositionsDetail?.profileFit
                        .careerFit.minYear !== null
                        ? `${matchReportModel.matchReportPositionsDetail?.profileFit.careerFit.minYear}년차 이상`
                        : `${matchReportModel.matchReportPositionsDetail?.profileFit.careerFit.maxYear}년차 이하`
                    )
                )}
            </div>
            {matchReportModel.matchReportPositionsDetail?.profileFit.careerFit
              .fitYn && (
              <div className='match-badge'>
                <div className='match-badge-text'>적합</div>
              </div>
            )}
          </div>
        </MatchFrame>
      </CompareFrame>
      <SpacingBlock size={40} vertical />
    </>
  );
};
export default inject(
  injectStore.companyModel,
  injectStore.matchReportModel,
  injectStore.login,
)(observer(CompanyReportSuggestion));
