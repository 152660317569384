import V2Text from '__pc__/components/common/v2Design/text';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import JDALink from 'components/JDALink';
import RoutePaths from 'consts/RoutePaths';
import ImgBanner from 'assets/_v2/_common/matching_banner.svg';
import React from 'react';
import styled from 'styled-components';

const BannerFrame = styled.div`
  .banner {
    position: relative;
    display: flex;
    align-items: center;
    height: 100px;
    padding-left: ${SpaceValue.XL}px;
    border-radius: ${RadiusValue.M}px;
    background-color: ${V2BackgroundColors.surface_alternative};

    .banner-img {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      width: 120px;
      height: 100px;
    }
  }
`;

const MatchingBanner: React.FC = () => {
  return (
    <BannerFrame>
      <div className='matching-banner'>
        <JDALink to={RoutePaths.info_detail(474)}>
          <div className='banner'>
            <V2Text
              element={V2TextOption.element.div}
              fontStyle={V2TextOption.fontStyle.body_1_b}
              color={V2TextOption.color.default}
            >
              매칭 퀘스트 완료하고
              <br />
              <V2Text
                element={V2TextOption.element.span}
                fontStyle={V2TextOption.fontStyle.body_1_sb}
                color={V2TextOption.color.brand}
              >
                합격자 이력서{`\n`}
              </V2Text>
              받아가세요!
            </V2Text>
            <div className='banner-img'>
              <img src={ImgBanner} alt='banner' />
            </div>
          </div>
        </JDALink>
      </div>
    </BannerFrame>
  );
};

export default MatchingBanner;
