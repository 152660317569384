import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Badge from '__pc__/components/common/v2Design/badge';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2TextColors, V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2BadgeOption, V2BadgeSizeType, V2BadgeType } from '__pc__/constant/v2Design/V2BadgeType';
import { V2FontStyle, V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import ImgTutorialCardOne from 'assets/_v2/main/img_tutorial_one.png';
import ImgTutorialCardTwo from 'assets/_v2/main/img_tutorial_two.png';
import ImgTutorialCardThree from 'assets/_v2/main/img_tutorial_three.png';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption, V2ButtonSizeType, V2ButtonType } from '__pc__/constant/v2Design/V2ButtonType';
import Login from 'models/Login';
import { useHistory } from 'react-router';
import RoutePaths from 'consts/RoutePaths';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import JDALink from 'components/JDALink';
import { useMatchHomeMatchingUserCount } from 'query/match/useMatchQuery';
import { formatNumberWithCommas } from 'utils/NumberFormatUtil';
import MainTutorialModal from './tutorial/MainTutorialModal';

const Frame = styled.div`
    display: flex;
    flex-direction: column;

`;

const TitleFrame = styled.div`
    margin : 32px 16px 0 16px;
    display: flex;
    justify-content: space-between;
`;
const CardFrame = styled.div`
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    ::-webkit-scrollbar {
          display: none;
      }
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    .scroll-area {
      display: flex;
      gap: 16px;
      width: fit-content;
      margin: 0 16px;
      .info-action-card {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 283px;
        height: 225px;
        border-radius: 8px;
        border: 1px solid ${V2BorderColors.default};
        .img-area{
          width: 100%;
          height: 159px;
          object-fit: cover;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        .text-frame{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px;
            border-top: 1px solid ${V2BorderColors.default};
        }
        
      }
    }

`;
interface IMainMatchingTutorial{
    login?:Login
    triggerTutorialModal:boolean
}
const MainMatchingTutorial:FC<IMainMatchingTutorial> = ({ login = new Login(), triggerTutorialModal }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const history = useHistory();
  const { data: matchingUserData } = useMatchHomeMatchingUserCount();
  const handleClick = async () => {
    if (login.userInfo) {
      setIsModalOpen(true);
    } else {
      history.push(RoutePaths.login);
    }
  };

  useEffect(() => {
    if (triggerTutorialModal) {
      handleClick();
    }
  }, [triggerTutorialModal]);

  return (
    <Frame>
      <TitleFrame>
        <V2Text fontStyle={V2FontStyleType.title_3_b} color={V2TextColorsType.strong}>매칭 튜토리얼</V2Text>
        <V2Badge type={V2BadgeType.OutLine} size={V2BadgeOption.size.L} color={V2BadgeOption.color.subtlest} fontStyle={V2BadgeOption.fontStyle.caption_1_sb} borderColor={V2BadgeOption.borderColor.default}>
          참여 구직자: {formatNumberWithCommas(matchingUserData || 0)}명
        </V2Badge>
      </TitleFrame>
      <SpacingBlock vertical size={SpaceValue.L} />
      <CardFrame>
        <div className='scroll-area'>
          <div onClick={() => handleClick()} role='button' className='info-action-card'>
            <img src={ImgTutorialCardOne} alt='img' className='img-area' />
            <div className='text-frame'>
              <V2Text fontStyle={V2FontStyleType.body_1_b} color={V2TextColorsType.default}>튜토리얼</V2Text>
              <V2Button type={V2ButtonType.OutLine} size={V2ButtonSizeType.S} fill={V2ButtonOption.fillType.neutral_subtle_default} color={V2TextColorsType.subtle} fontStyle={V2ButtonOption.fontStyle.body_2_sb}>
                진행하기
              </V2Button>
            </div>
          </div>
          <JDALink to={RoutePaths.profile} className='info-action-card'>
            <img src={ImgTutorialCardTwo} alt='img' className='img-area' />
            <div className='text-frame'>
              <V2Text fontStyle={V2FontStyleType.body_1_b} color={V2TextColorsType.default}>내 프로필</V2Text>
              <V2Button type={V2ButtonType.OutLine} size={V2ButtonSizeType.S} fill={V2ButtonOption.fillType.neutral_subtle_default} color={V2TextColorsType.subtle} fontStyle={V2ButtonOption.fontStyle.body_2_sb}>
                진행하기
              </V2Button>
            </div>
          </JDALink>
          <JDALink to={RoutePaths.acca_test} className='info-action-card'>
            <img src={ImgTutorialCardThree} alt='img' className='img-area' />
            <div className='text-frame'>
              <V2Text fontStyle={V2FontStyleType.body_1_b} color={V2TextColorsType.default}>역량검사</V2Text>
              <V2Button type={V2ButtonType.OutLine} size={V2ButtonSizeType.S} fill={V2ButtonOption.fillType.neutral_subtle_default} color={V2TextColorsType.subtle} fontStyle={V2ButtonOption.fontStyle.body_2_sb}>
                진행하기
              </V2Button>
            </div>
          </JDALink>
        </div>
      </CardFrame>
      <SpacingBlock vertical size={SpaceValue.XXL} />
      <MainTutorialModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />

    </Frame>
  );
};
export default inject(injectStore.login)(observer(MainMatchingTutorial));
