import URIs from 'consts/URIs';
import { IMatchingStatusRs } from 'interfaces/_v2/matchApply/IMatchingStatusCountRs';
import { IMatchingStatusListRs } from 'interfaces/_v2/matchApply/IMatchingStatusListRs';
import { action, observable } from 'mobx';
import { request } from 'utils/request';

const SEARCH_INIT_PAGE = 0;
const SEARCH_INIT_SIZE = 10;

export default class MatchApplyStatusModel {
  @observable matching: IMatchingStatusListRs | null = null;
  @observable responseRate: number = 0;
  @observable matchStatus: IMatchingStatusRs | null = null;

  @action
  loadResponseRate = async () => {
    try {
      this.responseRate = (await request<{ responseRate: number }>({
        method: 'get',
        url: URIs.get_match_response_status,
      })).responseRate;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadMatchList = async (tabType?: string) => {
    try {
      this.matching = await request<IMatchingStatusListRs>({
        method: 'get',
        url: URIs.get_match_list,
        params: {
          page: SEARCH_INIT_PAGE,
          size: SEARCH_INIT_SIZE,
          tabType,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action
  lazeLoadMatchList = async (tabType?: string) => {
    if (this.matching === null) return;
    if (this.matching.pages.totalPages <= this.matching.pages.page + 1) return;
    try {
      const nextMatchingList = await request<IMatchingStatusListRs>({
        method: 'get',
        url: URIs.get_match_list,
        params: {
          page: this.matching.pages.page + 1,
          size: this.matching.pages.size,
          tabType,
        },
      });

      this.matching = {
        matchingList: [...this.matching.matchingList, ...nextMatchingList.matchingList],
        pages: nextMatchingList.pages,
      };
    } catch (e) {
      console.error(e);
    }
  }

  // 개별 읽기 처리
  @action
  readMatchCard = async (matchingSn: number) => {
    try {
      await request({
        method: 'post',
        url: URIs.post_match_read,
        params: matchingSn,
      });
    } catch (e) {
      console.error(e);
    }
  }

  // tab 전체
  @action
  readMatchCards = async (tabType: string) => {
    try {
      await request({
        method: 'post',
        url: URIs.post_match_read_all,
        params: { tabType },
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadMatchStatus = async () => {
    try {
      this.matchStatus = (await request<IMatchingStatusRs>({
        method: 'get',
        url: URIs.get_match_status,
      }));
    } catch (e) {
      console.error(e);
    }
  }

   // tab 전체
   @action
   likePosition = async (positionSn: number) => {
     try {
       await request({
         method: 'post',
         url: URIs.post_match_like(positionSn),
       });
     } catch (e) {
       console.error(e);
     }
   }

   @action
   unlikePosition = async (positionSn: number) => {
     try {
       await request({
         method: 'post',
         url: URIs.post_match_unlike(positionSn),
       });
     } catch (e) {
       console.error(e);
     }
   }
}
