import { useQuery } from 'react-query';
import { useState, useEffect } from 'react';
import { RemoteUserRepo } from 'repository/user/userRepo';
import AuthorizeUtil from 'utils/AuthorizeUtil';

export const keys = ['login'];

const userRepo = new RemoteUserRepo();

const fetchUser = async () => {
  const data = await userRepo.loadCurrentUser();
  return data;
};

export function useUserInfo() {
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    const fetchAccessToken = async () => {
      const token = await AuthorizeUtil.getAccessToken();
      setAccessToken(token ?? null);
    };

    fetchAccessToken();
  }, []);

  return useQuery(keys, fetchUser, {
    enabled: !!accessToken, // accessToken이 존재할 때만 실행
    staleTime: Infinity, // 데이터가 항상 fresh 상태로 유지
    cacheTime: Infinity, // 데이터를 무제한 캐싱
    onError: (error) => {
      console.error('Failed to fetch user information:', error);
    },
  });
}
