enum V2ColorPalette {
  // GRAY
  C_GRAY_WHITE = '#ffffff',
  C_GRAY_BLACK = '#000000',
  C_GRAY_25 = '#f7f7f7',
  C_GRAY_50 = '#f3f3f7',
  C_GRAY_100 = '#e9eaeb',
  C_GRAY_200 = '#dfe0e2',
  C_GRAY_300 = '#d1d2d4',
  C_GRAY_400 = '#acaeb0',
  C_GRAY_500 = '#838486',
  C_GRAY_600 = '#464748',
  C_GRAY_700 = '#333436',
  C_GRAY_800 = '#18191b',

  // GREEN
  C_GREEN_25 = '#e5fff0',
  C_GREEN_50 = '#d7f9e3',
  C_GREEN_100 = '#c2f4d3',
  C_GREEN_200 = '#79e7a2',
  C_GREEN_300 = '#3ee089',
  C_GREEN_400 = '#02d46a',
  C_GREEN_500 = '#02c551',
  C_GREEN_600 = '#00b840',
  C_GREEN_700 = '#02a73c',
  C_GREEN_800 = '#008c2c',

  // SPRING GREEN
  C_SPRINGGREEN_25 = '#effbed',
  C_SPRINGGREEN_50 = '#e1f7dc',
  C_SPRINGGREEN_100 = '#c6efbd',
  C_SPRINGGREEN_200 = '#a2e594',
  C_SPRINGGREEN_300 = '#85dd74',
  C_SPRINGGREEN_400 = '#67d551',
  C_SPRINGGREEN_500 = '#5dcd47',
  C_SPRINGGREEN_600 = '#52b13f',
  C_SPRINGGREEN_700 = '#50a140',
  C_SPRINGGREEN_800 = '#428f33',

  // OLIVE
  C_OLIVEGREEN_25 = '#f7fbeb',
  C_OLIVEGREEN_50 = '#eff6d7',
  C_OLIVEGREEN_100 = '#e2eeb9',
  C_OLIVEGREEN_200 = '#cae17f',
  C_OLIVEGREEN_300 = '#bdd95f',
  C_OLIVEGREEN_400 = '#acd037',
  C_OLIVEGREEN_500 = '#a3c62f',
  C_OLIVEGREEN_600 = '#8ead2a',
  C_OLIVEGREEN_700 = '#7e9a23',
  C_OLIVEGREEN_800 = '#708820',

  // YELLOW
  C_YELLOW_25 = '#fffadb',
  C_YELLOW_50 = '#fff6bd',
  C_YELLOW_100 = '#fff18f',
  C_YELLOW_200 = '#ffe566',
  C_YELLOW_300 = '#ffdb4d',
  C_YELLOW_400 = '#ffcf0f',
  C_YELLOW_500 = '#f5c000',
  C_YELLOW_600 = '#e0a500',
  C_YELLOW_700 = '#c79200',
  C_YELLOW_800 = '#b28000',

  // GOLD
  C_GOLD_25 = '#fbf7eb',
  C_GOLD_50 = '#f8eed6',
  C_GOLD_100 = '#f2e0b5',
  C_GOLD_200 = '#eacc80',
  C_GOLD_300 = '#e4bc5a',
  C_GOLD_400 = '#ddab31',
  C_GOLD_500 = '#d0a02a',
  C_GOLD_600 = '#bc9024',
  C_GOLD_700 = '#ad821b',
  C_GOLD_800 = '#9c7517',

  // ORANGE
  C_ORANGE_25 = '#fff5f0',
  C_ORANGE_50 = '#fee5d9',
  C_ORANGE_100 = '#fed6c3',
  C_ORANGE_200 = '#fdb28c',
  C_ORANGE_300 = '#fd9966',
  C_ORANGE_400 = '#ff7628',
  C_ORANGE_500 = '#f46b1d',
  C_ORANGE_600 = '#e95907',
  C_ORANGE_700 = '#c74800',
  C_ORANGE_800 = '#ad3f00',

  // RED
  C_RED_25 = '#fff5f5',
  C_RED_50 = '#ffe7e5',
  C_RED_100 = '#ffd8d6',
  C_RED_200 = '#ff9e99',
  C_RED_300 = '#fd8882',
  C_RED_400 = '#fc675f',
  C_RED_500 = '#fd493f',
  C_RED_600 = '#f1392c',
  C_RED_700 = '#e42a20',
  C_RED_800 = '#cb2620',

  // PINK
  C_PINK_25 = '#fff0f3',
  C_PINK_50 = '#fedbe4',
  C_PINK_100 = '#fec8d6',
  C_PINK_200 = '#fd93af',
  C_PINK_300 = '#fd6f95',
  C_PINK_400 = '#fc4b7a',
  C_PINK_500 = '#ef2e62',
  C_PINK_600 = '#e42558',
  C_PINK_700 = '#c61e4b',
  C_PINK_800 = '#b0274b',

  // PURPLE
  C_PURPLE_25 = '#f9f0f8',
  C_PURPLE_50 = '#f1daed',
  C_PURPLE_100 = '#ebcbe6',
  C_PURPLE_200 = '#d590c9',
  C_PURPLE_300 = '#c76bb7',
  C_PURPLE_400 = '#b946a5',
  C_PURPLE_500 = '#ad3e99',
  C_PURPLE_600 = '#a1368e',
  C_PURPLE_700 = '#942b82',
  C_PURPLE_800 = '#832372',

  // VIOLET
  C_VIOLET_25 = '#f2f1fe',
  C_VIOLET_50 = '#dfdcfd',
  C_VIOLET_100 = '#d0cbfb',
  C_VIOLET_200 = '#a7a0ef',
  C_VIOLET_300 = '#8b82e9',
  C_VIOLET_400 = '#6f64dd',
  C_VIOLET_500 = '#6358d5',
  C_VIOLET_600 = '#574ccd',
  C_VIOLET_700 = '#4a40b5',
  C_VIOLET_800 = '#3f369a',

  // COBALT BLUE
  C_COBALTBLUE_25 = '#eef5fb',
  C_COBALTBLUE_50 = '#e0eeff',
  C_COBALTBLUE_100 = '#cce2ff',
  C_COBALTBLUE_200 = '#93bbf6',
  C_COBALTBLUE_300 = '#3982ef',
  C_COBALTBLUE_400 = '#376cf2',
  C_COBALTBLUE_500 = '#1e5af0',
  C_COBALTBLUE_600 = '#174fde',
  C_COBALTBLUE_700 = '#003ec2',
  C_COBALTBLUE_800 = '#0031ad',

  // SKY BLUE
  C_SKYBLUE_25 = '#f0f9ff',
  C_SKYBLUE_50 = '#e1f4ff',
  C_SKYBLUE_100 = '#ccecff',
  C_SKYBLUE_200 = '#83d1ff',
  C_SKYBLUE_300 = '#52c0ff',
  C_SKYBLUE_400 = '#33b2fe',
  C_SKYBLUE_500 = '#2ca4fb',
  C_SKYBLUE_600 = '#2497f3',
  C_SKYBLUE_700 = '#1084f2',
  C_SKYBLUE_800 = '#0b6cd5',

  // CYAN
  C_CYAN_25 = '#e9fafb',
  C_CYAN_50 = '#ccf3f7',
  C_CYAN_100 = '#b5eef3',
  C_CYAN_200 = '#67dbe6',
  C_CYAN_300 = '#34cfdd',
  C_CYAN_400 = '#01c3d5',
  C_CYAN_500 = '#00b6c7',
  C_CYAN_600 = '#009fad',
  C_CYAN_700 = '#00909d',
  C_CYAN_800 = '#007984',

  // TEAL
  C_TEAL_25 = '#e6fcff',
  C_TEAL_50 = '#d5fbee',
  C_TEAL_100 = '#bcf4e4',
  C_TEAL_200 = '#63e6be',
  C_TEAL_300 = '#38d9a9',
  C_TEAL_400 = '#20c997',
  C_TEAL_500 = '#12b886',
  C_TEAL_600 = '#0ca678',
  C_TEAL_700 = '#099268',
  C_TEAL_800 = '#087f5b',
}

export default V2ColorPalette;
