import colors from '__designkit__/common/colors';
import V2Button from '__pc__/components/common/v2Design/button';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2TextColors } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { V2FontStyleDirect } from '__pc__/constant/v2Design/V2FontStyle';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import JDRadioInput, { JD_RADIO_INPUT_TYPE } from 'components/_v2/_common/input/JDRadioInput';
import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { IMatchingHomeMatchingSettingUpdateRq } from 'interfaces/_v2/matchHome/IMatchHomeRqRs';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import { injectStore } from 'models/store';
import { useMatchHomeDashboard, useMatchHomeDashboardMatchingSetting } from 'query/match/useMatchQuery';
import React, { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

const Frame = styled.div`
`;

const V2ModalFrame = styled.div`
  display: flex;
  flex-direction: column;
  width: 327px;
  background: ${colors.WHITE_100};
  border-radius: ${RadiusValue.M}px;
  padding: ${SpaceValue.XL}px;

  .title-area {
    display: flex;
    flex-direction: column;
    gap: ${SpaceValue.XS}px;
    margin-bottom: ${SpaceValue.XL}px;
  }

  .btn-area {
    display: flex;
    flex-direction: column;
    gap: ${SpaceValue.XS}px;
    margin-top: ${SpaceValue.M}px;
  }

  .check-frame{
    display: flex;
    gap:${SpaceValue.L}px;
    padding: 0 32px;
    margin-bottom: 24px;
  }
`;

const OptionFormFrame = styled.div`
  display: flex;
  flex-direction: column;

  > :last-child {
    label {
      border-bottom: none;
      padding-bottom: 0px;
    }
  }
  
  label {
    align-items: flex-start;
    width: 100%;
    margin-bottom: ${SpaceValue.L}px;
    padding-bottom: ${SpaceValue.L}px;
    border-bottom: 1px solid ${V2BorderColors.default};
  }

  .label {
    padding-left: ${SpaceValue.M}px;

    .option {
      font: ${V2FontStyleDirect.body_2_m};
      color: ${V2TextColors.subtle};
    }
    .title {
      font: ${V2FontStyleDirect.body_2_b};
      color: ${V2TextColors.default};
      margin-bottom: 4px;
    }
  }
`;

interface IMatchJobSearchStatusModalProps extends IJDBaseModal {
  login?: Login;
  matchApplyModel?: MatchApplyModel;
}

const MatchJobSearchStatusModal: FC<IMatchJobSearchStatusModalProps> = (({ isOpen, onClickClose, login = new Login(), matchApplyModel = new MatchApplyModel() }) => {
  const useformed = useForm({
    mode: 'all',
    defaultValues: { job_status: login.matchConfigStatus?.jobSearchStatus || 'ACTIVE_SEARCH', subReason: 3 },
  });
  const { data: dashboardData, refetch } = useMatchHomeDashboard();
  const { watch, reset, setValue } = useformed;
  const saveSuccess = async () => {
    await refetch();

    await login.getMatchConfigStatus();
    onClickClose();
  };
  const { mutate: saveJobSearchStatus } = useMatchHomeDashboardMatchingSetting(saveSuccess);
  const onSubmit = useCallback(async () => {
    const rq:IMatchingHomeMatchingSettingUpdateRq = {
      jobSearchStatus: watch('job_status'),
      rejectPeriodMonth: watch('job_status') === 'NOT_LOOKING_FOR_JOB' ? watch('subReason') : null,
    };
    saveJobSearchStatus(rq);
  }, [watch]);

  useEffect(() => {
    if (login.matchConfigStatus?.jobSearchStatus) {
      reset({ job_status: login.matchConfigStatus?.jobSearchStatus, subReason: undefined, // 기본값 포함
      });
    }
  }, [login.matchConfigStatus?.jobSearchStatus]);

  return (
    <JDBaseModal isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
      <FormProvider {...useformed}>
        <Frame>
          <V2ModalFrame>
            <div className='title-area'>
              <V2Text
                element={V2TextOption.element.div}
                fontStyle={V2TextOption.fontStyle.title_2_b}
                color={V2TextOption.color.default}
              >
                구직 상태
              </V2Text>
              <V2Text
                element={V2TextOption.element.div}
                fontStyle={V2TextOption.fontStyle.body_1_m}
                color={V2TextOption.color.subtle}
              >
                받고 싶은 제안의 종류에 따라 <br />구직 상태를 설정해 주세요.
              </V2Text>
            </div>
            <OptionFormFrame>
              <JDRadioInput
                key='radio-ACTIVE_SEARCH'
                type={JD_RADIO_INPUT_TYPE.ROUND_CIRCLE_BOX}
                name='job_status'
                value='ACTIVE_SEARCH'
                label={(
                  <div className='option'>
                    <p className='title'>적극적으로 구직 중</p>
                    적극적으로 취업/이직을 준비 중이에요.
                  </div>
                  )}
                defaultValue={login.matchConfigStatus?.jobSearchStatus || ''}
              />
              <JDRadioInput
                key='radio-OPEN_TO_OFFERS'
                type={JD_RADIO_INPUT_TYPE.ROUND_CIRCLE_BOX}
                name='job_status'
                value='OPEN_TO_OFFERS'
                label={(
                  <div className='option'>
                    <p className='title'>매칭 제안 희망</p>
                    매칭 제안이 있으면 받고 싶어요.
                  </div>
                )}
                defaultValue={login.matchConfigStatus?.jobSearchStatus || ''}
              />
              <JDRadioInput
                key='radio-NOT_LOOKING_FOR_JOB'
                type={JD_RADIO_INPUT_TYPE.ROUND_CIRCLE_BOX}
                name='job_status'
                value='NOT_LOOKING_FOR_JOB'
                label={(
                  <div className='option'>
                    <p className='title'>관심 없음</p>
                    면접 제안을 안 받고 싶어요.
                  </div>
                )}
                defaultValue={login.matchConfigStatus?.jobSearchStatus || ''}
              />
            </OptionFormFrame>
            {
                  watch('job_status') === 'NOT_LOOKING_FOR_JOB' && (
                    <div className='check-frame'>

                      <JDRadioInput
                        key='radio-NOT_LOOKING_FOR_JOB0'
                        type={JD_RADIO_INPUT_TYPE.ROUND_CIRCLE_BOX}
                        name='subReason'
                        value={3}
                        label={(
                          <div className='option'>
                            <p className='title'>3개월</p>

                          </div>
                      )}
                        defaultValue={3}
                      />
                      <JDRadioInput
                        key='radio-NOT_LOOKING_FOR_JOB1'
                        type={JD_RADIO_INPUT_TYPE.ROUND_CIRCLE_BOX}
                        name='subReason'
                        value={6}
                        label={(
                          <div className='option'>
                            <p className='title'>6개월</p>

                          </div>
                      )}
                      />
                      <JDRadioInput
                        key='radio-NOT_LOOKING_FOR_JOB2'
                        type={JD_RADIO_INPUT_TYPE.ROUND_CIRCLE_BOX}
                        name='subReason'
                        value={12}
                        label={(
                          <div className='option'>
                            <p className='title'>1년</p>

                          </div>
                      )}
                      />
                    </div>
                  )
                }
            {/* 버튼 영역 */}
            <div className='btn-area'>
              {
                watch('job_status')
                  ? (
                    <V2Button
                      size={V2ButtonOption.size.L}
                      type={V2ButtonOption.type.Fill}
                      fontStyle={V2TextOption.fontStyle.body_1_sb}
                      color={V2TextOption.color.inverse}
                      fill={V2ButtonOption.fillType.brand_strong_default}
                      buttonProps={{ onClick: onSubmit }}
                    >
                      확인
                    </V2Button>
                  )
                  : (
                    <V2Button
                      size={V2ButtonOption.size.L}
                      type={V2ButtonOption.type.Fill}
                      fontStyle={V2TextOption.fontStyle.body_1_sb}
                      color={V2TextOption.color.inverse}
                      fill={V2ButtonOption.fillType.brand_strong_default}
                      buttonProps={{ disabled: true }}
                    >
                      확인
                    </V2Button>
                  )
              }

              <V2Button
                size={V2ButtonOption.size.L}
                type={V2ButtonOption.type.OutLine}
                fontStyle={V2TextOption.fontStyle.body_1_sb}
                color={V2TextOption.color.subtle}
                fill={V2ButtonOption.fillType.neutral_subtle_default}
                buttonProps={{ onClick: onClickClose }}
              >
                취소
              </V2Button>
            </div>
          </V2ModalFrame>
        </Frame>
      </FormProvider>
    </JDBaseModal>
  );
});

export default inject(injectStore.login, injectStore.matchApplyModel)(observer(MatchJobSearchStatusModal));
