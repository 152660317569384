// 채팅 목록을 끝까지 가져오기 위해서는 do-while문 안에서 await을 사용해야 하기 떄문에 아래 예외처리를 해준다.
/* eslint-disable no-await-in-loop */

import URIs from 'consts/URIs';
import { GetAllChannelNotificationWhetherRs, GetChannelIdByCompanySnRs, GetChattingTokenRs, GetCompanyChannelSnRs, GetNotificationWhetherRs, PostReportRq } from 'interfaces/_v2/chatting/IChattingRqRs';
import { Channel, Client, GetChannelListResponse } from 'talkplus-sdk';
import { request } from 'utils/request';

const ChattingAPI = {
  // talkplus 토큰 조회
  getToken: async (): Promise<string> => {
    try {
      const res = await request<GetChattingTokenRs>({
        method: 'GET',
        url: URIs.get_chat_token,
      });
      return res.token;
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
  // 내 채널 목록 조회 (talkplus API)
  getMyChannel: async (client: Client): Promise<Channel[]> => {
    try {
      const channelList = [];
      let hasNext;
      do {
        const res: GetChannelListResponse = await client.getChannels({
          limit: 100,
          lastChannelId: hasNext,
        });
        channelList.push(...(res.channels || []));
        if (res.hasNext && res.channels) hasNext = res.channels[res.channels.length - 1].id;
        else hasNext = undefined;
      } while (hasNext);
      return channelList;
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
  // 전체 채널 목록 조회 (talkplus API)
  getAllChannel: async (client: Client): Promise<Channel[]> => {
    try {
      const channelList = [];
      let hasNext;
      do {
        const res: GetChannelListResponse = await client.getPublicChannels({
          limit: 100,
          lastChannelId: hasNext,
        });
        channelList.push(...(res.channels || []));
        if (res.hasNext && res.channels) hasNext = res.channels[res.channels.length - 1].id;
        else hasNext = undefined;
      } while (hasNext);
      return channelList;
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
  // 내 채널 중 알림이 커져있는 채널 목록 조회
  getAllChannelNotificationWhether: async () => {
    try {
      const res = await request<GetAllChannelNotificationWhetherRs>({
        method: 'GET',
        url: URIs.get_chat_notifications(),
      });
      return res.notifications;
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
  // 채널 알림 여부 조회
  getChannelNotificationWhether: async (channelId: string) => {
    try {
      const res = await request<GetNotificationWhetherRs>({
        method: 'GET',
        url: URIs.get_chat_notifications(channelId),
      });
      return res.enabled;
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
  // 채널 알림 여부 수정
  updateChannelNotificationWhether: async (channelId: string, enabled: boolean) => {
    try {
      await request<void>({
        method: 'PUT',
        url: URIs.put_chat_notifications(channelId),
        data: { enabled },
      });
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
  // channelId를 통해 해당 채팅방의 companySn 조회
  getCompanySnByChannelId: async (channelId: string) => {
    try {
      const res = await request<GetCompanyChannelSnRs>({
        method: 'GET',
        url: URIs.get_chat_company_channel_channel_id(channelId),
      });
      return res;
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
  // companySn을 통해 해당 기업의 channelId 조회
  getChannelIdByCompanySn: async (companySn: number) => {
    try {
      const res = await request<GetChannelIdByCompanySnRs>({
        method: 'GET',
        url: URIs.get_chat_company_company_sn(companySn),
      });
      return res;
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
  // 채널 입장 로그용 API
  postChannelEnterLog: async (channelId: string) => {
    try {
      await request<void>({
        method: 'POST',
        url: URIs.post_chat_channel_enter_log(channelId),
      });
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
  // 채팅 신고
  postReport: async (channelId: string, messageId: string, data: PostReportRq) => {
    try {
      await request<void>({
        method: 'POST',
        url: URIs.post_chat_report(channelId, messageId),
        data,
      });
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
};

export default ChattingAPI;
