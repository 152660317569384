export const JobSearchStatus = {
  ACTIVE_SEARCH: 'ACTIVE_SEARCH', // 적극적으로 구직 중
  OPEN_TO_OFFERS: 'OPEN_TO_OFFERS', // 좋은 제안이 있으면 받고 싶어요.
  NOT_LOOKING_FOR_JOB: 'NOT_LOOKING_FOR_JOB', // 취업/이직을 준비 중이 아닌 상태에요.
  NOT_SELECTED: 'NOT_SELECTED', // 선택되지 않은 상태에요.
} as const;

export type JobSearchStatusType = keyof typeof JobSearchStatus;

export enum JobSearchStatusTypeText {
    ACTIVE_SEARCH = '적극적으로 구직 중',
    OPEN_TO_OFFERS = '매칭제안 희망',
    NOT_LOOKING_FOR_JOB = '선택 필요',
    NOT_SELECTED = '선택 필요',
}
