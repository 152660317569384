import URIs from 'consts/URIs';
import { IFindIdRq, IFindIdRs, IFindPasswordIPINRq, IFindPasswordRq } from 'interfaces/rqrs/IFindAccountRqRs';
import { IVerificationSmsCheckRq, IVerificationSmsIdSendRq, IVerificationSmsPasswordSendRq } from 'interfaces/rqrs/IJoinRqRs';
import { IKcbVerifyRs } from 'interfaces/_v2/verification/IVerificationRqRs';
import { action, observable } from 'mobx';
import { request } from 'utils/request';

export const FIND_ACCOUNT_ERROR = {
  WRONG_TOKEN: 'WRONG_TOKEN',
  B002: 'B002',
  SERVER_ERROR: 'SERVER_ERROR',
} as const;

type FIND_ACCOUNT_ERROR_TYPE = typeof FIND_ACCOUNT_ERROR[keyof typeof FIND_ACCOUNT_ERROR];

export const FIND_ACCOUNT_ERROR_STR: Record<FIND_ACCOUNT_ERROR_TYPE, string> = {
  WRONG_TOKEN: '잘못된 인증번호 입니다.',
  B002: '가입하신 정보와 일치하지 않습니다.',
  SERVER_ERROR: '잠시 후 다시 시도해 주세요.',
} as const;

export const FIND_ID_STEP = {
  FORM: 1,
  RESULT: 2,
} as const;

export const FIND_PASSWORD_STEP = {
  GATE: 0,
  FORM: 1,
  CHANGE: 2,
} as const;

export default class FindAccountModel {
  @observable currentFindIdStep: number = 0;
  @observable currentFindPasswordStep: number = 0;
  @observable idInfo: IFindIdRs | null = null;
  @observable verificationInfo: IKcbVerifyRs | null = null;

  @action
  saveVerificationInfo = (info: IKcbVerifyRs) => {
    this.verificationInfo = info;
  };

  @action
  sendSMS = async (smsSendRq: IVerificationSmsIdSendRq) => {
    try {
      const res = await request<{ success: boolean }>({
        method: 'post',
        url: URIs.post_verification_sms_send_id,
        data: smsSendRq,
      });
      return res.success;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  sendPasswordSMS = async (smsSendRq: IVerificationSmsPasswordSendRq) => {
    try {
      const res = await request<{ success: boolean }>({
        method: 'post',
        url: URIs.post_verification_sms_send_password,
        data: smsSendRq,
      });
      return res.success;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  checkSMS = async (smsCheckRq: IVerificationSmsCheckRq) => {
    try {
      const res = await request<{ success: boolean }>({
        method: 'post',
        url: URIs.post_verification_sms_check,
        data: smsCheckRq,
      });
      return res.success;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  findIdInfo = async (findIdRq: IFindIdRq) => {
    try {
      this.idInfo = await request({
        method: 'post',
        url: URIs.post_v2_users_id,
        data: findIdRq,
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  findIdInfoIPIN = async (token: string) => {
    try {
      this.idInfo = await request({
        method: 'post',
        url: URIs.post_identity_id,
        data: { token },
      });
    } catch (e) {
      const err = e as { response: any };
      if (err.response.data.errorCode === FIND_ACCOUNT_ERROR.B002) throw FIND_ACCOUNT_ERROR.B002;
      throw err;
    }
  }

  @action
  checkIdExist = async (id: string) => {
    const res = await request<{ isExist: boolean }>({
      method: 'get',
      url: URIs.get_users_id_exist,
      params: { id },
    });
    if (!res.isExist) throw FIND_ACCOUNT_ERROR.B002;
  }

  @action
  checkPasswordVerification = async (findPasswordRq: IFindPasswordRq) => {
    try {
      const res = await request<{ success: boolean }>({
        method: 'post',
        url: URIs.post_v2_users_password_verification,
        data: findPasswordRq,
      });
      return res.success;
    } catch (e) {
      const err = e as { response: any };
      if (err.response.data.errorCode === FIND_ACCOUNT_ERROR.B002) throw FIND_ACCOUNT_ERROR.B002;
      else throw FIND_ACCOUNT_ERROR.SERVER_ERROR;
    }
  }

  @action
  resetPassword = async (findPasswordRq: IFindPasswordRq) => {
    try {
      const res = await request<{ success: boolean }>({
        method: 'post',
        url: URIs.post_v2_users_password_reset,
        data: findPasswordRq,
      });
      return res.success;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  resetPasswordIPIN = async (findPasswordRq: IFindPasswordIPINRq) => {
    try {
      await request({
        method: 'post',
        url: URIs.post_users_password_reset_ipin,
        data: findPasswordRq,
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  resetData = () => {
    this.currentFindIdStep = 0;
    this.currentFindPasswordStep = 0;
    // data
    this.idInfo = null;
  }

  @action
  nextFindIdStep = (step: number) => {
    this.currentFindIdStep = step;
  };

  @action
  nextFindPasswordStep = (step: number) => {
    this.currentFindPasswordStep = step;
  };
}
