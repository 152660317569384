import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import imgArrow from 'assets/_v2/_common/icon_arrow_right.svg';
import IconClose from 'assets/icon/notification/icon_circle_close.svg';
import { MatchApplyStatusTabType } from 'components/_v2/matchApply/matchApplyStatus/MatchApplyStatusBoard';
import LazyImage from 'components/common/LazyImage';
import RoutePaths from 'consts/RoutePaths';
import { MatchTabType } from 'consts/_v2/match/MatchType';
import { MessageAccessPath } from 'interfaces/_v2/log/ILogRqRs';
import { INotification, NotificationType } from 'interfaces/_v2/notification/INotificationRqRs';
import { inject } from 'mobx-react';
import Login from 'models/Login';
import MatchMessageStore from 'models/MatchMessageStore';
import NotificationModel from 'models/_v2/NotificationModel';
import { injectStore } from 'models/store';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { getDCount, getDHour, getDMinutes } from 'utils/DateUtils';

const StyledNotificationCard = styled.div`
  display:flex;
  padding:20px;
  justify-content: space-between;

  .icon-frame{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
  .content-frame{
    flex:2;
    display: flex;
    flex-direction: column;
    >div{
      display: flex;
      margin-bottom:4px;
    }
    h1{
      font: ${Fonts.B2_Medium};
      white-space: pre-line;
      color:${colors.CG_80};
      margin-bottom:16px;
      strong{
        font: ${Fonts.B2_Bold};
      }
    }
    h3{
      height:20px;
      display:flex;
      align-items:flex-end;
      font: ${Fonts.B3_Medium};
      line-height: 14px;
      color:${colors.CG_60};

    }
    .move-button{
      font: ${Fonts.B3_Medium};
      line-height: 14px;
      color:${colors.CG_70};
      flex-direction: row;
      .icon-arrow{
        margin-left: 5px;
      }
    }
    .new{
      margin-left:4px;
      margin-top:5px;
      width:4px;
      height:4px;
      border-radius: 50%;
      background: ${colors.ERROR};
    }
  }

  .close-frame{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    button{
      background: transparent;
    }
  }
`;

interface INotificationCard {
  item: INotification;
  notificationModel?: NotificationModel;
  matchMessageStore?: MatchMessageStore;
  login?:Login;
}

const NotificationCard: FC<INotificationCard> = ({ item, notificationModel = new NotificationModel(), matchMessageStore = new MatchMessageStore(), login = new Login() }) => {
  const history = useHistory();

  const getMsgType = (userNotificationType: NotificationType) => {
    switch (userNotificationType) {
      case (NotificationType.INTERVIEW_OFFER):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName}에서`} <strong> 면접 제안</strong>이 왔어요! 어떤 포지션에서 제안이 왔는지 확인해 보세요.</h1>;
      case (NotificationType.DETAILED_BROWSE):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName}에서 내 프로필을 유심히 보고 있어요.`}</h1>;
      case (NotificationType.STATUS_CHANGE):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName}에서`} <strong>최종 결과</strong>가 발표되었어요. 지금 자세한 내용을 확인해보세요.</h1>;
      case (NotificationType.JD_CHANGE):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName}의 내용이 일부 변경되었어요! `}</h1>;
      case (NotificationType.ACC_ANALYSIS_COMPLETE):
        return <h1><strong>역량검사 분석이 완료</strong>{`되었어요! \n 지금 바로 결과를 확인해보세요.`}</h1>;
      case (NotificationType.FILE_REQUEST):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName}에서 `} <strong>추가 서류를 요청</strong>했어요. 지금 자세한 내용을 확인해보세요.</h1>;
      case (NotificationType.FILE_REQUEST_DEADLINE_REMINDER_D_DAY):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName}`} <strong>추가 서류 제출 마감</strong>일 입니다. 서둘로 제출해 주세요!</h1>;
      case (NotificationType.SIMPLE_MESSAGE):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName}에서`} <strong> {` 메세지를 보냈어요. `}</strong>지금 자세한 내용을 확인해보세요.</h1>;
      case (NotificationType.PACC_ANALYSIS_COMPLETE):
        return <h1><strong>개발자 역량검사 분석이 완료</strong>{`되었어요! \n 지금 바로 결과를 확인해보세요.`}</h1>;
      case (NotificationType.PST_ANALYSIS_COMPLETE):
        return <h1><strong>개발 구현 능력 검사 분석이 완료</strong>{`되었어요! \n 지금 바로 결과를 확인해보세요.`}</h1>;
      case (NotificationType.ACC_EXPIRED):
        return <h1>역량검사의 <strong>{` 유효기간이 ${item.data.expirationDays}일 `}</strong>{`남았어요. \n유효기간이 지나면 새롭게 응시를 해 주세요.`}</h1>;
      case (NotificationType.PACC_EXPIRED):
        return <h1>개발자 역량검사의 <strong>{` 유효기간이 ${item.data.expirationDays}일 `}</strong>{`남았어요. \n유효기간이 지나면 새롭게 응시를 해 주세요.`}</h1>;
      case (NotificationType.PST_EXPIRED):
        return <h1>개발 구현 능력 검사의 <strong>{` 유효기간이 ${item.data.expirationDays}일 `}</strong>{`남았어요. \n유효기간이 지나면 새롭게 응시를 해 주세요.`}</h1>;
      case (NotificationType.GENERAL_CREATED):
      case (NotificationType.DEVELOPER_CREATED):
        return <h1>{`잡다매칭 참여 준비가 완료되었어요.\n딱 맞는 공고를 카카오톡 알림톡으로 보내드릴게요!`}</h1>;
      case (NotificationType.GENERAL_DELETED):
      case (NotificationType.DEVELOPER_DELETED):
        return <h1>{`잡다매칭 신청을 취소했습니다. \n더 좋은 제안을 드리기위해 노력하고 있을게요.`}</h1>;
      case (NotificationType.MATCHING_TALK_LINK):
        return <h1><strong>{`${item.data.companyName}의 ${item.data.positionName} `}</strong>{`포지션 메세지가 연동되었어요. \n 지금 바로 확인해보세요. `}</h1>;
      case (NotificationType.ACC_REQUEST):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName}에서`} <strong>역량검사 검사 결과 제출을 요청</strong>했어요. 지금 자세한 내용을 확인해보세요.</h1>;
      case (NotificationType.PHS_REQUEST):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName}에서`} <strong>개발자 검사 결과 제출을 요청</strong>했어요. 지금 자세한 내용을 확인해보세요.</h1>;
      case (NotificationType.PROFILE_UPDATE_BY_RESUME):
        return <h1><strong>내 프로필</strong> 정보가 <strong>업데이트</strong> 되었어요!<br />상세 내용은 메일을 통해 확인해 주세요.</h1>;
      case (NotificationType.INSIGHT_STATUS_CHANGE):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName}에서`} <strong>지원상태가 변경</strong>되었어요. 지금 자세한 내용을 확인해보세요.</h1>;
      case (NotificationType.JOB_APPLICATION_SUBMITTED):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 지원이 \n완료되었어요. 지원자님의 행운을 빌어요!`}</h1>;
      case (NotificationType.JOB_APPLICATION_CANCELED):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 지원이 \n취소되었어요. 다른 포지션을 찾아볼까요?`}</h1>;
      case (NotificationType.INTERVIEW_OFFER_RESPONDED):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 면접이 확정되었어요. \n 잡다에서 10,000개의 면접 질문을 연습해보세요.`}</h1>;
      case (NotificationType.INTERVIEW_OFFER_DEADLINE_REMINDER_D_3):
        return <h1>{(item.data.companyName && item.data.positionName) ? `${item.data.companyName}의 ${item.data.positionName} 면접제안 응답 마감까지 3일 남았습니다.\n서둘러 답변해 주세요!` : `${login.userInfo?.name}님, 면접제안 응답 마감까지 3일 남았습니다.\n서둘러 답변해 주세요!` }</h1>;
      case (NotificationType.INTERVIEW_OFFER_DEADLINE_REMINDER_D_DAY):
        return <h1>{(item.data.companyName && item.data.positionName) ? `${item.data.companyName}의 ${item.data.positionName} 면접제안 응답 마감일입니다.\n서둘러 답변해 주세요!` : `${login.userInfo?.name}님, 면접제안 응답 마감일입니다.\n서둘러 답변해 주세요!`}</h1>;
      case (NotificationType.JOB_APPLICATION_DEADLINE_REMINDER_D_DAY):
        return <h1>{(item.data.companyName && item.data.positionName) ? `${item.data.companyName}의 ${item.data.positionName} 입사 지원 마감일입니다.\n서둘러 지원해 주세요!` : `${login.userInfo?.name}님, 입사 지원 마감일입니다.\n서둘러 지원해 주세요!`}</h1>;
      case (NotificationType.JOB_APPLICATION_DEADLINE_REMINDER_D_3):
        return <h1>{(item.data.companyName && item.data.positionName) ? `${item.data.companyName}의 ${item.data.positionName} 입사 지원 마감까지 3일 남았습니다.\n서둘러 지원해 주세요!` : `${login.userInfo?.name}님, 입사 지원 마감까지 3일 남았습니다.\n서둘러 지원해 주세요!`}</h1>;
      case (NotificationType.DELETE_JOB_APPLICATION):
        return <h1>{`${item.data.companyName} 요청으로 ${item.data.positionName} 포지션의 지원이 취소되었습니다.`}</h1>;
      case (NotificationType.COPY_JOB_APPLICATION):
        return <h1>{`${item.data.companyName} 요청으로 ${item.data.positionName} 포지션에서 심사 중입니다. 지원현황을 확인해 보세요.`}</h1>;
      case (NotificationType.PROFILE_INPUT_REMINDER):
        return <h1>{`프로필을 작성하면 맞춤형 기업 추천, 빠른\n입사지원 혜택을 누릴 수 있어요.`}</h1>;
      case (NotificationType.ACC_COMPLETE):
        return <h1>{`역량검사 응시가 완료되었어요.\n결과 분석은 최대 1일 가량 소요될 수 있어요.`}</h1>;
      case (NotificationType.PACC_COMPLETE):
        return <h1>{`개발자 역량검사 응시가 완료되었어요.\n결과 분석은 최대 1일 가량 소요될 수 있어요.`}</h1>;
      case (NotificationType.PST_COMPLETE):
        return <h1>{`개발 구현 능력 검사 응시가 완료되었어요.\n결과 분석은 최대 1일 가량 소요될 수 있어요.`}</h1>;
      case (NotificationType.ACC_RESULT_SUBMITTED):
        return <h1>{`역량검사 결과 제출이 완료되었어요.\n제출한 결과표를 확인하려 가볼까요?`}</h1>;
      case (NotificationType.PACC_RESULT_SUBMITTED):
        return <h1>{`개발자 역량검사 결과 제출이 완료되었어요.\n제출한 결과표를 확인하려 가볼까요?`}</h1>;
      case (NotificationType.PST_RESULT_SUBMITTED):
        return <h1>{`개발 구현 능력 검사 결과 제출이 완료되었어요.\n제출한 결과표를 확인하려 가볼까요?`}</h1>;
      case (NotificationType.ACC_REMINDER):
        return <h1>{`${login.userInfo?.name}님, 역량검사 응시하고 나에게 딱 맞는 포지션을\n쉽게 찾아보세요.`}</h1>;
      case (NotificationType.PACC_REMINDER):
        return <h1>{`${login.userInfo?.name}님, 개발자 역량검사 응시하고 나에게 딱 맞는 포지션을\n쉽게 찾아보세요.`}</h1>;
      case (NotificationType.PST_REMINDER):
        return <h1>{`${login.userInfo?.name}님, 개발 구현 능력 검사 응시하고 개발 실무 능력을\n길러 보세요.`}</h1>;
      case (NotificationType.ACC_COMPLETE_REMINDER):
        return <h1>{`${login.userInfo?.name}님, 현재 응시 중인 역량검사가 존재합니다. 이어서\n응시하시고 빠르게 취업에 성공해보세요.`}</h1>;
      case (NotificationType.PACC_COMPLETE_REMINDER):
        return <h1>{`${login.userInfo?.name}님, 현재 응시 중인 개발자 역량검사가 존재합니다.\n이어서 응시하시고 빠르게 취업에 성공해보세요.`}</h1>;
      case (NotificationType.PST_COMPLETE_REMINDER):
        return <h1>{`${login.userInfo?.name}님, 현재 응시 중인 개발 구현 능력 검사가\n존재합니다. 이어서 응시하시고 빠르게 취업에 성공해보세요.`}</h1>;
      case (NotificationType.ACC_RESULT_SUBMITTED_REMINDER_D_DAY):
        return <h1>{(item.data.companyName && item.data.positionName) ? `${item.data.companyName}의 ${item.data.positionName} 역량검사 결과 제출 마감일입니다.\n서둘러 제출해 주세요!` : `${login.userInfo?.name}님, 역량검사 결과 제출 마감일입니다.\n서둘러 제출해 주세요!`}</h1>;
      case (NotificationType.ACC_RESULT_SUBMITTED_REMINDER_D_3):
        return <h1>{(item.data.companyName && item.data.positionName) ? `${item.data.companyName}의 ${item.data.positionName} 역량검사 결과 제출 마감까지 3일 남았습니다.\n 서둘러 제출해 주세요!` : `${login.userInfo?.name}님, 역량검사 결과 제출 마감까지 3일 남았습니다.\n 서둘러 제출해 주세요!`}</h1>;
      case (NotificationType.PACC_RESULT_SUBMITTED_REMINDER_D_DAY):
        return <h1>{(item.data.companyName && item.data.positionName) ? `${item.data.companyName}의 ${item.data.positionName} 개발자 역량검사 결과 제출 마감일입니다.\n서둘러 제출해 주세요!` : `${login.userInfo?.name}님, 개발자 역량검사 결과 제출 마감일입니다.\n서둘러 제출해 주세요!`}</h1>;
      case (NotificationType.PACC_RESULT_SUBMITTED_REMINDER_D_3):
        return <h1>{(item.data.companyName && item.data.positionName) ? `${item.data.companyName}의 ${item.data.positionName} 개발자 역량검사 결과 제출 마감까지 3일 남았습니다.\n 서둘러 제출해 주세요!` : `${login.userInfo?.name}님, 개발자 역량검사 결과 제출 마감까지 3일 남았습니다.\n 서둘러 제출해 주세요!`}</h1>;
      case (NotificationType.PST_RESULT_SUBMITTED_REMINDER_D_DAY):
        return <h1>{(item.data.companyName && item.data.positionName) ? `${item.data.companyName}의 ${item.data.positionName} 개발 구현 능력 검사 결과 제출 마감일입니다.\n서둘러 제출해 주세요!` : `${login.userInfo?.name}님, 개발 구현 능력 검사 결과 제출 마감일입니다.\n서둘러 제출해 주세요!`}</h1>;
      case (NotificationType.PST_RESULT_SUBMITTED_REMINDER_D_3):
        return <h1>{(item.data.companyName && item.data.positionName) ? `${item.data.companyName}의 ${item.data.positionName} 개발 구현 능력 검사 결과 제출 마감까지 3일 남았습니다.\n 서둘러 제출해 주세요!` : `${login.userInfo?.name}님, 개발 구현 능력 검사 결과 제출 마감까지 3일 남았습니다.\n 서둘러 제출해 주세요!`}</h1>;
      case (NotificationType.REGISTER_USER):
        return <h1>{`${login.userInfo?.name}님 만나서 반가워요!\n잡다에서는 경력이 없어도 기업에게 면접제안을 받을 수 있어요.`}</h1>;
      case (NotificationType.ACC_RECOMMEND):
        return <h1>750개 이상의 회사가 사용하는 역량검사로 나의 잠재역량, 잘 맞는 직무 및 기업을 파악해보세요.</h1>;
      case (NotificationType.MATCH_OFFER):
        return <h1>{`${item.data.companyName}에서 `}<strong>입사지원 제안</strong>이 도착했어요. 어떤 제안이 왔는지 확인해보세요.</h1>;
      case (NotificationType.RECOMMEND):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에 추천됐어요.`} 포지션이 마음에 든다면, 관심 표현하고 제안받을 확률 올려보세요. </h1>;
      case (NotificationType.MATCH_INTERVIEW_OFFER):
        return <h1>{`${item.data.companyName}의  ${item.data.positionName}`}에서 <strong>면접 제안</strong>이 왔어요! 어떤 제안이 왔는지 확인해보세요.</h1>;
      case (NotificationType.ACC_LITE_COMPLETE):
        return <h1>{`${item.data.companyName}에서 요청한 역량검사 응시가 완료되었어요. \n제출 기한 전까지 응시 결과를 제출해 주세요.`}</h1>;
      case (NotificationType.ACC_LITE_RESULT_SUBMITTED):
        return <h1>{`${item.data.companyName}에서 요청한 역량검사 결과 제출이 완료되었어요.\n다음 전형 안내까지 조금만 기다려 주세요.`}</h1>;
      case (NotificationType.INTERVIEW_SCHEDULE_ADJUST):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서`} <strong>면접 제안</strong>이 왔어요! 면접 일정표를 보고 가능한 시간을 선택해 주세요.</h1>;
      case (NotificationType.INTERVIEW_SCHEDULE_CONFIRM):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서`} <strong>면접 일정</strong>을 안내드려요. 메시지를 확인해 보세요.</h1>;
      default:
        return '';
    }
  };
  const getNavMsgType = (userNotificationType: NotificationType) => {
    switch (userNotificationType) {
      case (NotificationType.INTERVIEW_OFFER):
      case (NotificationType.SIMPLE_MESSAGE):
      case (NotificationType.FILE_REQUEST):
      case (NotificationType.DETAILED_BROWSE):
      case (NotificationType.JD_CHANGE):
      case (NotificationType.STATUS_CHANGE):
      case (NotificationType.ACC_REQUEST):
      case (NotificationType.PHS_REQUEST):
      case (NotificationType.INSIGHT_STATUS_CHANGE):
      case (NotificationType.MATCH_INTERVIEW_OFFER):
      case (NotificationType.RECOMMEND):
      case (NotificationType.MATCH_OFFER):
      case (NotificationType.INTERVIEW_SCHEDULE_ADJUST):
      case (NotificationType.INTERVIEW_SCHEDULE_CONFIRM):
        return '확인하기';
      case (NotificationType.FILE_REQUEST_DEADLINE_REMINDER_D_DAY):
        return '추가서류 제출하기';
      case (NotificationType.ACC_ANALYSIS_COMPLETE):
      case (NotificationType.PST_ANALYSIS_COMPLETE):
      case (NotificationType.PACC_ANALYSIS_COMPLETE):
        return '분석결과 확인하기';
      case (NotificationType.ACC_EXPIRED):
      case (NotificationType.PACC_EXPIRED):
      case (NotificationType.PST_EXPIRED):
        return '홈으로 바로가기';
      case (NotificationType.GENERAL_CREATED):
      case (NotificationType.DEVELOPER_CREATED):
        return '잡다매칭 바로가기';
      case (NotificationType.MATCHING_TALK_LINK):
        return '메시지 확인하기';
      case (NotificationType.PROFILE_UPDATE_BY_RESUME):
        return '내 프로필 바로가기';
      case (NotificationType.JOB_APPLICATION_SUBMITTED):
      case (NotificationType.COPY_JOB_APPLICATION):
        return '지원현황 확인하기';
      case (NotificationType.JOB_APPLICATION_CANCELED):
        return '포지션 보러가기';
      case (NotificationType.ACC_RESULT_SUBMITTED):
      case (NotificationType.PACC_RESULT_SUBMITTED):
      case (NotificationType.PST_RESULT_SUBMITTED):
        return '제출한 결과표 확인하기';
      case (NotificationType.INTERVIEW_OFFER_RESPONDED):
        return '면접 연습하러 가기';
      case (NotificationType.INTERVIEW_OFFER_DEADLINE_REMINDER_D_3):
      case (NotificationType.INTERVIEW_OFFER_DEADLINE_REMINDER_D_DAY):
        return '답변하러 가기';
      case (NotificationType.JOB_APPLICATION_DEADLINE_REMINDER_D_3):
      case (NotificationType.JOB_APPLICATION_DEADLINE_REMINDER_D_DAY):
        return '지원서 작성하기';
      case (NotificationType.ACC_REMINDER):
      case (NotificationType.PACC_REMINDER):
        return '역량검사 응시하러 가기';
      case (NotificationType.PST_REMINDER):
        return '개발 구현 능력 검사 응시하러 가기';
      case (NotificationType.ACC_COMPLETE_REMINDER):
        return '응시 중인 역량검사 확인하기';
      case (NotificationType.PACC_COMPLETE_REMINDER):
        return '응시 중인 개발자 역량검사 확인하기';
      case (NotificationType.PST_COMPLETE_REMINDER):
        return '응시 중인 개발 구현 능력 검사 확인하기';
      case (NotificationType.PROFILE_INPUT_REMINDER):
        return '프로필 작성하러 가기';
      case (NotificationType.ACC_RESULT_SUBMITTED_REMINDER_D_3):
      case (NotificationType.ACC_RESULT_SUBMITTED_REMINDER_D_DAY):
        return '역량검사 결과 제출하기';
      case (NotificationType.PACC_RESULT_SUBMITTED_REMINDER_D_3):
      case (NotificationType.PACC_RESULT_SUBMITTED_REMINDER_D_DAY):
        return '개발자 역량검사 결과 제출하기';
      case (NotificationType.PST_RESULT_SUBMITTED_REMINDER_D_3):
      case (NotificationType.PST_RESULT_SUBMITTED_REMINDER_D_DAY):
        return '개발 구현 능력 검사 결과 제출하기';
      case (NotificationType.DEVELOPER_DELETED):
      case (NotificationType.GENERAL_DELETED):
        return '잡다매칭 혜택 다시보기';
      case (NotificationType.REGISTER_USER):
        return '면접제안 받기';
      case (NotificationType.ACC_RECOMMEND):
        return '나 알아보기';
      default:
        return '';
    }
  };
  const onClickNotification = (userNotificationType: NotificationType, positionSn?: number) => {
    switch (userNotificationType) {
      case (NotificationType.DETAILED_BROWSE):
      case (NotificationType.JD_CHANGE):
        history.push(RoutePaths.position_jd(positionSn));
        break;
      case (NotificationType.ACC_ANALYSIS_COMPLETE):
        history.push(RoutePaths.profile);
        break;
      case (NotificationType.FILE_REQUEST):
      case (NotificationType.FILE_REQUEST_DEADLINE_REMINDER_D_DAY):
      case (NotificationType.INTERVIEW_OFFER):
      case (NotificationType.SIMPLE_MESSAGE):
      case (NotificationType.STATUS_CHANGE):
      case (NotificationType.COPY_JOB_APPLICATION):
      case (NotificationType.INTERVIEW_SCHEDULE_ADJUST):
      case (NotificationType.INTERVIEW_SCHEDULE_CONFIRM):
        matchMessageStore.messageAccessPath = MessageAccessPath.JOBDA_NOTIFICATION;
        matchMessageStore.open(positionSn || -1);
        break;
      case (NotificationType.PACC_ANALYSIS_COMPLETE):
      case (NotificationType.PST_ANALYSIS_COMPLETE):
      case (NotificationType.PROFILE_INPUT_REMINDER):
        history.push(RoutePaths.profile);
        break;
      case (NotificationType.REGISTER_USER):
        history.push(RoutePaths.match_introduce);
        break;
      case (NotificationType.GENERAL_CREATED):
      case (NotificationType.DEVELOPER_CREATED):
        history.push(RoutePaths.match_apply);
        break;
      case (NotificationType.ACC_EXPIRED):
      case (NotificationType.PACC_EXPIRED):
      case (NotificationType.PST_EXPIRED):
      case (NotificationType.INTERVIEW_OFFER_DEADLINE_REMINDER_D_3):
      case (NotificationType.ACC_RESULT_SUBMITTED_REMINDER_D_3):
      case (NotificationType.PACC_RESULT_SUBMITTED_REMINDER_D_3):
      case (NotificationType.PST_RESULT_SUBMITTED_REMINDER_D_3):
      case (NotificationType.INTERVIEW_OFFER_DEADLINE_REMINDER_D_DAY):
      case (NotificationType.ACC_RESULT_SUBMITTED_REMINDER_D_DAY):
      case (NotificationType.PACC_RESULT_SUBMITTED_REMINDER_D_DAY):
      case (NotificationType.PST_RESULT_SUBMITTED_REMINDER_D_DAY):
        history.push({ pathname: '/mypage/apply', search: `?tab=${MatchTabType.IN_PROGRESS}` });
        break;
      case (NotificationType.JOB_APPLICATION_SUBMITTED):
        history.push({ pathname: '/mypage/apply', search: `?tab=${MatchTabType.APPLICATION}` });
        break;
      case (NotificationType.JOB_APPLICATION_DEADLINE_REMINDER_D_3):
      case (NotificationType.JOB_APPLICATION_DEADLINE_REMINDER_D_DAY):
        history.push(RoutePaths.mypage_applying);
        break;
      case (NotificationType.ACC_REQUEST):
      case (NotificationType.PHS_REQUEST):
      case (NotificationType.MATCHING_TALK_LINK):
      case (NotificationType.INSIGHT_STATUS_CHANGE):
        history.push({ pathname: '/mypage/apply', search: `?sn=${item.data.positionSn}` });
        break;
      case (NotificationType.PROFILE_UPDATE_BY_RESUME):
        history.push(RoutePaths.profile);
        break;
      case (NotificationType.JOB_APPLICATION_CANCELED):
        history.push(RoutePaths.home);
        break;
      case (NotificationType.INTERVIEW_OFFER_RESPONDED):
        history.push(RoutePaths.pass_interview);
        break;
      case (NotificationType.ACC_REMINDER):
      case (NotificationType.ACC_COMPLETE_REMINDER):
      case (NotificationType.ACC_RECOMMEND):
        history.push(RoutePaths.acca_test);
        break;
      case (NotificationType.PACC_REMINDER):
      case (NotificationType.PACC_COMPLETE_REMINDER):
      case (NotificationType.PST_REMINDER):
      case (NotificationType.PST_COMPLETE_REMINDER):
        history.push(RoutePaths.phs_test);
        break;
      case (NotificationType.GENERAL_DELETED):
      case (NotificationType.DEVELOPER_DELETED):
        history.push(RoutePaths.match_introduce);
        break;
      case (NotificationType.ACC_RESULT_SUBMITTED):
        history.push(RoutePaths.acca_results(item.data.applySn));
        break;
      case (NotificationType.PACC_RESULT_SUBMITTED):
      case (NotificationType.PST_RESULT_SUBMITTED):
        history.push(RoutePaths.phs_pacc_results(item.data.applySn));
        break;
      case (NotificationType.MATCH_OFFER):
        history.push(RoutePaths.match_apply_status_tab(MatchApplyStatusTabType.MATCH_OFFER, item.data.positionSn, MessageAccessPath.JOBDA_NOTIFICATION));
        break;
      case (NotificationType.RECOMMEND):
        history.push(RoutePaths.match_apply_status_tab(MatchApplyStatusTabType.RECOMMEND));
        break;
      case (NotificationType.MATCH_INTERVIEW_OFFER):
        history.push(RoutePaths.match_apply_status_tab(MatchApplyStatusTabType.MATCH_INTERVIEW_OFFER, item.data.positionSn));
        break;
      default:
        break;
    }
  };
  const getTime = (dateTime: string) => {
    const dcount = getDCount(dateTime);
    if (dcount === 1) {
      const diffMin = getDMinutes(dateTime);
      if (diffMin < 1) return `방금전`;
      if (diffMin >= 1 && diffMin < 60) return `${diffMin}분 전`;
      if (diffMin > 60 && diffMin < 1380) return `${getDHour(dateTime)}시간 전`;
      return `${dcount}일 전`;
    }
    return `${dcount}일 전`;
  };
  const NotificationComponent = () => {
    if (item.type === NotificationType.ACC_COMPLETE
      || item.type === NotificationType.PACC_COMPLETE
      || item.type === NotificationType.PST_COMPLETE
      || item.type === NotificationType.ACC_LITE_COMPLETE
      || item.type === NotificationType.ACC_LITE_RESULT_SUBMITTED
      || item.type === NotificationType.DELETE_JOB_APPLICATION) {
      return null;
    }
    return <LazyImage src={imgArrow} className='icon-arrow' />;
  };

  return (
    <StyledNotificationCard className={`notification-card-${item.notificationSn}`}>
      <div className='content-frame'>
        <div>
          <h3>
            {getTime(item.createdDateTime)}
          </h3>
          {!item.accessYn && <div className='new' />}
        </div>
        {getMsgType(item.type)}
        <div
          className='move-button'
          role='button'
          onClick={async () => {
            onClickNotification(item.type, item.data.positionSn);
            await notificationModel.readNotifications([item.notificationSn]);
          }}
        >
          {getNavMsgType(item.type)}
          {NotificationComponent()}
        </div>
      </div>
      <div className='close-frame'>
        <button onClick={async () => {
          await notificationModel.deleteNotifications([item.notificationSn]);
          notificationModel.notifications = [...notificationModel.notifications.filter((listItem) => listItem.notificationSn !== item.notificationSn)];
        }}
        >
          <img src={IconClose} alt='닫기' />
        </button>
      </div>
    </StyledNotificationCard>
  );
};

export default inject(injectStore.notificationModel, injectStore.matchMessageStore, injectStore.login)(NotificationCard);
