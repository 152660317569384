import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import V2Button from '__pc__/components/common/v2Design/button';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BorderColorsType } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { RadiusType, RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import ImgDumy from 'assets/_v2/positionList/img_dumy.png';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import MainNoPosition from 'components/_v2/main/MainNoPosition';
import { LocationCode } from 'consts/CompanyLocationType';
import RoutePaths from 'consts/RoutePaths';
import { lineClampBox } from 'consts/_v2/_common/style/mixins';
import { RecruitmentType, RecruitmentTypeText } from 'consts/_v2/position/PositionType';
import useToast from 'hooks/useToast';
import { IAccCenterPosition } from 'interfaces/_v2/acc/IAccGate';
import { MatchingHomeRecommendPositionDto } from 'interfaces/_v2/matchHome/IMatchHomeRqRs';
import { IPositionCompanyCardDto } from 'interfaces/_v2/positionJd/IPositionCompanyCardListRs';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Login from 'models/Login';
import Main from 'models/_v2/Main';
import PositionListModel from 'models/_v2/PositionListModel';
import { injectStore } from 'models/store';
import { useMatchHomePositionsRecommend } from 'query/match/useMatchQuery';
import useProfileConditions from 'query/profiles/useProfileConditionsQuery';
import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getChipDday, getDDayString, str2DateTime } from 'utils/DateUtils';

interface IPopularPositionCardProps {
    positionInfo: MatchingHomeRecommendPositionDto;
    login? :Login;
    context?:Context;
    positionListModel?: PositionListModel;

  }

const PositionCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 160px;
  height: 214px;

  .thumbnail {
    width: 160px;
    height: 106px;
    border: 1px solid ${V2BorderColorsType.default};
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: ${RadiusValue.M}px;
      object-fit: cover;
    }
    .bookmark-frame {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
  
  .card-info {
    display: flex;
    flex-direction: column;
    gap: ${SpaceValue.XXXS}px;
    margin-top: ${SpaceValue.XS}px;

    .position-name {
      ${lineClampBox(2)};
    }
  }

  .card-desc {
    position: relative;
    display: flex;
    gap: ${SpaceValue.L}px;
    margin-top: ${SpaceValue.M}px;

    > :not(:first-child) {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: -8px;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 8px;
        background-color: #DFE0E2;
      }
    }
  }
`;

const BookMarkFrame = styled.div`
  svg {
    width: 24px;
    height: 24px;
  }
`;
const MainPopularPositionCard:FC<IPopularPositionCardProps> = ({ positionInfo, login = new Login(), context = new Context(), positionListModel = new PositionListModel() }) => {
  const { setToastObject } = useToast();
  const { pathname, search } = useLocation();
  const [bookmark, setBookmark] = useState<boolean>(false);
  const history = useHistory();
  const instanceOfIAccCenterPosition = (
    object: any,
  ): object is IAccCenterPosition => {
    if (object === undefined) return false;
    return 'sn' in object;
  };

  const setPositionBookMark = async () => {
    try {
      if (!login.userInfo?.id) {
        context.setRedirectUrl(pathname + search);
        history.push(RoutePaths.login);
        return;
      }
      if (instanceOfIAccCenterPosition(positionInfo)) {
        await positionListModel.setMatchPositionBookMark(bookmark, positionInfo.sn);
      } else {
        await positionListModel.setMatchPositionBookMark(
          bookmark,
          positionInfo.positionSn,
        );
      }
      if (!bookmark) {
        setToastObject({ isOpen: true, type: 'INFO', message: '북마크에 저장되었습니다', subMessage: undefined });
      } else {
        setToastObject({ isOpen: true, type: 'INFO', message: '북마크가 삭제되었습니다', subMessage: undefined });
      }
      setBookmark(!bookmark);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <PositionCard>
      <div className='thumbnail'>
        {/* [D] 북마크/썸네일 작업 필요 */}
        <img
          className='recruit-img'
          alt='card'
          src={positionInfo.thumbnailImageUrl}
          onError={(e) => {
            e.currentTarget.src = ImgDumy;
          }}
        />
        <div className='bookmark-frame'>
          <BookMarkFrame
            onClick={(e) => {
              setPositionBookMark();
              e.stopPropagation();
              e.preventDefault();
            }}
          >{bookmark ? <Icon name='bookmark-filled' size={24} color={colors.WHITE_100} /> : <Icon name='bookmark' size={24} color={colors.WHITE_100} />}
          </BookMarkFrame>
        </div>
      </div>
      <div className='card-info'>
        <V2Text
          className='position-name'
          fontStyle={V2FontStyleType.body_1_b}
          color={V2TextColorsType.default}
        >
          {positionInfo.positionName}
        </V2Text>
        <V2Text
          fontStyle={V2FontStyleType.body_3_m}
          color={V2TextColorsType.subtlest}
          ellipsis
        >
          {positionInfo.companyName}
        </V2Text>
      </div>
      <div className='card-desc'>
        {
            positionInfo.locationCode
            && (
            <V2Text
              fontStyle={V2FontStyleType.body_3_m}
              color={V2TextColorsType.subtle}
            >
              {LocationCode(positionInfo.locationCode)}
            </V2Text>
            )
          }
        {
              positionInfo.recruitmentType
              && (
              <V2Text
                fontStyle={V2FontStyleType.body_3_m}
                color={V2TextColorsType.subtle}
              >
                {RecruitmentTypeText[positionInfo.recruitmentType] }
              </V2Text>
              )
            }
        {
            positionInfo.closingDateTime
              ? (
                <V2Text
                  fontStyle={V2FontStyleType.body_3_m}
                  color={V2TextColorsType.subtle}
                >
                  {getDDayString(str2DateTime(positionInfo.closingDateTime))}
                </V2Text>
              ) : (
                <V2Text
                  fontStyle={V2FontStyleType.body_3_m}
                  color={V2TextColorsType.subtle}
                >
                  상시채용
                </V2Text>
              )
          }

      </div>
    </PositionCard>
  );
};

export default inject(injectStore.login, injectStore.context, injectStore.positionListModel)(observer(MainPopularPositionCard));
