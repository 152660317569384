import graphLottie from '__designkit__/assets/lottie/lottie_graph_bar.json';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Emoji from '__designkit__/icon/Emoji';
import Icon from '__designkit__/icon/Icon';
import Lottie from 'lottie-react';
import React, { FC } from 'react';
import styled from 'styled-components';
import ImgTutorial from 'assets/_v2/main/img_tutorial_main.png';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2FontStyle, V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { V2TextColors, V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
  height: 100%;

`;
const HeaderFrame = styled.div`
  display: flex;
  justify-content: flex-end;
  padding:12px;

`;
const ContentsFrame = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 16px;;
  width: 100%;
  height: 100%;
  text-align: center;
  .img-tutorial{
    width: 100%;
    height: auto;
  }
`;
interface IMainTutoriamMain {
  onClose: () => void;
}
const MainTutorialMain:FC<IMainTutoriamMain> = ({ onClose }) => (
  <Frame>
    <HeaderFrame>
      <div role='button' onClick={onClose}>
        <Icon name='close' size={32} />
      </div>
    </HeaderFrame>
    <ContentsFrame>
      <V2Text fontStyle={V2FontStyleType.body_2_b} color={V2TextColorsType.brand}>매칭 튜토리얼</V2Text>
      <SpacingBlock size={SpaceValue.XS} vertical />
      <V2Text fontStyle={V2FontStyleType.title_1_b} color={V2TextColorsType.default}>잡다매칭 참여하고<br /> 나의 역량만으로 취업하세요</V2Text>
      <SpacingBlock size={SpaceValue.XL} vertical />
      <img src={ImgTutorial} alt='tutorial' className='img-tutorial' />
      <SpacingBlock size={SpaceValue.XXXL} vertical />
      <V2Text fontStyle={V2FontStyleType.body_1_m} color={V2TextColorsType.subtle}>역량검사를 응시하고 프로필을 입력하면 나의 역량을<br />
        기반으로 적합한 기업, 직무를 매칭해드려요
      </V2Text>
    </ContentsFrame>
  </Frame>
);

export default MainTutorialMain;
