import { V2BackgroundColorsType } from './Colors/V2BackgroundColors';
import { V2BorderColorsType } from './Colors/V2BorderColors';
import { V2TextColorsType } from './Colors/V2TextColors';
import { V2FontStyleType } from './V2FontStyle';

export enum V2BadgeType {
  Fill = 'Fill',
  OutLine = 'OutLine',
}

export enum V2BadgeSizeType {
  L = 'L',
  M = 'M',
}

export const V2BadgeOption = {
  type: V2BadgeType,
  size: V2BadgeSizeType,
  fontStyle: V2FontStyleType,
  color: V2TextColorsType,
  backgroundColor: V2BackgroundColorsType,
  borderColor: V2BorderColorsType,
};
