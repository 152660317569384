import { action, observable } from 'mobx';
import { request } from 'utils/request';
import URIs from 'consts/URIs';
import { TermType } from 'consts/TermType';
import { ISocialAuthLoginRs } from 'interfaces/rqrs/ISocialAuthLoginRs';
import { ITermsListGetRs, ITermsGetDto, ITermItemGetRs } from 'interfaces/rqrs/ITermsListGetRs';
import { ICheckDuplicateIdRq, IRequestVerificationCodeRq, ICheckVerificationCodeRq, IDuplicateOrNotRs, IGetCheckMobileDuplicateRq, IJoinRq, IResultOrNotRs, ISuccessOrNotRs, IGetCheckMobileDuplicateRs } from 'interfaces/_v2/join/IJoinRqRs';

export enum JOIN_ERROR {
  WRONG_TOKEN = 'WRONG_TOKEN',
  UNKNOWN = 'UNKNOWN'
}

export default class JoinModel {
  @observable socialJoinInfo: ISocialAuthLoginRs | null = null;
  @observable activeTermType: TermType | null = null;
  @observable activeTermContents: ITermsGetDto[] = [];

  // 휴대폰 번호 중복 체크
  @action
  checkMobileDuplicate = async (params: IGetCheckMobileDuplicateRq): Promise<IGetCheckMobileDuplicateRs> => {
    try {
      const res = await request<IGetCheckMobileDuplicateRs>({
        method: 'get',
        url: URIs.get_register_duplication_check_mobile,
        params,
      });
      return res;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // 비회원 휴대폰 번호 인증 코드 발송
  @action
  requestVerificationCode = async (data: IRequestVerificationCodeRq): Promise<boolean> => {
    try {
      const res = await request<ISuccessOrNotRs>({
        method: 'post',
        url: URIs.post_v2_verification_sms_send,
        data,
      });
      return res.success;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // 비회원 휴대폰 번호 인증 코드 확인
  @action
  checkVerificationCode = async (data: ICheckVerificationCodeRq): Promise<boolean> => {
    try {
      const res = await request<ISuccessOrNotRs>({
        method: 'post',
        url: URIs.post_verification_sms_check,
        data,
      });
      return res.success;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // 아이디 중복 체크
  @action
  checkDuplicateId = async (params: ICheckDuplicateIdRq): Promise<boolean> => {
    try {
      const res = await request<IDuplicateOrNotRs>({
        method: 'get',
        url: URIs.get_register_duplication_check_id,
        params,
      });
      return res.isDuplicate;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // 회원가입
  @action
  join = async (data: IJoinRq): Promise<boolean> => {
    try {
      const res = await request<IResultOrNotRs>({
        method: 'post',
        url: URIs.post_v5_register,
        data,
      });
      return res.result;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // Terms
  @action
  loadTermsList = async (termType: TermType) => {
    try {
      this.activeTermContents = (await request<ITermsListGetRs>({
        method: 'get',
        url: URIs.get_terms,
        params: { termType },
      })).terms;
    } catch (e) {
      this.activeTermContents = [];
    }
  }

  @action
  loadPartnershipTermsDetail = async (termType: TermType) => {
    try {
      const res = (await request<ITermItemGetRs>({
        method: 'get',
        url: URIs.get_partnership_terms,
        params: { termType },
      })).terms;
      this.activeTermContents = [res];
    } catch (e) {
      this.activeTermContents = [];
    }
  };

  @action
  resetTerms=() => {
    this.activeTermContents = [];
    this.activeTermType = null;
  }
}
