import { V2IconColors, V2IconColorsType } from '__pc__/constant/v2Design/Colors/V2IconColors';
import { V2IconSizeType, V2IconSizeValue } from '__pc__/constant/v2Design/V2IconType';
import { IconName } from 'interfaces/_v2/_common/iconType';
import { FC } from 'react';
import V2IconList from './V2Icon';

interface IconProps {
  name: IconName;
  size?: V2IconSizeType;
  fill?: V2IconColorsType;
  disabled?: boolean;
}

/**
 * @param {IconNameType} [name] - 아이콘 이름.
 * @param {V2IconSizeType} [size] - 아이콘 사이즈.
 * @param {V2IconColorsType} [fill] - 아이콘 색상.
 * @param {boolean} [disabled] - 아이콘  disabled 색상 적용.
 */

const V2Icon: FC<IconProps> = ({ name, size = V2IconSizeType.L, fill = V2IconColorsType.default, disabled }) => {
  const convertProps = {
    name,
    width: V2IconSizeValue[size],
    height: V2IconSizeValue[size],
    fill: disabled ? V2IconColors[V2IconColorsType.disabled] : V2IconColors[fill],
  };
  return <V2IconList {...convertProps} />;
};

export default V2Icon;
