import PopUpNudgeModal from 'components/_v2/_common/PopUpNudgeModal';
import URIs from 'consts/URIs';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { request } from 'utils/request';

export interface IPopup {
  sn: number;
  linkType: string;
  link: string;
  postStartDateTime: string;
  postEndDateTime: string;
  pcAttachFileUrl: string;
  moAttachFileUrl: string;
}

interface IPopupRouterProps {
  isRequiredExposure: boolean;
}
const PopupRouter: FC<IPopupRouterProps> = ({ isRequiredExposure }) => {
  const [popups, setPopups] = useState<IPopup[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();

  const loadPopup = async () => {
    try {
      const res = (await request<{ popups: IPopup[] }>({
        method: 'get',
        url: URIs.get_popup,
        params: { url: pathname },
      })).popups;
      setPopups(res);
      setIsOpen(res.length > 0);
    } catch (e) {
      console.error(e);
      setPopups([]);
    }
  };

  useEffect(() => {
    if (!isRequiredExposure) loadPopup();
  }, [pathname]);

  return (
    <>
      <PopUpNudgeModal
        popups={popups}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

export default PopupRouter;
