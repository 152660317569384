import JDBaseModal from 'components/_v2/_common/modals/JDBaseModal';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import URIs from 'consts/URIs';
import Fonts from '__designkit__/common/fonts';
import queryStirng from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { request } from 'utils/request';
import SupportMail from 'consts/_v2/GlobalText';

const Frame = styled.div`
  width:296px;
  display: flex;
  flex-direction: column;
  background: ${colors.WHITE_100};
  border-radius: 12px;
  padding:40px 24px;

  h1{
    font: ${Fonts.H4_Bold};
    color: ${colors.JOBDA_BLACK};
    text-align: center;
    margin-bottom: 8px;
  }
  h2{
    font: ${Fonts.B2_Medium};
    line-height: 22px;
    ${colors.CG_70};
    text-align: center;
    margin-bottom:24px;
  }


  a{
    display: contents;
  }

  .email{
    >span{
    text-align: center;
    color:${colors.B_100};
    font: ${Fonts.B3_Medium};
    margin-bottom: 24px;
  }
  }


  button{
    padding: 10px 16px;
    border-radius: 4px;
    font: ${Fonts.B2_Bold};
    ${colors.CG_70};
    text-align: center;
    color:${colors.WHITE_100};
    background: ${colors.JOBDA_BLACK};
  }
`;

enum AccaAgreeType {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  ALREADY = 'ALREADY',
  NONE='NONE'
}

const AccaAgree: FC = () => {
  const { search } = useLocation();
  const [type, setType] = useState<AccaAgreeType>(AccaAgreeType.NONE);

  useEffect(() => {
    const { id } = queryStirng.parse(search);
    const setAccaAgree = async () => {
      try {
        const res = await request({
          method: 'post',
          url: URIs.post_user_terms_agree_alimatalk_acc_agree,
          data: { id },
        });
        if (res) setType(AccaAgreeType.SUCCESS);
        else setType(AccaAgreeType.FAIL);
      } catch (e: any) {
        if (e.response.data.errorCode === 'B902') {
          setType(AccaAgreeType.ALREADY);
        } else setType(AccaAgreeType.FAIL);
      }
    };
    setAccaAgree();
  }, []);

  const getContent = () => {
    switch (type) {
      case AccaAgreeType.SUCCESS:
        return (
          <Frame>
            <h1>역량검사 유효기간이<br />
              연장되었습니다.
            </h1>
            <h2>서비스 이용 기간 동안<br />
              역량검사 응시/결과 정보를<br />
              자유롭게 확인하실 수 있습니다.
            </h2>
            <Link to={RoutePaths.root}>
              <button>확인</button>
            </Link>
          </Frame>
        );
      case AccaAgreeType.FAIL:
        return (
          <Frame>
            <h1>역량검사 유효기간<br />
              연장에 실패했습니다.
            </h1>
            <h2>이와 같은 메시지를 확인한 회원님께서는<br />
              잡다로 문의주시기 바랍니다.
            </h2>
            <a className='email' href={`mailto:${SupportMail}`}><span>문의처 : {SupportMail}</span></a>
            <Link to={RoutePaths.root}>
              <button>확인</button>
            </Link>
          </Frame>
        );
      case AccaAgreeType.ALREADY:
        return (
          <Frame>
            <h1>이미 동의하셨습니다.</h1>
            <Link to={RoutePaths.root}>
              <button>확인</button>
            </Link>
          </Frame>
        );
      default:
        return (
          <></>
        );
    }
  };

  return (
    <div>
      <JDBaseModal dimmed isOpen onClickClose={() => { }}>
        {getContent()}
      </JDBaseModal>
    </div>
  );
};

export default AccaAgree;
