import { useQuery } from 'react-query';
import { RemoteAccRepo } from 'repository/acc/AccRepo';

export const keys = ['v2', 'acc', 'results'];

const accRepo = new RemoteAccRepo();

const fetchAccResults = async () => {
  const data = await accRepo.fetchAccResults();
  return data;
};

export default function useAccResults(isEnabled: boolean) {
  return useQuery([...keys], fetchAccResults, { enabled: isEnabled });
}
