import URIs from 'consts/URIs';
import { IMatchingReportCompanyRs, IMatchingReportPositionsRs, IMatchingReportRs, MatchingReportPositionDto } from 'interfaces/_v2/matchingReport/IMatchingReportRs';
import { action, observable } from 'mobx';
import { request } from 'utils/request';
import axios from 'axios';

export default class MatchReportModel {
    @observable matchReportCompanyInformation:IMatchingReportCompanyRs | null = null;
    @observable matchReportCompaniesPositions:IMatchingReportPositionsRs | null = null;
    @observable matchReportPositionsDetail:IMatchingReportRs | null = null;
    @observable currentReportPosition:MatchingReportPositionDto | null = null;
    @observable currentReportMessageSn:number = 0;
    @observable locationX: string = '';
    @observable locationY: string = '';
    @observable changedPosition:boolean = false;

    @action
    getLocation = async () => {
      try {
        await axios.get(`https://dapi.kakao.com/v2/local/search/address.json?query=${this.matchReportPositionsDetail?.addressInfo.address}`,
          { headers: { Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_REST_KEY}` } })
          .then((res) => {
            const location = res.data.documents[0];
            this.locationX = location.address.x;
            this.locationY = location.address.y;
          });
      } catch (e) {
        console.error(e);
      }
    };

    @action
    acceptMatch = async (positionSn: number, optionSn: number) => {
      try {
        await request({
          method: 'post',
          url: URIs.post_match_accept,
          data: { positionSn, optionSn },
        });
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    loadMatchReportCompanies = async (sn:number) => {
      try {
        this.matchReportCompanyInformation = (await request({
          method: 'get',
          url: URIs.get_match_report_companies_sn(sn),
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadMatchReportCompaniesPositions = async (sn:number) => {
      try {
        this.matchReportCompaniesPositions = (await request({
          method: 'get',
          url: URIs.get_match_report_companies_sn_positions(sn),
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadMatchReportPositionsDetail = async (positionSn:number) => {
      try {
        this.matchReportPositionsDetail = (await request({
          method: 'get',
          url: URIs.get_match_report,
          params: { positionSn },
        }));
      } catch (e) {
        console.error(e);
      }
    }
}
