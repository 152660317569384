import { IAccApplyResultRs, IAccGameRs, IAccResultsToken, IAccSelfReportRs, IAccVideoRs } from 'interfaces/_v2/acc/IAccResultsRs';
import { action, observable } from 'mobx';
import { request } from 'utils/request';
import URIs from 'consts/URIs';
import { PhsAccType } from 'consts/MatchingMessageType';
import { IAccProgressCompleteDto } from 'interfaces/_v2/acc/IAccProgressCompleteRs';

export default class AccResultsModel {
    @observable game:IAccGameRs | null = null;
    @observable applyResult:IAccApplyResultRs | null = null;
    @observable selfReport:IAccSelfReportRs | null = null;
    @observable video:IAccVideoRs | null = null;
    @observable applyResultToken:IAccResultsToken | null = null;
    @observable accProgressList: IAccProgressCompleteDto[] | null = null;

    @action
    reset = async () => {
      this.game = null;
      this.applyResult = null;
      this.selfReport = null;
      this.video = null;
    };

    @action
    loadGame = async (sn:number, type:PhsAccType) => {
      if (sn === 0) return false;
      if (type === PhsAccType.ACC) {
        try {
          this.game = (await request({
            method: 'get',
            url: URIs.get_acc_apply_game,
            params: { sn },
          }));
          return true;
        } catch (e) {
          console.error(e);
          throw e;
        }
      } else {
        try {
          this.game = (await request({
            method: 'get',
            url: URIs.get_phs_pacc_results_game,
            params: { sn },
          }));
          return true;
        } catch (e) {
          console.error(e);
          throw e;
        }
      }
    }

    @action
    loadAccProgressList = async () => {
      try {
        this.accProgressList = (await request<{resultRecords : IAccProgressCompleteDto[]}>({
          method: 'get',
          url: URIs.get_acc_results_list,
        })).resultRecords;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadApplyResult = async (sn:number, type:PhsAccType) => {
      if (sn === 0) return false;
      try {
        if (type === PhsAccType.ACC) {
          this.applyResult = (await request({
            method: 'get',
            url: URIs.get_acc_apply_result,
            params: { sn },
          }));
          return true;
        }
        this.applyResult = (await request({
          method: 'get',
          url: URIs.get_phs_pacc_results_result,
          params: { sn },
        }));
        return true;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    loadApplyResultToken = async (code:string) => {
      try {
        this.applyResultToken = (await request({
          method: 'get',
          url: URIs.get_acc_result_token,
          params: { code },
        }));
        return true;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    loadSelfReport = async (sn:number, type:PhsAccType) => {
      if (sn === 0) return false;
      if (type === PhsAccType.ACC) {
        try {
          this.selfReport = (await request({
            method: 'get',
            url: URIs.get_acc_apply_self_report,
            params: { sn },
          }));
          return true;
        } catch (e) {
          console.error(e);
          throw e;
        }
      } else {
        try {
          this.selfReport = (await request({
            method: 'get',
            url: URIs.get_phs_pacc_results_self_report,
            params: { sn },
          }));
          return true;
        } catch (e) {
          console.error(e);
          throw e;
        }
      }
    }

    @action
    loadVideo = async (sn:number, type:PhsAccType) => {
      if (sn === 0) return false;
      if (type === PhsAccType.ACC) {
        try {
          this.video = (await request({
            method: 'get',
            url: URIs.get_acc_apply_video,
            params: { sn },
          }));
          return true;
        } catch (e) {
          console.error(e);
          throw e;
        }
      } else {
        try {
          this.video = (await request({
            method: 'get',
            url: URIs.get_phs_pacc_results_video,
            params: { sn },
          }));
          return true;
        } catch (e) {
          console.error(e);
          throw e;
        }
      }
    }
}
