import URIs from 'consts/URIs';
import IExaminationGradeListRs from 'interfaces/rqrs/IExaminationGradeListRs';
import IExaminationListRs, { IExaminationDto } from 'interfaces/rqrs/IExaminationListRs';
import { ILicenseDto, ILicenseListRs } from 'interfaces/rqrs/ILicenseListRs';
import ISkillListRs, { ISkillDto } from 'interfaces/rqrs/ISkillListRs';
import ISkillRecommendListRs, { ISkillCategoryDto } from 'interfaces/rqrs/ISkillRecommendListRs';
import IProfileKnowledgeAndSkillsRs from 'interfaces/_v2/profile/IProfileKnowledgeAndSkillsRs';
import IProfileKnowledgeAndSkillsSaveRq from 'interfaces/_v2/profile/IProfileKnowledgeAndSkillsSaveRq';
import { action, observable } from 'mobx';
import { request } from 'utils/request';
import store from 'models/store';

export default class ProfileSkillModel {
    @observable skill: IProfileKnowledgeAndSkillsRs | null = null;
    @observable searchedLicenses:ILicenseDto[] = [];
    @observable searchedExaminations:IExaminationDto[] = [];
    @observable searchedSkills:ISkillDto[] = [];
    @observable recommendSkills: ISkillCategoryDto[] = [];
    @observable isDirty: boolean = false;

    @action
    loadSkill = async () => {
      try {
        const res = await request<IProfileKnowledgeAndSkillsRs>({
          method: 'get',
          url: URIs.get_profiles_skill,
        });
        this.setSkill(res);
        if (res.skills.length > 0 || res.licenses.length > 0 || res.examinations.length > 0) store.profileModel.snbCheckAdditional.skillInfo.completeYn = true;
        else store.profileModel.snbCheckAdditional.skillInfo.completeYn = false;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    setSkill = (skill: IProfileKnowledgeAndSkillsRs) => {
      this.skill = skill;
    }

    @action
    saveSkill = async (data: IProfileKnowledgeAndSkillsSaveRq) => {
      try {
        await request({
          method: 'put',
          url: URIs.put_profiles_skill,
          data,
        });
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    searchLicenses = async (keyword: string) => {
      try {
        this.searchedLicenses = (await request<ILicenseListRs>({
          method: 'get',
          url: URIs.get_license,
          params: { keyword },
        })).licenses;
      } catch (e) {
        console.error(e);
        this.searchedLicenses = [];
      }
    }

    @action
    searchExaminations = async (keyword: string) => {
      try {
        this.searchedExaminations = (await request<IExaminationListRs>({
          method: 'get',
          url: URIs.get_examinations,
          params: { keyword },
        })).examinations;
      } catch (e) {
        console.error(e);
        this.searchedExaminations = [];
      }
    }

    @action
    getExaminationGrades = async (code: number) => request<IExaminationGradeListRs>({
      method: 'get',
      url: URIs.get_examination_code_grade(code),
    });

  @action
  searchSkills = async (keyword:string) => {
    try {
      this.searchedSkills = (await request<ISkillListRs>({
        method: 'get',
        url: URIs.get_skills,
        params: { keyword },
      })).skills;
    } catch (e) {
      console.error(e);
      this.searchedSkills = [];
    }
  }

  @action
  loadRecommendSkills = async (skillCode: number) => {
    try {
      this.recommendSkills = (await request<ISkillRecommendListRs>({
        method: 'get',
        url: URIs.get_skills_recommend(skillCode),
      })).recommendSkills;
    } catch (e) {
      console.error(e);
      this.recommendSkills = [];
    }
  }
}
