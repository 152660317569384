/* eslint-disable camelcase */
import V2ColorPalette from './V2ColorPalette';

export enum V2TextColorsType {
  strong = 'strong',
  default = 'default',
  subtle = 'subtle',
  subtlest = 'subtlest',
  inverse = 'inverse',
  disabled = 'disabled',
  brand = 'brand',
  success = 'success',
  danger = 'danger',
  warning = 'warning',
  information = 'information',
  accent_green_default = 'accent_green_default',
  accent_green_subtle = 'accent_green_subtle',
  accent_green_strong = 'accent_green_strong',
  accent_springgreen_default = 'accent_springgreen_default',
  accent_springgreen_subtle = 'accent_springgreen_subtle',
  accent_springgreen_strong = 'accent_springgreen_strong',
  accent_olivegreen_default = 'accent_olivegreen_default',
  accent_olivegreen_subtle = 'accent_olivegreen_subtle',
  accent_olivegreen_strong = 'accent_olivegreen_strong',
  accent_yellow_default = 'accent_yellow_default',
  accent_yellow_subtle = 'accent_yellow_subtle',
  accent_yellow_strong = 'accent_yellow_strong',
  accent_gold_default = 'accent_gold_default',
  accent_gold_subtle = 'accent_gold_subtle',
  accent_gold_strong = 'accent_gold_strong',
  accent_orange_default = 'accent_orange_default',
  accent_orange_subtle = 'accent_orange_subtle',
  accent_orange_strong = 'accent_orange_strong',
  accent_red_default = 'accent_red_default',
  accent_red_subtle = 'accent_red_subtle',
  accent_red_strong = 'accent_red_strong',
  accent_pink_default = 'accent_pink_default',
  accent_pink_subtle = 'accent_pink_subtle',
  accent_pink_strong = 'accent_pink_strong',
  accent_purple_default = 'accent_purple_default',
  accent_purple_subtle = 'accent_purple_subtle',
  accent_purple_strong = 'accent_purple_strong',
  accent_violet_default = 'accent_violet_default',
  accent_violet_subtle = 'accent_violet_subtle',
  accent_violet_strong = 'accent_violet_strong',
  accent_cobaltblue_default = 'accent_cobaltblue_default',
  accent_cobaltblue_subtle = 'accent_cobaltblue_subtle',
  accent_cobaltblue_strong = 'accent_cobaltblue_strong',
  accent_skyblue_default = 'accent_skyblue_default',
  accent_skyblue_subtle = 'accent_skyblue_subtle',
  accent_skyblue_strong = 'accent_skyblue_strong',
  accent_cyan_default = 'accent_cyan_default',
  accent_cyan_subtle = 'accent_cyan_subtle',
  accent_cyan_strong = 'accent_cyan_strong',
  accent_teal_default = 'accent_teal_default',
  accent_teal_subtle = 'accent_teal_subtle',
  accent_teal_strong = 'accent_teal_strong',
}

export const V2TextColors: Record<V2TextColorsType, V2ColorPalette> = {
  strong: V2ColorPalette.C_GRAY_800,
  default: V2ColorPalette.C_GRAY_700,
  subtle: V2ColorPalette.C_GRAY_500,
  subtlest: V2ColorPalette.C_GRAY_400,
  inverse: V2ColorPalette.C_GRAY_WHITE,
  disabled: V2ColorPalette.C_GRAY_300,
  brand: V2ColorPalette.C_GREEN_600,
  success: V2ColorPalette.C_GREEN_600,
  danger: V2ColorPalette.C_RED_600,
  warning: V2ColorPalette.C_YELLOW_600,
  information: V2ColorPalette.C_SKYBLUE_600,
  accent_green_default: V2ColorPalette.C_GREEN_600,
  accent_green_subtle: V2ColorPalette.C_GREEN_400,
  accent_green_strong: V2ColorPalette.C_GREEN_800,
  accent_springgreen_default: V2ColorPalette.C_SPRINGGREEN_600,
  accent_springgreen_subtle: V2ColorPalette.C_SPRINGGREEN_400,
  accent_springgreen_strong: V2ColorPalette.C_SPRINGGREEN_800,
  accent_olivegreen_default: V2ColorPalette.C_OLIVEGREEN_600,
  accent_olivegreen_subtle: V2ColorPalette.C_OLIVEGREEN_400,
  accent_olivegreen_strong: V2ColorPalette.C_OLIVEGREEN_800,
  accent_yellow_default: V2ColorPalette.C_YELLOW_600,
  accent_yellow_subtle: V2ColorPalette.C_YELLOW_400,
  accent_yellow_strong: V2ColorPalette.C_YELLOW_800,
  accent_gold_default: V2ColorPalette.C_GOLD_600,
  accent_gold_subtle: V2ColorPalette.C_GOLD_400,
  accent_gold_strong: V2ColorPalette.C_GOLD_800,
  accent_orange_default: V2ColorPalette.C_ORANGE_600,
  accent_orange_subtle: V2ColorPalette.C_ORANGE_400,
  accent_orange_strong: V2ColorPalette.C_ORANGE_800,
  accent_red_default: V2ColorPalette.C_RED_600,
  accent_red_subtle: V2ColorPalette.C_RED_400,
  accent_red_strong: V2ColorPalette.C_RED_800,
  accent_pink_default: V2ColorPalette.C_PINK_600,
  accent_pink_subtle: V2ColorPalette.C_PINK_400,
  accent_pink_strong: V2ColorPalette.C_PINK_800,
  accent_purple_default: V2ColorPalette.C_PURPLE_600,
  accent_purple_subtle: V2ColorPalette.C_PURPLE_400,
  accent_purple_strong: V2ColorPalette.C_PURPLE_800,
  accent_violet_default: V2ColorPalette.C_VIOLET_600,
  accent_violet_subtle: V2ColorPalette.C_VIOLET_400,
  accent_violet_strong: V2ColorPalette.C_VIOLET_800,
  accent_cobaltblue_default: V2ColorPalette.C_COBALTBLUE_600,
  accent_cobaltblue_subtle: V2ColorPalette.C_COBALTBLUE_400,
  accent_cobaltblue_strong: V2ColorPalette.C_COBALTBLUE_800,
  accent_skyblue_default: V2ColorPalette.C_SKYBLUE_600,
  accent_skyblue_subtle: V2ColorPalette.C_SKYBLUE_400,
  accent_skyblue_strong: V2ColorPalette.C_SKYBLUE_800,
  accent_cyan_default: V2ColorPalette.C_CYAN_600,
  accent_cyan_subtle: V2ColorPalette.C_CYAN_400,
  accent_cyan_strong: V2ColorPalette.C_CYAN_800,
  accent_teal_default: V2ColorPalette.C_TEAL_600,
  accent_teal_subtle: V2ColorPalette.C_TEAL_400,
  accent_teal_strong: V2ColorPalette.C_TEAL_800,
};
