import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import RoutePaths from 'consts/RoutePaths';
import { CurationTitleType, IAdRecommendJobPostingDto } from 'interfaces/_v2/main/IJobPostings';
import { inject, observer } from 'mobx-react';
import JobsModel from 'models/_v2/JobsModel';
import Main from 'models/_v2/Main';
import { injectStore } from 'models/store';
import useAdsImpressionQuery from 'query/ads/useAdsImpressionQuery';
import useAdsViewsQuery from 'query/ads/useAdsViewsQuery';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { DateFormatYYYYMMDDCCHHMMUnit, getDDayString, str2DateTime } from 'utils/DateUtils';
import ImgDumy from 'assets/_v2/positionList/img_dumy.png';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2BorderColors, V2BorderColorsType } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import V2Badge from '__pc__/components/common/v2Design/badge';
import { V2BadgeOption, V2BadgeType } from '__pc__/constant/v2Design/V2BadgeType';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { useMatchHomeJobPostingsNow } from 'query/match/useMatchQuery';
import MainNoPosition from './MainNoPosition';
import MainAccaJobsCard from './MainAccaJobsCard';

const Frame = styled.div`
  width:100%;
  height: fit-content;
  margin-top: ${SpaceValue.XXL}px;
`;

const TitleFrame = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 16px 16px 16px;
`;

const CardFrame = styled.div`
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0 ${SpaceValue.XS}px;

  ::-webkit-scrollbar {
        display: none;
    }
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  .scroll-area {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: max-content;
    gap: ${SpaceValue.XXL}px;
    padding: 0 ${SpaceValue.XS}px;
  }
`;

const InnerFrame = styled.div`
`;

const MainRecruitFrame = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const MainRecruit = () => {
  const { data: jobPostingNowData, isLoading } = useMatchHomeJobPostingsNow(15);

  return (
    <Frame>
      <TitleFrame>
        <V2Text
          fontStyle={V2FontStyleType.title_3_b}
          color={V2TextColorsType.strong}
        >
          지금 뜨는 공고
        </V2Text>
        <V2Button
          className='btnViewAll'
          size={V2ButtonOption.size.S}
          fontStyle={V2ButtonOption.fontStyle.body_2_sb}
          color={V2ButtonOption.color.subtle}
          aProps={{ href: RoutePaths.jobs }}
        >
          전체 보기
        </V2Button>
      </TitleFrame>
      <CardFrame>
        <>
          { jobPostingNowData && jobPostingNowData.nowJobPostings.length > 0 ? (
            <MainRecruitFrame>
              <div className='scroll-area'>

                { jobPostingNowData && jobPostingNowData.nowJobPostings.length > 0 && jobPostingNowData.nowJobPostings.map((item) => (
                  <InnerFrame>
                    <MainAccaJobsCard item={item} positionList={jobPostingNowData.nowJobPostings} />

                  </InnerFrame>

                ))}
              </div>
            </MainRecruitFrame>
          ) : !isLoading ? <MainNoPosition /> : <></>}
        </>
      </CardFrame>
      <SpacingBlock vertical size={SpaceValue.XXL} />
    </Frame>
  );
};

export default MainRecruit;
