import URIs from 'consts/URIs';
import { IChallengeAd, IChallengeCard } from 'interfaces/_v2/challenge/IChallengeList';
import { action, observable } from 'mobx';
import { request } from 'utils/request';

export default class ChallengeModel {
  @observable challengeAds: IChallengeAd[] =[];
  @observable challenges: IChallengeCard[] =[];

  @action
  loadChallengeAds = async () => {
    try {
      this.challengeAds = (await request<{ challengeAds: IChallengeAd[] }>({
        method: 'get',
        url: URIs.get_match_challenge_ad_list,
      })).challengeAds;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadChallenge = async () => {
    try {
      this.challenges = (await request<{ challenges: IChallengeCard[] }>({
        method: 'get',
        url: URIs.get_match_challenge_list,
      })).challenges;
    } catch (e) {
      console.error(e);
    }
  }
}
