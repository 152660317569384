/* eslint-disable class-methods-use-this */
import URIs from 'consts/URIs';
import ILoginUserInfoRs from 'interfaces/rqrs/ILoginUserInfoRs';
import { request } from 'utils/request';

export default interface UserRepo {

    loadCurrentUser():Promise<ILoginUserInfoRs>;

}
export class RemoteUserRepo implements UserRepo {
  loadCurrentUser() {
    return request<ILoginUserInfoRs>({
      method: 'get',
      url: URIs.get_login_user_info,
    });
  }
}
