import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import store, { injectStore } from 'models/store';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import ProfileLegend from 'components/_v2/profile/ProfileLegend';
import ProfileSNBTab from 'components/_v2/profile/ProfileSNBTab';
import JDFullScreenBaseModal from 'components/_v2/_common/modals/JDFullScreenBaseModal';
import Fonts from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import RoutePaths from 'consts/RoutePaths';
import { IProfileProps } from 'interfaces/_v2/profile/IProfileProps';
import useProfileConditions from 'query/profiles/useProfileConditionsQuery';

const Frame = styled.div`
  width: 100%;
  height: fit-content;
  padding: 0 16px;
`;

const Title = styled.div`
  width: 100%;
  height: fit-content;

  margin-top: 56px;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: ${Fonts.H5_Bold};
    color: ${Colors.JOBDA_BLACK};
  }

  & > div:last-child {
    font: ${Fonts.B2_Medium};
    color: ${Colors.CG_60};

    & > span {
      font: ${Fonts.B2_Medium};
      color: ${Colors.CG_80};
      text-decoration: underline;
    }
  }
`;

const ProfileGraphFrame = styled.div<{ percent: number}>`
  width: 100%;
  height: fit-content;
  padding: 24px;
  border-radius: 8px;
  background-color: ${Colors.G_30};

  & > span {
    display: block;
    margin-bottom: 8px;
    font: ${Fonts.B2_Bold};
    color: ${Colors.G_200};
  }

  & > div {
    position: relative;
    width: 100%;
    height: 6px;
    border-radius: 3px;
    background-color: ${Colors.WHITE_100};
    overflow: hidden;

    & > div {
      position: absolute;
      top: 0;
      left: 0;
      width: ${({ percent }) => percent}%;
      height: 6px;
      border-radius: 4px;
      background-color: ${Colors.G_150};
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${Colors.CG_40};
`;

interface IProfileSNBProps extends IProfileProps{
  isOpen?: boolean;
  onClose?: () => void;
  profileModel?: ProfileModel;
}

const ProfileSNB :FC<IProfileSNBProps> = ({ isOpen, onClose, profileModel = new ProfileModel() }) => {
  const history = useHistory();
  const [profileCompletePercent, setProfileCompletePercent] = useState<number>(0);
  const { data: profileConditionsData } = useProfileConditions(true);

  const scrollTo = (idx: number) => {
    profileModel.setScrollPosition(idx);
    if (onClose) onClose();
  };

  useEffect(() => {
    if (profileConditionsData && profileConditionsData.jobGroups.length > 0 && profileConditionsData.locations.length > 0 && profileConditionsData.salary > 0) { 
      store.profileModel.snbCheckEssential.preferInfo.completeYn = true;
    } else {
      store.profileModel.snbCheckEssential.preferInfo.completeYn = false;
    }
    setProfileCompletePercent(
      [profileModel.snbCheckEssential.basicInfo.completeYn,
        profileModel.snbCheckEssential.preferInfo.completeYn,
        profileModel.snbCheckEssential.eduInfo.completeYn,
        profileModel.snbCheckAdditional.careerInfo.completeYn,
        profileModel.snbCheckAdditional.projectInfo.completeYn,
        profileModel.snbCheckAdditional.skillInfo.completeYn,
        profileModel.snbCheckAdditional.experienceInfo.completeYn,
        profileModel.snbCheckAdditional.prizeInfo.completeYn,
        profileModel.snbCheckAdditional.militaryInfo.completeYn,
        profileModel.snbCheckAdditional.fileInfo.completeYn].filter((isComlete) => isComlete).length * 10,
    );
  }, [profileModel.snbCheckEssential, profileModel.snbCheckAdditional, profileConditionsData]);

  return (
    <JDFullScreenBaseModal isOpen={isOpen} onClose={onClose} style={{ height: 'calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom))', overflowY: 'scroll' }}>
      <Frame>
        <Title>
          <div>
            프로필 항목
            <ProfileLegend />
          </div>
          <SpacingBlock size={8} vertical />
          <div>
            필수정보 입력하면 <span role='button' className='mo_profile_snb_match_link_0831' onClick={() => history.push(RoutePaths.match_apply)}>제안을 받을 수 있어요.</span>
          </div>
        </Title>
        <SpacingBlock size={24} vertical />
        <ProfileGraphFrame percent={profileCompletePercent}>
          <span>{ profileCompletePercent }% 완성</span>
          <div>
            <div />
          </div>
        </ProfileGraphFrame>
        <SpacingBlock size={24} vertical />
        <Divider />
        <SpacingBlock size={8} vertical />
        { profileModel.snbCheckEssential && (
        <>
          <ProfileSNBTab onClick={() => scrollTo(0)} tabInfo={profileModel.snbCheckEssential.basicInfo} />
          <ProfileSNBTab onClick={() => scrollTo(1)} tabInfo={profileModel.snbCheckEssential.preferInfo} />
          <ProfileSNBTab onClick={() => scrollTo(2)} tabInfo={profileModel.snbCheckEssential.eduInfo} />
        </>
        )}
        <SpacingBlock size={8} vertical />
        <Divider />
        <SpacingBlock size={8} vertical />
        { profileModel.snbCheckAdditional && (
        <>
          <ProfileSNBTab onClick={() => scrollTo(3)} tabInfo={profileModel.snbCheckAdditional.careerInfo} />
          <ProfileSNBTab onClick={() => scrollTo(4)} tabInfo={profileModel.snbCheckAdditional.projectInfo} />
          <ProfileSNBTab onClick={() => scrollTo(5)} tabInfo={profileModel.snbCheckAdditional.skillInfo} />
          <ProfileSNBTab onClick={() => scrollTo(6)} tabInfo={profileModel.snbCheckAdditional.experienceInfo} />
          <ProfileSNBTab onClick={() => scrollTo(7)} tabInfo={profileModel.snbCheckAdditional.prizeInfo} />
          <ProfileSNBTab onClick={() => scrollTo(8)} tabInfo={profileModel.snbCheckAdditional.militaryInfo} />
          <ProfileSNBTab onClick={() => scrollTo(9)} tabInfo={profileModel.snbCheckAdditional.fileInfo} />
        </>
        )}
      </Frame>
    </JDFullScreenBaseModal>
  );
};

export default inject(injectStore.profileModel)(observer(ProfileSNB));
