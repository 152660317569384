import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import MainHeader from 'components/_v2/_common/mainHeader/MainHeader';

import shadows from 'consts/style/shadows';
import { NotificationType } from 'interfaces/_v2/notification/INotificationRqRs';
import React, { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { V2BackgroundColors, V2BackgroundColorsType } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { useUserInfo } from 'query/user/useUserInfoQuery';
import MainNotificationCard from './MainNotificationCard';
import MainTutorialIndicator from './MainTutorialIndicator';

const dimOpacityAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
`;
const Dim = styled.div<{backgroundColor:V2BackgroundColorsType}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => V2BackgroundColors[props.backgroundColor]};
  opacity: 0.6;
  animation: ${dimOpacityAnimation} 0.3s ease-out;
  animation-duration: 0.4s;
  z-index: 10005;
`;
const Frame = styled.div`
  >:not(:last-child){
    ${shadows.tnbLine};
  }
`;

const HeaderBtnFrame = styled.div`
    padding:8px 20px 11px 20px;
    height:33px;
    border-bottom: 1px solid ${Colors.CG_40};
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 12px;
    
    >button{
        background: transparent;
    }
    .clear{
        color:${Colors.CG_60};
        font: ${Fonts.B3_Medium};
        line-height: 14px;
      }
    .read{
        color:${Colors.CG_70};
        font: ${Fonts.B3_Medium};
      }
`;
interface IMainTutorialNotification{
    setStep : React.Dispatch<React.SetStateAction<number>>;
}
const MainTutorialNotification:FC<IMainTutorialNotification> = ({ setStep }) => {
  const { data: userInfoData } = useUserInfo();
  const [backgroundColor, setBackgroundColor] = useState<V2BackgroundColorsType>(V2BackgroundColorsType.transparent);
  const [isIndicatorVisible, setIsIndicatorVisible] = useState<boolean>(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setBackgroundColor(V2BackgroundColorsType.dimmer); // 배경색을 회색으로 설정
      setIsIndicatorVisible(true);
    }, 500);

    return () => clearTimeout(timer); // 컴포넌트가 언마운트되면 타이머를 정리
  }, []);
  const handleNextClick = () => {
    setStep(2);
  };
  return (
    <>
      <Dim backgroundColor={backgroundColor} />
      <MainHeader
        title='알람'
        gnb={false}
        search={false}
        close
        alarm={false}
        underShadow={false}
        onClose={() => {}}
      />
      <HeaderBtnFrame>
        <button
          className='clear'
        >모두 지우기
        </button>
        <button
          className='read'
        >모두 읽음
        </button>
      </HeaderBtnFrame>
      <Frame>
        <div role='button' onClick={() => setStep(2)}>
          <MainNotificationCard />
        </div>
        <MainTutorialIndicator titleText='카카오톡 및 잡다 알림 확인하기' descText={`${userInfoData?.name}님에게 딱 맞는 매칭 제안이 도착했어요!\n카카오톡 알림과 잡다 알림을 확인해 볼까요? \n(카카오톡 알림은 최초 한 번만 발송됩니다.)`} stepText='01' buttonText='다음' position='bottom' onClickNext={handleNextClick} visible={isIndicatorVisible} />

      </Frame>
    </>
  );
};
export default MainTutorialNotification;
