/* eslint-disable import/prefer-default-export */
export enum IconName {
    acca = 'acca',
    accaHome = 'acca-home',
    add = 'add',
    alert = 'alert',
    alertFiled = 'alert-filed',
    arrowBottom = 'arrow-bottom',
    arrowLeft = 'arrow-left',
    arrowRight = 'arrow-right',
    arrowTop = 'arrow-top',
    arrowUpward = 'arrow-upward',
    arrowDownward = 'arrow-downward',
    assignmentInd = 'assignment-ind',
    bell = 'bell',
    bellOff = 'bell-off',
    bookGreen = 'book-green',
    bookmarkFilled = 'bookmark-filled',
    bookmark = 'bookmark',
    bookmarkShadowFilled = 'bookmark-shadow-filled',
    bookmarkShadow = 'bookmark-shadow',
    cafe = 'cafe',
    calender = 'calender',
    camera = 'camera',
    camping = 'camping',
    car = 'car',
    chart = 'chart',
    chatYellow = 'chat-yellow',
    checkCircle = 'check-circle',
    checkCircleFilled = 'check-circle-filled',
    check = 'check',
    checkbox = 'checkbox',
    checkboxEmpty = 'checkbox-empty',
    child = 'child',
    close = 'close',
    coding = 'coding',
    comfortableLife = 'comfortable-life',
    contents = 'contents',
    corporateFare = 'corporate-fare',
    delete = 'delete',
    design = 'design',
    desktopAccaGreen = 'desktop-acca-green',
    desktop = 'desktop',
    diamond = 'diamond',
    diversity = 'diversity',
    downSFilled = 'down-s-filled',
    enter = 'enter',
    error = 'error',
    errorFilled = 'error-filled',
    eyeClose = 'eye-close',
    eyeOpen = 'eye-open',
    fairBalance = 'fair-balance',
    fileAdd = 'file-add',
    filter = 'filter',
    financialSupport = 'financial-support',
    folder = 'folder',
    gift = 'gift',
    guideAccaGreen = 'guide-acca-green',
    guide = 'guide',
    heart = 'heart',
    heartFilled = 'heart-filled',
    home = 'home',
    idCard = 'id-card',
    idea = 'idea',
    infoUser = 'info-user',
    information = 'information',
    ipin = 'ipin',
    laptopAccaBlue = 'laptop-acca-blue',
    largeArrowBottom = 'large-arrow-bottom',
    largeArrowLeft = 'large-arrow-left',
    largeArrowRight = 'large-arrow-right',
    largeArrowTop = 'large-arrow-top',
    linkLine = 'link-line',
    listView = 'list-view',
    locationAway = 'location-away',
    luggage = 'luggage',
    marketing = 'marketing',
    mall = 'mall',
    meal = 'meal',
    medical = 'medical',
    message = 'message',
    military = 'military',
    money = 'money',
    moreHorizon = 'more-horizon',
    moreVert = 'more-vert',
    mouse = 'mouse',
    move = 'move',
    openInNew = 'open-in-new',
    pageUserChoice = 'page-user-choice',
    palette = 'palette',
    people = 'people',
    pin = 'pin',
    readBook = 'read-book',
    realEstate = 'real-estate',
    removeFilled = 'remove-filled',
    remove = 'remove',
    reset = 'reset',
    resume = 'resume',
    resumeDoneGreen = 'resume-done-green',
    resumeYellow = 'resume-yellow',
    robot = 'robot',
    rocketLaunch = 'rocket-launch',
    savingMoney = 'saving-money',
    score = 'score',
    searchAccaBlue = 'search-acca-blue',
    search = 'search',
    share = 'share',
    smartphone = 'smartphone',
    starFilled = 'star-filled',
    star = 'star',
    stdioBlue = 'stdio-blue',
    swapVert = 'swap-vert',
    testAcca = 'test-acca',
    testCodingBlue = 'test-coding-blue',
    textWrite = 'text-write',
    thumbsUp = 'thumbs-up',
    time = 'time',
    top20 = 'top-20',
    university = 'university',
    upSFilled = 'up-s-filled',
    upload = 'upload',
    userFolder = 'user-folder',
    user = 'user',
    verified = 'verified',
    verifiedFilled = 'verified-filled',
    visibilityOff = 'visibility-off',
    work = 'work',
    business = 'business',
    businessFilled = 'business-filled',
    invitationUser = 'invitation-user',
    matchingPersonGreen = 'matching_person_green',
    militaryTech = 'military-tech',
    folderOpen = 'folder-open',
    projectLight = 'project-light',
    openNew = 'open_new',
    resumeSeparationBlue = 'resume_separation_blue',
    developer = 'developer',
    download = 'download',
    output = 'output',
    sendLight = 'send-light',
    sendLightFilled = 'send-light-filled',
    peopleBlue = 'people-blue',
    goodWorkers = 'good-workers',
    personLove = 'person-love',
    threeStar = 'three-star',
    clock = 'clock',
    bagClock = 'bag-clock',
    doNotCopy = 'do-not-copy',
    calendarCheck = 'calendar-check',
    laurelWreathStar = 'laurel-wreath-star',
    easyCommunication = 'easy-communication',
    redHeart = 'red-heart',
    polyshape = 'polyshape',
    fileDoneGreen = 'file-done-green',
    testBlue = 'test-blue',
    chatBlue = 'chat-blue',
    matchingYellow = 'matching-yellow',
    matching = 'matching',
    matchLight = 'match-light',
    lockYellow = 'lock-yellow',
    pageBlue = 'page-blue',
    calenderFill = 'calender-fill',
    handshake = 'handshake',
    giftRed = 'gift-red'
  }
