import { IconName } from '__designkit__/icon/IconNameType';
import { V2FillColorsType } from '__pc__/constant/v2Design/Colors/V2FillColors';
import V2ColorPalette from './Colors/V2ColorPalette';
import { V2TextColorsType } from './Colors/V2TextColors';
import { V2FontStyleType } from './V2FontStyle';

export enum V2ButtonType {
  Fill = 'Fill',
  OutLine = 'OutLine',
  Text = 'Text',
}

export enum V2ButtonSizeType {
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
}

export enum V2ButtonIconPositionType {
  left = 'left',
  right = 'right',
}

export const V2ButtonOption = {
  type: V2ButtonType,
  size: V2ButtonSizeType,
  fillType: V2FillColorsType,
  fontStyle: V2FontStyleType,
  color: V2TextColorsType,
  iconName: IconName,
  iconPosition: V2ButtonIconPositionType,
  iconColor: V2ColorPalette,
};
