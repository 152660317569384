import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2FillColorsType } from '__pc__/constant/v2Design/Colors/V2FillColors';
import { RadiusType, RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import Footer from 'components/_v2/_common/Footer';
import { MainMetaTag } from 'components/_v2/_common/meta/MetaTag';
import MainAccaRecruit from 'components/_v2/main/MainAccaRecruit';
import MainAd from 'components/_v2/main/MainAd';
import MainContents from 'components/_v2/main/MainContents';
import MainMatching from 'components/_v2/main/MainMatching';
import MainMatchingTutorial from 'components/_v2/main/MainMatchingTutorial';
import MainPersonalPosition from 'components/_v2/main/MainPersonalPosition';
import MainPopularPosition from 'components/_v2/main/MainPopularPosition';
import MainRecruit from 'components/_v2/main/MainRecruit';
import MainRolling from 'components/_v2/main/MainRolling';
import { FC, useEffect, useState } from 'react';
import MobileStore from 'store/mobileStore';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import LottieGift from 'assets/lottie/lottie_snb_gift.json';
import MainMatchingQuestModal from 'components/_v2/main/MainMatchingQuestModal';
import MatchingBanner from 'components/_v2/myProfile/MatchingBanner';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import MainMatchingCompany from 'components/_v2/main/MainMatchingCompany';
import MainMatchingStroy from 'components/_v2/main/MainMatchingStroy';
import Login from 'models/Login';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import { useHistory, useLocation } from 'react-router';
import RoutePaths from 'consts/RoutePaths';
import CommonUtils from 'utils/CommonUtils';

const Frame = styled.div`
  width:100%;
  .styled-snb{
    position: fixed;
    bottom: calc(81px + env(safe-area-inset-bottom));
    right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: ${RadiusValue.CIRCULAR}px !important;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06), 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
    z-index:1000;
    .lottie-gift{
      width: 48px;
      height: 48px;
    }
  }

  .banner {
    padding: ${SpaceValue.M}px ${SpaceValue.L}px;
  }
`;

export const MainTitleFrame = styled.div`
    display: flex;
    flex-direction: column;
    margin:32px 0 24px 0px;
    .title{
        font: ${Fonts.H5_Bold};
        color:${colors.JOBDA_BLACK};
        width: 288px;
        word-break: break-all;
    }
    .subtitle-frame{
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        .subtitle-text{
            font: ${Fonts.B3_Medium};
            color:${colors.CG_70};
        }
        .more-text{
        display: flex;
        align-items: center;
        font: ${Fonts.B3_Medium};
        color:${colors.CG_70};
        text-decoration: underline;
    }
    }
`;
interface IMainProps{
  login?:Login
}
const Main:FC<IMainProps> = ({ login = new Login() }) => {
  const [isOpenSNB, setIsOpenSNB] = useState<boolean>(false);
  const [triggerTutorial, setTriggerTutorial] = useState<boolean>(false);
  const { search } = useLocation();
  const { tutorial } = CommonUtils.parseQuery(search);

  const history = useHistory();
  const onClickSNB = () => {
    if (login.userInfo) {
      setIsOpenSNB(true);
    } else {
      history.push(RoutePaths.login);
    }
  };

  useEffect(() => {
    if (tutorial === 'open') {
      setTriggerTutorial(true);
    }
  }, [tutorial]);
  return (
    <Frame>
      <MainMetaTag />
      <MainRolling />
      <MainAd />
      <MainMatching />
      <MainMatchingTutorial triggerTutorialModal={triggerTutorial} />
      <MainPersonalPosition />
      <MainMatchingCompany />
      <MainMatchingStroy />
      <div className='banner'>
        <MatchingBanner />
      </div>
      {/* 5.3.0에서 MainCuration 제거  */}
      {/* <MainCuration /> */}
      <MainPopularPosition />
      <MainRecruit />
      <MainAccaRecruit />
      {/* <MainContents /> */}
      <V2Button className='styled-snb' fill={V2FillColorsType.brand_strong_default} buttonProps={{ onClick: onClickSNB }}>
        <Lottie animationData={LottieGift} className='lottie-gift' />
      </V2Button>
      <MainMatchingQuestModal isOpen={isOpenSNB} onClose={() => setIsOpenSNB(false)} afterLogic={() => setTriggerTutorial(true)} />
      {!MobileStore.isMobile && <Footer isNavi={false} corpInfoFoled={false} />}
    </Frame>
  );
};

export default inject(injectStore.login)(observer(Main));
