/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';

import classnames from 'classnames/bind';
import { V2ButtonIconPositionType, V2ButtonSizeType, V2ButtonType } from '__pc__/constant/v2Design/V2ButtonType';
import { V2FontStyle, V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { V2TextColors, V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { IconName } from 'interfaces/_v2/_common/iconType';
import { V2FillColorsType } from '__pc__/constant/v2Design/Colors/V2FillColors';
import { V2IconColorsType } from '__pc__/constant/v2Design/Colors/V2IconColors';
import styles from './index.module.scss';
import V2Icon from '../icon';
import { getButtonBorderStyle, getButtonIconSize, getButtonRadiusStyle, getButtonSpaceStyle } from './styleUtil';

const cx = classnames.bind(styles);

interface IButtonProps {
  children?: React.ReactNode;
  className?: string;
  type?: V2ButtonType;
  size?: V2ButtonSizeType;
  fill?: V2FillColorsType;
  fontStyle?: V2FontStyleType;
  color?: V2TextColorsType;
  iconName?: IconName;
  iconPosition?: V2ButtonIconPositionType;
  iconColor?: V2IconColorsType;
  isLoading?: boolean;
  aProps?: React.AnchorHTMLAttributes<HTMLAnchorElement>;
  buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  styles?: React.CSSProperties;
}

/**
 * @param {string} [className] - 추가 클래스를 설정.
 * @param {V2ButtonType} [type=V2ButtonType.Fill] - 버튼 스타일 타입.
 * @param {V2ButtonSizeType} [size=V2ButtonSizeType.L] - 버튼 크기 타입.
 * @param {object} [aProps] - Link태그 속성.
 * @param {object} [buttonProps] - button태그 속성.
 * @param {V2FontStyleType} [fontStyle=V2FontStyleType.body_1_b] - 폰트 스타일 타입.
 * @param {V2TextColorsType} [color=V2TextColorsType.default] - 폰트 색상 타입.
 * @param {string} [iconName] - 버튼에 들어갈 아이콘 이름.
 * @param {V2ButtonIconPositionType} [iconPosition] - 아이콘 위치.
 * @param {string} [iconColor] - 아이콘 색상.
 * @param {string} [fill] - 버튼 색상.
 * @param {boolean} [isLoading] - 버튼 로딩 여부.
 * @param {React.CSSProperties} [styles] - 추가 인라인 스타일.
 */

const V2Button = ({
  children,
  className,
  type = V2ButtonType.Fill,
  size = V2ButtonSizeType.L,
  aProps,
  buttonProps,
  fontStyle = V2FontStyleType.body_1_b,
  color = V2TextColorsType.default,
  iconName,
  iconPosition,
  iconColor,
  fill,
  isLoading,
  styles,
}: IButtonProps) => {
  const style = {
    color: V2TextColors[color],
    ...getButtonRadiusStyle(size),
    ...getButtonSpaceStyle(size, iconPosition),
    ...getButtonBorderStyle(type, fill),
    ...styles,
  };

  if (aProps) {
    return (
      <a className={cx('button', className, fill, type)} {...aProps} style={style as any}>
        <div className={cx('inner')} style={V2FontStyle[fontStyle] as any}>
          {iconName && iconPosition === 'left' && (
            <V2Icon name={iconName} size={getButtonIconSize(size)} fill={iconColor} />
          )}
          {children}
          {iconName && iconPosition === 'right' && (
            <V2Icon name={iconName} size={getButtonIconSize(size)} fill={iconColor} />
          )}
        </div>
      </a>
    );
  }

  return (
    <button
      className={cx('button', className, type, fill, { isLoading })}
      {...buttonProps}
      disabled={buttonProps?.disabled || isLoading}
      type={buttonProps?.type}
      style={style as any}
    >
      <div className={cx('inner')}>
        {iconName && iconPosition === 'left' && (
        <V2Icon name={iconName} size={getButtonIconSize(size)} fill={iconColor} disabled={buttonProps?.disabled} />

        )}
        {isLoading ? <span className={cx('spinner')} style={{ borderColor: V2TextColors[color] }} />
          : (
            <div style={V2FontStyle[fontStyle] as any}>
              {children}
            </div>
          )}
        {iconName && iconPosition === 'right' && (
        <V2Icon name={iconName} size={getButtonIconSize(size)} fill={iconColor} disabled={buttonProps?.disabled} />

        )}
      </div>
    </button>
  );
};

export default V2Button;
