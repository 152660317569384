import { IconName } from '__designkit__/icon/IconNameType';
import { V2IconColorsType } from './Colors/V2IconColors';

export enum V2IconSizeType {
  XL = 'XL',
  L = 'L',
  M = 'M',
  S = 'S',
  XS = 'XS',
}

export const V2IconSizeValue: Record<V2IconSizeType, number> = {
  XL: 48,
  L: 40,
  M: 32,
  S: 24,
  XS: 20,
};

export const V2IconOption = {
  name: IconName,
  size: V2IconSizeType,
  fill: V2IconColorsType,
};
