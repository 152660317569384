import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';
import CompanyReportCompetency from 'components/_v2/company/CompanyReportCompetency';
import CompanyReportFooter from 'components/_v2/company/CompanyReportFooter';
import CompanyReportInformation from 'components/_v2/company/CompanyReportInformation';
import CompanyReportMap from 'components/_v2/company/CompanyReportMap';
import CompanyReportSpecialMessage from 'components/_v2/company/CompanyReportSpecialMessage';
import CompanyReportSuggestion from 'components/_v2/company/CompanyReportSuggestion';
import ProfilePreviewModal from 'components/_v2/profile/preview/ProfilePreviewModal';
import { Divider12Grey } from 'components/divider/Divider';
import MatchAcceptModal from 'components/jobMatch/dashboard/MatchAcceptModal';
import MatchOfferDeniedModal from 'components/jobMatch/dashboard/MatchOfferDeniedModal';
import ErrorCode from 'consts/ErrorCodes';
import SupportMail from 'consts/_v2/GlobalText';
import useToast from 'hooks/useToast';
import { IMatchingReportViewLogSaveRq, MessageAccessPath } from 'interfaces/_v2/log/ILogRqRs';
import { MessageOptionType } from 'interfaces/_v2/matchingReport/IMatchingReportRs';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Login from 'models/Login';
import MatchMessageStore from 'models/MatchMessageStore';
import CompanyModel from 'models/_v2/CompanyModel';
import MatchReportModel from 'models/_v2/MatchReportModel';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import { injectStore } from 'models/store';
import { useSaveLogMatchReportView } from 'query/useLogQuery';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import CommonUtils from 'utils/CommonUtils';
import { determineMediumType, determinesAccessPath } from 'utils/TypeCheckUtils';

const BottomFrame = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 61px;
  padding: 10px 16px calc(env(safe-area-inset-bottom) + 10px) 16px;
  text-align: center;
  background: rgba(255, 255, 255, 0.95);  
  z-index: 1000;
  position:fixed;
  border-top: 1px solid ${Colors.CG_40};
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.25);
  .timer-frame{
    display: flex;
    flex-direction: column;
    .title-text{
        font:${Fonts.B4_Bold};
        color:${Colors.JOBDA_BLACK};
        text-align: start;
        margin-bottom: 4px;
    }
    .d-day-frame{
        display: flex;
        gap: 4px;
        .d-day-number{
            display: flex;
            width: 18px;
            height: 24px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            background: linear-gradient(180deg, #4BD667 0%, #40CCC6 100%);
            .number-text{
                color:${Colors.WHITE_100};
                font:${Fonts.B3_Bold};
            }
            }
        .d-day-text{
            font:${Fonts.B3_Bold};
            color:${Colors.JOBDA_BLACK};
            align-self: center;
            margin-right: 8px;
        }
    }
  }
  .button-frame{
    display: flex;
    gap: 4px;
    .decline-button{
        display: flex;
        height: 40px;
        padding: 9px 20px;
        align-items: center;
        justify-content: center;
        border-radius:30px;
        border: 1px solid ${Colors.CG_50};
        color:${Colors.CG_70};
        font:${Fonts.B3_Bold};
    }
    .accept-button{
        display: flex;
        height: 40px;
        padding: 9px 20px;
        align-items: center;
        justify-content: center;
        border-radius:30px;
        background: linear-gradient(90deg, #4BD667 0%, #40CCC6 100%);
        color:${Colors.WHITE_100};
        font:${Fonts.B3_Bold};
    }
  }
`;

interface ICompanyDetailMatchingReport{
    companyModel?: CompanyModel
    context?:Context
    matchReportModel?:MatchReportModel;
    matchMessageStore?:MatchMessageStore
    profileModel?:ProfileModel
    login?:Login
    setCurrentTab?: (tab: number) => void;
}

const CompanyDetailMatchingReport:FC<ICompanyDetailMatchingReport> = ({ matchMessageStore = new MatchMessageStore(), login = new Login(), companyModel = new CompanyModel(), profileModel = new ProfileModel(), context = new Context(), matchReportModel = new MatchReportModel(), setCurrentTab }) => {
  const [isAcceptMatchOfferModalVisible, setAcceptMatchOfferModalVisible] = useState(false);
  const [isDeniedMatchOfferModalVisible, setDeniedMatchOfferModalVisible] = useState(false);
  const [selectedAcceptOptionSn, setSelectedAcceptOptionSn] = useState<number | null>(null);
  const [selectedRefuseOptionSn, setSelectedRefuseOptionSn] = useState<number | null>(null);
  const [logged, setLogged] = useState(false);
  const { setToastObject } = useToast();
  const history = useHistory();
  const { search } = useLocation();
  const { accessPath, positionSn } = CommonUtils.parseQuery(search);

  // 성공 후 쿼리 제거 함수
  const removeAccessPathQuery = () => {
    const queryParams = new URLSearchParams(search);
    queryParams.delete('accessPath'); // accessPath 쿼리 제거
    const newSearch = queryParams.toString();
    history.replace({
      pathname: window.location.pathname,
      search: newSearch ? `?${newSearch}` : '',
    });
  };

  const saveSuccesLog = () => {
    setLogged(true);
  };
  const { mutate: saveLog } = useSaveLogMatchReportView(saveSuccesLog);
  const [time, setTime] = useState(0); // 초 단위로 설정
  useEffect(() => {
    return () => {
      removeAccessPathQuery();
    };
  }, []);
  useEffect(() => {
    if (matchReportModel.currentReportPosition && matchReportModel.currentReportPosition.responseType !== 'ACCEPTED') {
      context.hideBottomNav = true;
    }
    return () => {
      context.hideBottomNav = false;
    };
  }, [companyModel.companySn, matchReportModel.currentReportPosition]);

  useEffect(() => {
    const startLog = async () => {
      if ((positionSn || matchReportModel.currentReportPosition) && !logged) {
        const rq:IMatchingReportViewLogSaveRq = {
          positionSn: positionSn || matchReportModel.currentReportPosition?.sn,
          userId: login.userInfo?.id || '',
          mediumType: determineMediumType(),
          matchingReportAccessPath: accessPath ? determinesAccessPath(determineMediumType(), accessPath) || MessageAccessPath.NONE : MessageAccessPath.COMPANY_CHANNEL,
        };
        await saveLog(rq);
      }
    };
    startLog();
  }, [accessPath, positionSn, matchReportModel.currentReportPosition, logged]);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const initializeTimer = () => {
      if (matchReportModel.currentReportPosition) {
        const targetDate = new Date(matchReportModel.currentReportPosition.decisionDueDateTime);
        const currentTime = new Date();
        const timeDiff = targetDate.getTime() - currentTime.getTime();
        setTime(timeDiff / 1000);

        const timer = setInterval(() => {
          setTime((prevTime) => prevTime - 1);
        }, 1000);

        return () => {
          clearInterval(timer);
        };
      }
    };

    initializeTimer();

    return () => {
      // 정리(clean-up)가 필요한 경우 추가
    };
  }, [matchReportModel.matchReportCompaniesPositions]);

  const days = Math.floor(time / (24 * 60 * 60))
    .toString()
    .padStart(2, '0');
  const hours = Math.floor((time % (24 * 60 * 60)) / (60 * 60))
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((time % (60 * 60)) / 60)
    .toString()
    .padStart(2, '0');

  const handleAccept = async () => {
    const acceptOption = matchReportModel.currentReportPosition?.options.find((option) => option.optionCommand === MessageOptionType.ACCEPT);
    if (acceptOption) {
      setSelectedAcceptOptionSn(acceptOption.optionSn);
    }
    setAcceptMatchOfferModalVisible(true);
  };
  const handleReject = async () => {
    const refuseOption = matchReportModel.currentReportPosition?.options.find((option) => option.optionCommand === MessageOptionType.REFUSE);
    if (refuseOption) {
      setSelectedRefuseOptionSn(refuseOption.optionSn);
    }
    removeAccessPathQuery();
    setDeniedMatchOfferModalVisible(true);
  };

  const acceptMatching = async () => {
    try {
      await matchReportModel.acceptMatch(matchReportModel.currentReportPosition?.sn || -1, selectedAcceptOptionSn || -1);
      setToastObject({ isOpen: true, type: TOAST_TYPE.SUCCESS, message: '제안을 수락하셨습니다.', duration: 1500 });
      await matchReportModel.loadMatchReportCompaniesPositions(companyModel.companySn || -1);
      matchReportModel.changedPosition = true;
    } catch (e: any) {
      switch (e.response?.data?.errorCode) {
        case ErrorCode.B901:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '종료된 포지션입니다.', duration: 1500 });
          break;
        case ErrorCode.B902:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '삭제된 포지션입니다.', duration: 1500 });
          break;
        case ErrorCode.B903:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '매칭 제안 이력이 없습니다.', duration: 1500 });
          break;
        case ErrorCode.B904:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '이미 수락한 제안입니다.', duration: 1500 });
          break;
        case ErrorCode.B905:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '존재하지 않는 선택지입니다.', duration: 1500 });
          break;
        case ErrorCode.B906:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '응답 마감 기한이 지났습니다.', duration: 1500 });
          break;
        case ErrorCode.B908:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '현재 서비스가 원활하지 않습니다. 잡다 헬프데스크로 문의 해 주세요.', subMessage: `잡다 헬프데스크 : ${SupportMail}`, duration: 1500 });
          break;
        default:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '지원에 실패했습니다. 다시 시도해 주세요.', duration: 1500 });
      }
    }
    setAcceptMatchOfferModalVisible(false);
  };

  const handleDenialModalClose = async () => {
    setDeniedMatchOfferModalVisible(false);
    matchReportModel.changedPosition = true;
  };
  return (
    <>
      <CompanyReportSuggestion />
      <Divider12Grey />
      {
        matchReportModel.matchReportPositionsDetail && matchReportModel.matchReportPositionsDetail.competencyAnalysis.strengths.length > 0 && (
        <>
          <CompanyReportCompetency />
          <Divider12Grey />
          {
            matchReportModel.matchReportCompanyInformation && matchReportModel.matchReportCompanyInformation.specialMessage && <CompanyReportSpecialMessage />
          }

          <Divider12Grey />
        </>
        )
      }
      <CompanyReportInformation setCurrentTab={setCurrentTab} />
      <Divider12Grey />
      <CompanyReportMap location={matchReportModel.matchReportPositionsDetail?.addressInfo.address} />
      <Divider12Grey />
      <CompanyReportFooter />
      <BottomFrame>
        <div className='timer-frame'>
          <div className='title-text'>매칭 수락 마감까지</div>
          <div className='d-day-frame'>
            {days.split('').map((digit, index) => (
              <div key={`d-day-${index + digit}`} className='d-day-number'>
                <div className='number-text'>
                  {digit}
                </div>
              </div>
            ))}
            <div className='d-day-text'>일</div>
            {hours.split('').map((digit, index) => (
              <div key={`d-day-${index + digit}`} className='d-day-number'>
                <div className='number-text'>
                  {digit}
                </div>
              </div>
            ))}
            <div className='d-day-text'>시</div>
            {minutes.split('').map((digit, index) => (
              <div key={`d-day-${index + digit}`} className='d-day-number'>
                <div className='number-text'>
                  {digit}
                </div>
              </div>
            ))}
            <div className='d-day-text'>분</div>
          </div>
        </div>
        <div className='button-frame'>
          <div onClick={() => handleReject()} role='button' className='decline-button'>거절</div>
          <div onClick={() => handleAccept()} role='button' className='accept-button'>수락</div>
        </div>
      </BottomFrame>
      <MatchAcceptModal
        isOpen={isAcceptMatchOfferModalVisible}
        onClickClose={() => setAcceptMatchOfferModalVisible(false)}
        onClickApply={acceptMatching}
        sendDateTime={String(new Date())}
      />
      {isDeniedMatchOfferModalVisible
          && (
          <MatchOfferDeniedModal
            isOpen={isDeniedMatchOfferModalVisible}
            onClickClose={handleDenialModalClose}
            positionSn={matchReportModel.currentReportPosition ? matchReportModel.currentReportPosition.sn : -1}
            optionSn={selectedRefuseOptionSn || -1}
          />
          )}
    </>
  );
};
export default inject(injectStore.login, injectStore.companyModel, injectStore.matchMessageStore, injectStore.context, injectStore.matchReportModel, injectStore.profileModel)(observer(CompanyDetailMatchingReport));
