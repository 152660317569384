import URIs from 'consts/URIs';
import type { ICheckVerificationCodeRq, IRequestVerificationCodeRq } from 'interfaces/_v2/profile/IBasicInfoRqRs';
import type { ISuccessOrNotRs } from 'interfaces/commonRqRs';
import { request } from 'utils/request';

const SmsAPI = {
  // 인증번호 발송 (회원용)
  sendVerificationSmsToUser: async (data: IRequestVerificationCodeRq) => {
    try {
      const res = await request<ISuccessOrNotRs>({
        method: 'POST',
        url: URIs.post_verification_user_sms_send,
        data,
      });
      return res.success;
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
  // 인증번호 확인 (회원용)
  chackVerificationSmsToUser: async (data: ICheckVerificationCodeRq) => {
    try {
      const res = await request<ISuccessOrNotRs>({
        method: 'POST',
        url: URIs.post_verification_user_sms_check,
        data,
      });
      return res.success;
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
};

export default SmsAPI;
