import { FC } from 'react';
import classnames from 'classnames/bind';
import { V2BadgeSizeType, V2BadgeType } from '__pc__/constant/v2Design/V2BadgeType';
import { V2FontStyle, V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { V2TextColors, V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { V2BackgroundColors, V2BackgroundColorsType } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { V2BorderColorsType } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import styles from './index.module.scss';
import { getBadgeRadiusStyle, getBadgeBorderStyle, getBadgeSpaceStyle } from './styleUtil';

const cx = classnames.bind(styles);

interface Props {
  type?: V2BadgeType;
  size?: V2BadgeSizeType;
  fontStyle?: V2FontStyleType;
  color?: V2TextColorsType;
  backgroundColor?: V2BackgroundColorsType;
  borderColor?: V2BorderColorsType;
  children?: React.ReactNode;
}

const V2Badge: FC<Props> = ({
  type = V2BadgeType.Fill,
  size = V2BadgeSizeType.L,
  fontStyle = V2FontStyleType.body_1_b,
  color = V2TextColorsType.default,
  backgroundColor,
  borderColor,
  children,
}) => {
  const style = {
    ...V2FontStyle[fontStyle],
    color: V2TextColors[color],
    backgroundColor: backgroundColor ? V2BackgroundColors[backgroundColor] : undefined,
    ...getBadgeBorderStyle(type, borderColor),
    ...getBadgeRadiusStyle(size),
    ...getBadgeSpaceStyle(size),
    ...styles,
  };

  return (
    <div className={cx('badge')} style={style as any}>
      {children}
    </div>
  );
};

export default V2Badge;
