import { PushNotificationSchema, PushNotifications } from '@capacitor/push-notifications';
import RoutePaths from 'consts/RoutePaths';
import URIs from 'consts/URIs';
import { MediumEnum } from 'consts/_v2/notification/NotificationPushType';
import { IPushNotificationSendLogSaveRq, NotificationLogType } from 'interfaces/_v2/log/ILogRqRs';
import { INotificationLogRq } from 'interfaces/_v2/notification/INotificationLogRq';
import { NotificationType } from 'interfaces/_v2/notification/INotificationRqRs';
import PushUtils from 'utils/PushUtils';
import { request } from 'utils/request';

const BASIC_TOPIC = 'push_agreed';

const FCM_TOKEN: string = '';

export const notificationData = { value: null as INotificationLogRq | null };

const register = async () => {
  try {
    await PushNotifications.addListener('registration', (token) => {
      console.info('Registration token: ', token.value);
      PushUtils.FCM_TOKEN = token.value;
    });

    await PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error);
    });

    // TODO: 서버에서 알림 받았을 때 수행. (프론트에서 Notification 작업해서 수행하고 싶다면 local-notifications 사용)
    await PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      console.log('Push notification received: ', notification);
    });

    // 사용자가 알림을 탭했을 때 특정 동작 수행.
    await PushNotifications.addListener('pushNotificationActionPerformed', async (action) => {
      console.log('Push notification action performed', action.actionId, action.inputValue);

      const { data } = action.notification;
      let url = '';
      let dataRq: INotificationLogRq;
      let positionSn = 0;
      let logDataRq:IPushNotificationSendLogSaveRq;
      const matchingTalkMessageSn = -1;
      let channelId = '';
      if (data && data.type) {
        dataRq = {
          notificationId: data.notificationId,
          notificationType: data.type,
          mediumType: MediumEnum.PUSH,
        };
        logDataRq = {
          matchingTalkMessageSn: data.matchingTalkMessageSn,
          notificationLogType: NotificationLogType.MATCH_OFFER,
        };
        switch (data.type) {
          case NotificationType.MATCH_INTERVIEW_OFFER:
            url = `/match/apply/status?tab=MATCH_INTERVIEW_OFFER`;
            break;
          // case NotificationType.RECOMMEND:
          //   url = `/match/apply/status?tab=RECOMMEND`;
          //   break;
          case NotificationType.MATCH_OFFER:
            positionSn = action.notification.data.positionSn;
            url = `/match/apply/status?tab=OFFER&sn=${positionSn}&mediumType=PUSH`;
            break;
          case NotificationType.INSIGHT_RESUME_SAVED:
            url = data.chatUrl;
            break;
          case NotificationType.CHAT:
            channelId = action.notification.data.channelId;
            url = `${RoutePaths.chatChannel}?channelId=${channelId}`;
            break;
          default:
            break;
        }
        notificationData.value = dataRq;
        try {
          await request({
            method: 'post',
            url: URIs.post_notification_log,
            data: dataRq,
          });
          await request({
            method: 'post',
            url: URIs.post_v2_notification_logs_send_push,
            data: logDataRq,
          });
          console.log('Push notification click');
          window.location.href = url;
        } catch (error) {
          console.error('test error', error);
        }
      }
    });

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register();
    // FCM.subscribeTo({ topic: BASIC_TOPIC });
  } catch (e) {
    console.error(e);
  }
};

export { BASIC_TOPIC, FCM_TOKEN, register };
export default ({ BASIC_TOPIC, FCM_TOKEN, register });
