import { action, observable } from 'mobx';
import INudgeInfoRs, { INudgeInfoDto } from 'interfaces/rqrs/INudgeInfoRs';
import { request } from 'utils/request';
import URIs from 'consts/URIs';
import { NudgeType } from 'consts/NudgeType';

interface INudgeInfoUserDto extends INudgeInfoDto {
  closed: boolean;
}

class NudgeModel {
  @observable nudgeInfoMap: Map<string, INudgeInfoUserDto> = new Map<string, INudgeInfoUserDto>();
  @observable activeNudgeInfo: INudgeInfoUserDto | undefined = undefined;
  @observable isLoaded: boolean = false;

  @action
  loadNudgeInfoList = async () => {
    const nudgeInfoList = await request<INudgeInfoRs>({
      method: 'get',
      url: URIs.get_nudges,
    });
    const tempNudgeInfoMap: Map<string, INudgeInfoUserDto> = new Map<string, INudgeInfoUserDto>();
    nudgeInfoList?.nudgeInfoList?.forEach((nudgeInfo) => {
      if (nudgeInfo.type !== NudgeType.MOBILE_BOTTOM) return;
      if (nudgeInfo.hasPath === false) {
        tempNudgeInfoMap.set('common', { ...nudgeInfo, closed: false });
      } else {
        nudgeInfo.pathList.forEach((path) => tempNudgeInfoMap.set(path, { ...nudgeInfo, closed: false }));
      }
    });
    this.nudgeInfoMap = tempNudgeInfoMap;
    this.isLoaded = true;
  };

  @action
  loadActiveNudge = (pathname: string) => {
    this.activeNudgeInfo = this.nudgeInfoMap.get(pathname) || this.nudgeInfoMap.get('common');
  }
}

export default NudgeModel;
