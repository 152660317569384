import { V2TextColorsType } from './Colors/V2TextColors';
import { V2FontStyleType } from './V2FontStyle';

export enum V2TextElementType {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  div = 'div',
  span = 'span',
  em = 'em',
  strong = 'strong',
  p = 'p',
}

export const V2TextOption = {
  element: V2TextElementType,
  fontStyle: V2FontStyleType,
  color: V2TextColorsType,
};
