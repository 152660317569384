import { VerificationType } from 'components/_v2/verification/VerificationContent';
import URIs from 'consts/URIs';
import { IKcbVerifyRs } from 'interfaces/_v2/verification/IVerificationRqRs';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getSession, removeSession } from 'utils/CommonUtils';
import { request } from 'utils/request';

const getKcbSmsVerify = async (moduleToken: string) => {
  const result = await request<IKcbVerifyRs>({
    method: 'get',
    url: URIs.get_kcb_sms_identify_verify,
    params: { moduleToken },
  });
  return result;
};

const getKcbIpinVerify = async (moduleToken: string) => {
  const result = await request<IKcbVerifyRs>({
    method: 'get',
    url: URIs.get_kcb_ipin_identify_verify,
    params: { moduleToken },
  });
  return result;
};

const useVerification = () => {
  const [params, setParams] = useState<IKcbVerifyRs | null>(null);
  const history = useHistory();
  useEffect(() => {
    const init = async () => {
      try {
        const sms = await getSession(VerificationType.SMS);
        const ipin = await getSession(VerificationType.IPIN);
        if (sms || ipin) {
          let res = null;
          if (sms) res = await getKcbSmsVerify(sms);
          if (ipin) res = await getKcbIpinVerify(ipin);
          if (res !== null && (res.resultCode === 'B000' || res.resultCode === 'T000')) {
            setParams(res);
            history.replace(history.location.pathname, '');
            if (sms) removeSession(VerificationType.SMS);
            if (ipin) removeSession(VerificationType.IPIN);
          } else throw (res!.resultMessage);
        }
      } catch (e) {
        console.error(e);
        history.replace(history.location.pathname, '');
      }
    };
    init();
  }, []);

  return params;
};

export default useVerification;
