import colors from '__designkit__/common/colors';
import JDALink from 'components/JDALink';
import { RequestUrlOption } from 'consts/URIs';
import useRequest from 'hooks/useRequest';
import { IAdMainBannerDto } from 'interfaces/_v2/main/IAdMainBanner';
import { inject, observer } from 'mobx-react';
import Main from 'models/_v2/Main';
import { injectStore } from 'models/store';
import useAdsImpressionQuery from 'query/ads/useAdsImpressionQuery';
import useAdsViewsQuery from 'query/ads/useAdsViewsQuery';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import JDSwiper, { JDFractions, JDSwiperSlide } from '../_common/JDSwiper';

const Skeleton = styled.div`
  width: calc(100vw - 32px);
  height: calc((100vw - 32px) / 2.175);
`;

const Frame = styled.div`
  width:100%;
  margin-top: 12px;
`;

export const MainAdFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: calc((100vw - 32px) / 2.175);
  min-height: 160px;
  background: ${colors.WHITE_100};

  .main-custom-slide{
    width: 100vw;
    display: flex;
    justify-content: center;
  }
`;

export const MainAdCardFrame = styled.div`
  position:relative;
  width: calc(100vw - 32px);
  height: calc((100vw - 32px) / 2.175);
  border: 1px solid ${V2BorderColors.default};
  border-radius: 8px;
  overflow: hidden;
  
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
interface IMainAd{
  main?:Main;
}

const MainAd:FC<IMainAd> = ({ main = new Main() }) => {
  const [viewBannerSn, setViewBannerSn] = useState<number[]>([]);
  const isViewMainBanner = (sn: number) => viewBannerSn.includes(sn);
  const { mutate: increaseAdsViewMutate } = useAdsViewsQuery();
  const { mutate: impressionAdsMutate } = useAdsImpressionQuery();
  const { loading, execute: loadAdsMainBanner } = useRequest(RequestUrlOption.getLoadAdsMainBanners,
    (data) => {
      main.fetchAdsMainBanners(data);
    });

  useEffect(() => {
    loadAdsMainBanner();
  }, []);

  const MainAdCard = (item:IAdMainBannerDto) => (
    <JDALink to={item.bannerLink}>
      <MainAdCardFrame onClick={(() => increaseAdsViewMutate(item.sn))}>
        <img
          src={`${item.moBannerImageUrl}?w=${(window.innerWidth - 64) * 2}`}
          alt='test-img'
          // eslint-disable-next-line no-return-assign
          onError={(e) => e.currentTarget.src = 'https://picsum.photos/seed/picsum/343/160'}
        />
      </MainAdCardFrame>
    </JDALink>
  );
  if (loading) return <Skeleton />;

  return (
    <Frame>
      {main.mainAds && main.mainAds.length > 0
      && (
      <MainAdFrame>
        <JDSwiper
          slidesPerView={1}
          autoplay={{ delay: 3000 }}
          pagination={JDFractions}
          loop
          onSlideChange={(slide) => {
            try {
              const mainBannerSn = main.mainAds[slide.realIndex].sn;
              if (mainBannerSn && !isViewMainBanner(main.mainAds[slide.realIndex].sn)) {
                impressionAdsMutate(main.mainAds[slide.realIndex].sn);
                setViewBannerSn([...viewBannerSn, mainBannerSn]);
              }
            } catch (e) {
              console.error(e);
            }
          }}
        >
          {
              main.mainAds && main.mainAds.length > 0 && main.mainAds.map((item) => (
                <JDSwiperSlide key={item.moBannerImageUrl} className='main-custom-slide'>
                  {MainAdCard(item)}
                </JDSwiperSlide>
              ))
            }

        </JDSwiper>
      </MainAdFrame>
      )}
    </Frame>
  );
};

export default inject(injectStore.main)(observer(MainAd));
