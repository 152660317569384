/* eslint-disable camelcase */
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import write_blob from 'capacitor-blob-writer';
import { IJDToastProps } from 'components/_v2/_common/toast/JDToast';
import URIs from 'consts/URIs';
import MobileStore from 'store/mobileStore';
import { request } from 'utils/request';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class FileUtil {
  static async fileDownload(filename: string, file: ArrayBuffer, read:boolean = false, setToastObject?: (props: IJDToastProps) => void, setIsLoading?: (isLoading: boolean) => void) {
    if (MobileStore.isMobile) {
      // APP 환경
      const currentFile = this.convertArrayBufferToBase64(file);
      const blob = new Blob([file], { type: 'octet/stream' });
      if (MobileStore.currentPlatform === 'ios') {
        try {
          const { uri } = await Filesystem.writeFile({
            path: filename,
            data: currentFile,
            directory: Directory.Documents,
            recursive: true,
          });
          if (setIsLoading) setIsLoading(false);
          await Share.share({
            title: '파일 다운로드/공유',
            text: '선택한 파일을 다운로드/공유합니다.',
            url: uri,
          });
          await Filesystem.deleteFile({
            path: filename,
            directory: Directory.Documents,
          });
        } catch (error) {
          console.error(`[JOBDA] 파일 저장 오류: ${error}`);
        }
      } else {
        await write_blob({
          path: filename,
          blob,
          directory: Directory.Documents,
          fast_mode: true,
          recursive: true,
          on_fallback(error) {
            if (setToastObject) setToastObject({ message: '파일이 다운로드 되었습니다.', type: 'SUCCESS', isOpen: true });
            if (setIsLoading) setIsLoading(false);
            console.error(error);
          },
        }).then(async () => {
          if (setToastObject) setToastObject({ message: '파일이 다운로드 되었습니다.', type: 'SUCCESS', isOpen: true });
          if (setIsLoading) setIsLoading(false);
        });
      }
    } else if (read) {
      const blob = new Blob([file], { type: 'application/pdf' });
      window.open(URL.createObjectURL(blob), '_blank');
    } else {
      const blob = new Blob([file], { type: 'octet/stream' });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = filename;
      a.hidden = true;
      document.body.appendChild(a);
      a.click();
    }
  }

  // PDF 생성 및 다운로드 기능 추가
  static async generatePdf(docDefinition: any, filename: string, setToastObject?: (props: IJDToastProps) => void, setIsLoading?: (isLoading: boolean) => void) {
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64(async (data) => {
      const base64Data = `data:application/pdf;base64,${data}`;
      const file = await fetch(base64Data).then((res) => res.arrayBuffer());
      await this.fileDownload(filename, file, false, setToastObject, setIsLoading);
    });
  }

  static dataURLtoFile = (dataurl: any, fileName: string) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  };

  static convertCanvasToFile = (dataUrl: string, imgName: string) => {
    const blobBin = atob(dataUrl.split(',')[1]); // base64 데이터 디코딩
    const array = [];
    for (let i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }
    return new File([new Blob([new Uint8Array(array)], { type: 'image/png' })], imgName);
  };

  static fileGetArrayBuffer = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target?.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsArrayBuffer(file);
  });

  static checkFileExtArr(file: File, allowableExtArr: string[], withDot = false) {
    const fileName = file.name;
    if (!withDot) {
      const ext = fileName.split('.').pop()?.toLowerCase();
      return allowableExtArr.some((v) => v === ext);
    }

    const ext = fileName.slice(fileName.lastIndexOf('.'));
    return allowableExtArr.includes(ext);
  }

  static checkFileSize(fileSize: number, limitSize: number, limitSizeUnit: 'KB' | 'MB') {
    let positionalNumber = 1;

    switch (limitSizeUnit) {
      case 'KB':
        positionalNumber = 1024;
        break;
      case 'MB':
        positionalNumber = 1024 ** 2;
        break;
      default:
        break;
    }

    return fileSize / positionalNumber <= limitSize;
  }

  static checkFileSizeZeroValidation = (file: File) => {
    if (file.size === 0) return false;
    return true;
  };

  static checkFileSizeValidation = (file: File, LIMIT_FILE_SIZE: number) => {
    if (file.size > LIMIT_FILE_SIZE) return false;
    return true;
  };

  static checkFileTypeValidation = (file: File, type: string[]) => {
    if (type) {
      const checkType = type.some((fileType: string): boolean => {
        if (file.type === fileType) {
          return true;
        } return false;
      });
      if (!checkType) {
        return false;
      }
    }
    return true;
  };

  static transformFileSize = (size: number | undefined) => {
    if (size) {
      if (size < 1024) {
        return `${size}bytes`;
      } if (size >= 1024 && size < 1048576) {
        return `${(size / 1024).toFixed()}KB`;
      } if (size >= 1048576) {
        return `${(size / 1048576).toFixed(1)}MB`;
      }
    }
    return 0;
  };

  static convertArrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }
}

export const prefetchFile = async (url:string,
  fetchedCallback: any,
  errorCallback: any) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';

  xhr.addEventListener('load', () => {
    if (xhr.status === 200) {
      const URL = window.URL || window.webkitURL;
      const blobUrl = URL.createObjectURL(xhr.response);
      fetchedCallback(blobUrl);
    } else {
      errorCallback();
    }
  }, false);

  await xhr.send();
};

export const downloadFile = async (fileName: string, fileUid: string) => {
  try {
    const res = await request<ArrayBuffer>({
      method: 'get',
      url: URIs.get_file_download(fileUid),
      responseType: 'arraybuffer',
    });
    FileUtil.fileDownload(fileName, res);
    return res;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const docAllowableExtArr = ['.doc', '.docx', '.hwp', '.hwpx', '.pdf', '.ppt', '.pptx', '.rtf', '.txt', '.xls', '.xlsx'];
export const videoVoiceAllowableExtArr = ['.avi', '.m4v', '.mov', '.mp3', '.mp4', '.wmv', 'webm'];

export const imageAllowableExtArr = ['.tif', '.tiff', '.png', '.gif', '.jpg', '.jpeg', '.jfif', '.bmp', '.raw', '.psd', '.ai'];
export const zipAllowableExtArr = ['.egg', '.zip', '.alz', '.rar', '.7z'];
export const allFileAllowedExtensions = [
  ...docAllowableExtArr,
  ...videoVoiceAllowableExtArr,
  ...imageAllowableExtArr,
  ...zipAllowableExtArr,
];

export const profileAllowedExtensions = ['.ppt', '.pptx', '.pdf'];
export const profileImageAllowedExtensions = ['.jpg', '.png', '.JPG', '.PNG'];
