import URIs from 'consts/URIs';
import { IPositionCuration } from 'interfaces/_v2/positionList/IPositionCuration';
import { IPositionListRq, IPositionListRs } from 'interfaces/_v2/positionList/IPositionList';
import { action, observable } from 'mobx';
import { request } from 'utils/request';

export enum CurationDefaultType {
  ALL = -2,
  RECOMMEND = -1,
}

const SEARCH_INIT_PAGE = 0;
const SEARCH_INIT_SIZE = 25;
export default class PositionCurationModel {
  @observable curationSelect: CurationDefaultType | number = CurationDefaultType.ALL;
  @observable curations: IPositionCuration[] = [];
  @observable searchPositions: IPositionListRs | null = null;
  @observable currentCurationSn: number = 0;
  @observable recommendPositionError: string = '';

  @action
  loadCurations = async () => {
    try {
      this.curations = (await request<{ curations: IPositionCuration[] }>({
        method: 'get',
        url: URIs.get_curations,
      })).curations;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadCurationsRecommendPositions = async () => {
    try {
      this.searchPositions = await request<IPositionListRs>({
        method: 'get',
        url: URIs.get_curations_positions,
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  loadCurationsPositions = async (curationSn: number, searchFilters: Partial<IPositionListRq>) => {
    try {
      this.searchPositions = await request<IPositionListRs>({
        method: 'get',
        url: URIs.get_curations_sn_positions(curationSn),
        params: {
          page: SEARCH_INIT_PAGE,
          size: SEARCH_INIT_SIZE,
          ...searchFilters,
        },
      });
      this.currentCurationSn = curationSn;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  lazeLoadCurationsPositions = async (searchFilters: Partial<IPositionListRq>) => {
    if (this.searchPositions === null) return;
    if (this.searchPositions.pages?.totalPages <= this.searchPositions.pages?.page + 1) return;

    const nextSearchPositions = await request<IPositionListRs>({
      method: 'get',
      url: URIs.get_curations_sn_positions(this.currentCurationSn),
      params: {
        page: (this.searchPositions.pages?.page + 1),
        size: this.searchPositions.pages?.size,
        ...searchFilters,
      },
    });
    this.searchPositions = {
      positions: [...this.searchPositions.positions, ...nextSearchPositions.positions],
      pages: nextSearchPositions.pages,
    };
  }
}
