import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC } from 'react';
import styled from 'styled-components';
import { getCreatedDayTime } from 'utils/DateUtils';

const Frame = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
const TitleFrame = styled.div`
    display: flex;
    margin-top: 12px;
    align-self: flex-end;

    .time-text{
        font: ${Fonts.B3_Medium};
        color: ${Colors.cA4A4A4};
    }
`;
const MessageFrame = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    width: fit-content;
    padding:24px;
    background-color: ${Colors.G_200};
    border-radius: 12px 2px 12px 12px;
    align-self: flex-end;
    .user-text{
        color:${Colors.WHITE_100};
        font:${Fonts.B1_Bold_P};
    }
`;

interface IMainTutorialUserMessageSuggestAccept{
  interview?:boolean
}
const MainTutorialUserMessageSuggestAccept:FC<IMainTutorialUserMessageSuggestAccept> = ({ interview = false }) => {
  const today = new Date();
  const todayISOString = today.toISOString();
  return (
    <Frame>
      <TitleFrame>
        <div className='time-text'>{getCreatedDayTime(todayISOString)}</div>
      </TitleFrame>
      <MessageFrame>
        {
          interview
            ? <div className='user-text'>000님께서 면접 제안을 수락하였습니다.</div>
            : <div className='user-text'>000님께서 제안을 수락하였습니다.</div>
        }

      </MessageFrame>
    </Frame>
  );
};
export default MainTutorialUserMessageSuggestAccept;
