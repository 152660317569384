import { request } from 'utils/request';
import URIs from 'consts/URIs';
import { useMutation } from 'react-query';

const useAdsImpressionQuery = () => useMutation({
  mutationFn: async (adSn: number) => {
    try {
      const response = await request({
        method: 'post',
        url: URIs.post_ads_impression(adSn),
      });
      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
});

export default useAdsImpressionQuery;
