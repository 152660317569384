import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IconFileTag from 'assets/_v2/profile/icon_file_tag.svg';
import Loading from 'components/Loading';
import ProfileItemTitle from 'components/_v2/profile/ProfileItemTitle';
import ProfileListCard from 'components/_v2/profile/ProfileListCard';
import ProfileEducation from 'components/_v2/profile/education/ProfileEducation';
import URIs from 'consts/URIs';
import { EducationLevelText, EducationSchoolText, EducationType, EducationTypeText } from 'consts/_v2/profile/EducationLevel';
import { ProfileAuditItemType } from 'consts/_v2/profile/ProfileType';
import useToast from 'hooks/useToast';
import { IAttachFile, IProfileEducation, MajorTypeText, ScoreType } from 'interfaces/_v2/profile/IProfileEducation';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import ProfileEducationModel from 'models/_v2/profile/ProfileEducationModel';
import { injectStore } from 'models/store';
import { useInsightResumeAutoSave } from 'query/resume/useResumeQuery';
import { FC, useEffect, useState } from 'react';
import MobileStore from 'store/mobileStore';
import styled from 'styled-components';
import FileUtil from 'utils/FileUtil';
import { request } from 'utils/request';

const Frame = styled.div`
`;

const DescriptionCard = styled.div<{edit?:boolean}>`
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  >h1{
    font: ${Fonts.B2_Bold};
    line-height: 20px;
    color:${colors.CG_90};
    margin-bottom: 8px;
  }

  .research-frame{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    margin-top:27px;
    >div{
      width:100%;
      display: inline-block;
      white-space: pre-line;
      font: ${Fonts.B3_Medium};
      color:${colors.CG_70};
      margin-top: 19px;
    }
  }
`;
const MajorCard = styled.div<{edit?:boolean}>`
    font: ${Fonts.B2_Medium};
    color:${colors.CG_70};
    background-color: ${(props) => (props.edit ? `${colors.Y_100}` : `${colors.WHITE_100}`)};
`;
const StyledFileTag = styled.button`
  display: flex;
  align-items: center;
  font: ${Fonts.B2_Medium};
  color:${colors.JOBDA_BLACK};
  text-decoration: underline;
  >span{
    margin-left:6px;
  }
`;

export const FileTag: FC<IAttachFile> = ({ fileName, fileUid }) => {
  const { setToastObject } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const downloadFile = async (fileNameIn: string, fileUidIn: string) => {
    try {
      if (MobileStore.isMobile) {
        setIsLoading(true);
      }

      const res = await request<ArrayBuffer>({
        method: 'get',
        url: URIs.get_file_download(fileUidIn),
        responseType: 'arraybuffer',
      });
      FileUtil.fileDownload(fileNameIn, res, false, setToastObject, setIsLoading);
      return res;
    } catch (e) {
      console.error(e);
      return null;
    }
  };
  return (
    <StyledFileTag
      type='button'
      onClick={(e) => {
        e.preventDefault();
        downloadFile(fileName, fileUid);
      }}
    >
      {isLoading && <Loading />}

      <img src={IconFileTag} alt='file' />
      <span>{fileName}</span>
    </StyledFileTag>
  );
};

export interface IProfileEducationView {
  login?: Login;
  profileEducationModel?: ProfileEducationModel;
  suggestionModalCode: number;
}

const ProfileEducationView: FC<IProfileEducationView> = ({ suggestionModalCode = 0, login = new Login(), profileEducationModel = new ProfileEducationModel() }) => {
  const [openEducation, setOpenEducation] = useState<boolean>(false);
  const { data: insightResumeAutoSaveData, refetch: refetchAutoSave } = useInsightResumeAutoSave(!!login.userInfo);

  const getCardEndDate = (card:IProfileEducation) => {
    if (card.educationType === EducationType.GRADUATION) return card.endDate;
    if (card.educationType === EducationType.GRADUATION_CANDIDATE || card.educationType === EducationType.DROP) return `${card.endDate}(${EducationTypeText[card.educationType]})`;
    return EducationTypeText[card.educationType];
  };

  useEffect(() => {
    if (suggestionModalCode === 3) setOpenEducation(true);
  }, [suggestionModalCode]);

  const checkAudit = (caseType:ProfileAuditItemType, index:number) => {
    if (profileEducationModel.educations === null || profileEducationModel.educations === undefined) return false;
    switch (caseType) {
      case ProfileAuditItemType.PROFILE_EDUCATION_START_DATE:
        return profileEducationModel.educations[index].auditItems?.includes(ProfileAuditItemType.PROFILE_EDUCATION_START_DATE);
      case ProfileAuditItemType.PROFILE_EDUCATION_END_DATE:
        return profileEducationModel.educations[index].auditItems?.includes(ProfileAuditItemType.PROFILE_EDUCATION_END_DATE);
      default:
        return false;
    }
  };

  const checkMajorAudit = (caseType:ProfileAuditItemType, index:number, secondIndex:number) => {
    if (!profileEducationModel.educations) return false;
    const education = profileEducationModel.educations[index];
    if (!education) return false;
    if (!education.profileMajors || secondIndex >= education.profileMajors.length) return false;
    const major = education.profileMajors[secondIndex];
    if (!major || !major.auditItems) return false;
    return major.auditItems.includes(caseType);
  };
  return (
    <Frame>
      <ProfileItemTitle required icon='university' text='학력' subText='학력정보를 적어주세요.' onClickEditBtn={() => setOpenEducation(true)} tag={profileEducationModel.educationLevel !== null ? EducationLevelText[profileEducationModel.educationLevel] : undefined} />
      {profileEducationModel.educations !== null && profileEducationModel.educations.map((card: IProfileEducation, index: number) => (
        <ProfileListCard
          key={`card-list${card.schoolName}`}
          institution={card.schoolName}
          date={`${card.startDate} ~ ${getCardEndDate(card)}`}
          editDate={checkAudit(ProfileAuditItemType.PROFILE_EDUCATION_START_DATE, index) || checkAudit(ProfileAuditItemType.PROFILE_EDUCATION_END_DATE, index)}
          description={(
            <DescriptionCard>
              <h1>{EducationSchoolText[card.schoolLevel]}</h1>
              {card.profileMajors?.map((major, idx) => (
                <MajorCard edit={checkMajorAudit(ProfileAuditItemType.PROFILE_EDUCATION_MAJOR, index, idx)} key={`major-tag-${idx}`}>{`${MajorTypeText[major.majorType]} : ${major.majorName} ${major.scoreType === ScoreType.SCORE
                  ? (major.score === null ? `` : `(학점 ${major.score}/${major.perfectScore})`)
                  : (major.score === null ? `` : `(백분위 ${major.score})`)
                }`}
                </MajorCard>
              ))}
              {card.profileResearches?.map((research, idx) => (
                <div className='research-frame' key={`research-frame-${idx}`}>
                  {research.attachFiles.map((file) => (
                    <FileTag key={`file-item-${file.fileUid}`} fileName={file.fileName} fileUid={file.fileUid} />
                  ))}
                  <div>
                    {research.description}
                  </div>
                </div>
              ))}
            </DescriptionCard>
              )}
          index={index}
          length={profileEducationModel.educations?.length || 0}
        />
      ))}
      <ProfileEducation
        isOpen={openEducation}
        onClose={async () => {
          await Promise.all([login.getMatchConfigStatus()]);
          refetchAutoSave();
          setOpenEducation(false);
        }}
      />
    </Frame>

  );
};

export default inject(injectStore.login, injectStore.profileEducationModel)(observer(ProfileEducationView));
