export enum BusinessSize {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
  STARTUP ='STARTUP',
  GOVERNMENT ='GOVERNMENT',
  ETC = 'ETC'
}

export enum BusinessSizeText {
  LARGE = '대기업',
  MEDIUM = '중견기업',
  SMALL = '중소기업',
  STARTUP = '스타트업',
  GOVERNMENT = '공기업',
  ETC = '기타'
}

export default BusinessSize;
