import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { RemoteMatchRepo } from 'repository/match/MatchRepo';

const matchRepo = new RemoteMatchRepo();

const fetchMatchHomeDashboard = async () => {
  const data = await matchRepo.loadMatchHomeDashboard();
  return data;
};

export function useMatchHomeDashboard() {
  return useQuery(['match', 'home', 'dashboard'], fetchMatchHomeDashboard, {
    onError: (error) => {
      console.error('Failed to fetch match home dashboard:', error);
    },
  });
}

const fetchMatchHomeDashboardMatchingSetting = async (rq: any) => {
  const data = await matchRepo.updateMatchHomeDashboardMatchingSetting(rq);
  return data;
};

export function useMatchHomeDashboardMatchingSetting(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation(fetchMatchHomeDashboardMatchingSetting, {
    onSuccess,
    onError,
  });
}

const fetchMatchHomeJobPostingsAcc = async (pageSize: number) => {
  const data = await matchRepo.loadMatchHomeJobPostingsAcc(pageSize);
  return data;
};

export function useMatchHomeJobPostingsAcc(pageSize: number) {
  return useQuery(['match', 'home', 'acc', pageSize], () => fetchMatchHomeJobPostingsAcc(pageSize), {
    onError: (error) => {
      console.error('Failed to fetch match home job postings acc:', error);
    },
  });
}

const fetchMatchHomeJobPostingsNow = async (pageSize: number) => {
  const data = await matchRepo.loadMatchHomeJobPostingsNow(pageSize);
  return data;
};

export function useMatchHomeJobPostingsNow(pageSize: number) {
  return useQuery(['match', 'home', 'now', pageSize], () => fetchMatchHomeJobPostingsNow(pageSize), {
    onError: (error) => {
      console.error('Failed to fetch match home job postings now:', error);
    },
  });
}

const fetchMatchHomeMatchingCompany = async () => {
  const data = await matchRepo.loadMatchHomeMatchingCompany();
  return data;
};

export function useMatchHomeMatchingCompany() {
  return useQuery(['match', 'home', 'matching-company'], fetchMatchHomeMatchingCompany, {
    onError: (error) => {
      console.error('Failed to fetch match home matching company:', error);
    },
  });
}

const fetchMatchHomeMatchingPositions = async () => {
  const data = await matchRepo.loadMatchHomeMatchingPositions();
  return data;
};

export function useMatchHomeMatchingPositions() {
  return useQuery(['match', 'home', 'matching-positions'], fetchMatchHomeMatchingPositions, {
    onError: (error) => {
      console.error('Failed to fetch match home matching positions:', error);
    },
  });
}

const fetchMatchHomeMatchingQuestStatus = async () => {
  const data = await matchRepo.loadMatchHomeMatchingQuestStatus();
  return data;
};

export function useMatchHomeMatchingQuestStatus(isEnabled:boolean) {
  return useQuery(['match', 'home', 'matching-quest-status'], fetchMatchHomeMatchingQuestStatus, {
    enabled: isEnabled,
    onError: (error) => {
      console.error('Failed to fetch match home matching quest status:', error);
    },
  });
}

const fetchMatchHomeMatchingTutorialComplete = async () => {
  const data = await matchRepo.saveMatchHomeMatchingTutorialComplete();
  return data;
};

export function useMatchHomeMatchingTutorialComplete(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation(fetchMatchHomeMatchingTutorialComplete, {
    onSuccess,
    onError,
  });
}

const fetchMatchHomeMatchingUserCount = async () => {
  const data = await matchRepo.loadMatchHomeMatchingUserCount();
  return data;
};

export function useMatchHomeMatchingUserCount() {
  return useQuery(['match', 'home', 'matching-user-count'], fetchMatchHomeMatchingUserCount, {
    onError: (error) => {
      console.error('Failed to fetch match home matching user count:', error);
    },
  });
}

const fetchMatchHomePositionsRecommend = async (jobGroupCode: number, pageSize: number) => {
  const data = await matchRepo.loadMatchHomePositionsRecommend(jobGroupCode, pageSize);
  return data;
};

export function useMatchHomePositionsRecommend(jobGroupCode: number, pageSize: number, isEnabled:boolean) {
  return useQuery(['match', 'home', 'positions', 'recommend', jobGroupCode, pageSize], () => fetchMatchHomePositionsRecommend(jobGroupCode, pageSize), {
    enabled: isEnabled,
    onError: (error) => {
      console.error('Failed to fetch match home positions recommend:', error);
    },
  });
}

const fetchMatchHomeMatchingTutorialAlarm = async () => {
  const data = await matchRepo.sendMatchHomeMatchingTutorialAlarm();
  return data;
};

export function useMatchHomeMatchingTutorialAlarm(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation(fetchMatchHomeMatchingTutorialAlarm, {
    onSuccess,
    onError,
  });
}
